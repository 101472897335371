const setTimeOnFixedDate = (hours: number, minutes: number): Date => {
  const fixedDate = new Date();
  fixedDate.setHours(hours, minutes, 0, 0);
  return fixedDate;
};

export const timeDropdownItems = Array.from({ length: 48 }, (_, index) => {
  const hours = Math.floor(index / 2);
  const minutes = (index % 2) * 30;
  const period = hours < 12 ? 'AM' : 'PM';
  const timeValue = setTimeOnFixedDate(hours, minutes);

  return {
    label: `${String(hours % 12 || 12).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')} ${period}`,
    value: timeValue.toLocaleString(),
  };
});

export const stringToDate = (dateString: string) => {
  const [first, second] = dateString.split(',').map((item) => item.trim());
  const [day, month, year] = first.split('/');
  const [hours, minutes, seconds] = second.split(':');

  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hours),
    parseInt(minutes),
    parseInt(seconds)
  );
};
