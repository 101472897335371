import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode } from 'react';

const ReactQueryProvider = ({ children }: { children: ReactNode }) => {
  const client = new QueryClient();
  const isInitialized: boolean = process.env.REACT_QUERY_DEVTOOLS === 'true';

  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools
        client={client}
        initialIsOpen={isInitialized}
        buttonPosition="bottom-left"
      />
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
