import React from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

type CustomDropdownProps = {
  label?: string;
  name: string;
  options: { label: string; value: string }[];
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  labelClass?: string;
  className?: string;
  customMargin?: string;
  customPlaceHolder?: string;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  name,
  options,
  value,
  placeholder,
  labelClass,
  className,
  onChange,
  customMargin = 'mb-5',
  customPlaceHolder,
}) => {
  return (
    <div className={`${className} ${customMargin}`}>
      <label
        htmlFor={name}
        className={`block mb-1 text-primaryTextColor text-sm ${labelClass}`}
      >
        {label}
      </label>
      <Listbox value={value} onChange={onChange}>
        <div className="relative">
          <ListboxButton
            className={`w-full p-2 h-[42px] focus:outline-none rounded-sm text-xs lg:text-sm text-categoryActive border-[1px] border-blueBorderColor outline-none focus-within:border-categoryActive text-left flex justify-between items-center ${customPlaceHolder}`}
          >
            {options.find((option) => option.value === value)?.label ||
              placeholder}
            <ChevronDownIcon className="size-4 fill-white/60" />
          </ListboxButton>
          <ListboxOptions
            className={`mt-12 w-full bg-white shadow-lg max-h-48 rounded-md py-1 text-xs lg:text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-10 px-2`}
          >
            {options.map((option, index) => (
              <ListboxOption
                key={option.value + index}
                value={option.value}
                className={({ active }) =>
                  `cursor-default select-none relative py-2 pl-4 pr-4  text-xs border-b-dropDownItemBorder border-b-[1px] last:border-b-transparent ${
                    active
                      ? 'text-categoryActive bg-categoryInactive'
                      : 'text-categoryActive'
                  } hover:bg-gray-200 hover:rounded-sm`
                }
              >
                {({ selected }) => (
                  <span
                    className={`block truncate ${
                      selected ? 'font-medium' : 'font-medium'
                    }`}
                  >
                    {option.label}
                  </span>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  );
};

export default CustomDropdown;
