import React, { useState, useEffect } from 'react';
import termsAndConditionContent from 'components/terms-and-conditions/TermsAndConditionsData';
import { TermAndCondition, ContentItem } from 'model/TermsAndConditionsItem';

const createMarkup = (htmlContent: string) => {
  return { __html: htmlContent };
};

const extractTitle = (htmlContent: string | JSX.Element) => {
  if (typeof htmlContent === 'string') {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    return tempDiv.textContent || tempDiv.innerText || '';
  }
  return '';
};

const TableOfContents: React.FC<{ content: TermAndCondition[] }> = ({
  content,
}) => {
  return (
    <div className="table-of-contents">
      <h3 className="font-semibold sm:text-lg lg:text-2xl">
        Table of Contents
      </h3>
      <div className="w-14 h-[3px] bg-primary mb-4" />
      <ul className="text-sm md:text-base">
        {content.map((section) => (
          <li key={section.id}>
            <div className="flex items-center mb-2 px-8">
              <div className="w-8 h-[3px] bg-primary mr-2" />
              <p className="text-sm md:text-base">{section.mainTitle}</p>
            </div>
            {section.description?.map((desc, descIndex) => (
              <ul key={descIndex} className="ml-10">
                <li className="flex items-center mb-1 px-8">
                  <div className="w-5 h-[3px] bg-primary mr-3" />
                  <p>{extractTitle(desc.topic || '')}</p>
                </li>
              </ul>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};

const TermsAndConditionsPage: React.FC = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const renderContentItem = (item: ContentItem) => (
    <div className={`mt-4 ${item.isSubtitle ? '' : ''}`}>
      {item.numb && <span className="mr-2">{item.numb}.</span>}
      {typeof item.topic === 'string' ? (
        <span dangerouslySetInnerHTML={createMarkup(item.topic)} />
      ) : (
        item.topic
      )}
      {item.content &&
        item.content.map((content, index) =>
          typeof content === 'string' ? (
            <div
              key={index}
              className="ml-4 p-2"
              dangerouslySetInnerHTML={createMarkup(content)}
            />
          ) : (
            <div key={index} className="ml-4 p-2">
              {content}
            </div>
          )
        )}
    </div>
  );

  return (
    <div className="p-6 sm:py-10 md:py-20 container mx-auto max-w-7xl">
      <h1 className="text-xl md:text-2xl lg:text-5xl text-center font-medium mb-5">
        Terms & Conditions
      </h1>
      <div className="text-center sm:text-base md:text-lg lg:text-xl mb-5">
        <span className="font-bold">Last Updated:</span> 26 January 2022
      </div>
      <h3 className="font-semibold  sm:text-lg lg:text-2xl">INTRODUCTION</h3>
      <div className="w-14 h-[3px] bg-primary mb-4" />
      <div className="mb-8 text-sm md:text-base">
        Welcome to Move2NewCity! This page contains the Terms and Conditions of
        Move2NewCity. Please carefully read the following terms and conditions.
        You are agreeing to be bound by these terms and conditions and our terms
        of use policy by visiting our site or signing up as a member.
      </div>
      <TableOfContents content={termsAndConditionContent} />
      <ol className="pl-3 sm:pl-4 text-sm md:text-base">
        {termsAndConditionContent.map((term: TermAndCondition, index) => (
          <li key={term.id} id={term.id} className="mb-8">
            <h2 className="text-lg lg:text-2xl font-semibold  mb-4">{`${index + 1}. ${term.mainTitle}`}</h2>
            <div className="w-14 h-[3px] bg-primary mb-4" />
            {term.points &&
              term.points.map((point, pointIndex) => (
                <div
                  key={pointIndex}
                  className="mb-2"
                  dangerouslySetInnerHTML={createMarkup(point)}
                />
              ))}
            {term.description &&
              term.description.map((desc) => renderContentItem(desc))}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TermsAndConditionsPage;
