import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import Avatar from 'components/common/Avatar';
import { User } from 'model/User';
import { UserRole } from 'constants/User';
import { useNavigate } from 'react-router-dom';

type UserMenuProps = {
  user: User;
  handleLogout: () => void;
};

const UserMenu: React.FC<UserMenuProps> = ({ user, handleLogout }) => {
  const navigate = useNavigate();

  const onLogout = () => {
    handleLogout();
    navigate('/', { replace: true });
    window.location.reload();
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="focus:outline-none">
        <Avatar
          name={user?.username}
          initialImageUrl={''}
          size="h-10 w-10"
          isSelected={false}
          borderStyle="border-[1px] border-avatarBorderColor"
          charBorderStyles="border-[1px] border-avatarBorderColor"
        />
      </MenuButton>
      <MenuItems className="absolute right-0 mt-2 w-36 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 font-medium">
        <div className="py-1">
          <MenuItem>
            {({ active }) => (
              <button
                className={`${
                  active
                    ? 'text-secondary hover:bg-gray-200 duration-300'
                    : 'text-secondary'
                } group flex items-center w-full px-2 py-2 text-sm border-b-categoryInactive border-b-[1px]`}
                onClick={() => {
                  navigate(`/user-profile?tabs=account`);
                }}
              >
                Edit Account
              </button>
            )}
          </MenuItem>
          {user.role === UserRole.FRIEND && (
            <>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'text-secondary hover:bg-gray-200 duration-300'
                        : 'text-secondary'
                    } group flex items-center w-full px-2 py-2 text-sm border-b-categoryInactive border-b-[1px]`}
                    onClick={() => {
                      navigate(`/user-profile`);
                    }}
                  >
                    My Profile
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'text-secondary hover:bg-gray-200 duration-300'
                        : 'text-secondary'
                    } group flex items-center w-full px-2 py-2 text-sm border-b-categoryInactive border-b-[1px]`}
                    onClick={() => {
                      navigate(`/user-profile?tabs=scheduled-calls`);
                    }}
                  >
                    Scheduled Calls
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'text-secondary hover:bg-gray-200 duration-300'
                        : 'text-secondary'
                    } group flex items-center w-full px-2 py-2 text-sm border-b-categoryInactive border-b-[1px]`}
                    onClick={() => {
                      navigate(`/user-profile?tabs=availability`);
                    }}
                  >
                    Availability
                  </button>
                )}
              </MenuItem>
            </>
          )}

          <MenuItem>
            {({ active }) => (
              <button
                onClick={onLogout}
                className={`${
                  active ? 'text-secondary hover:bg-gray-200' : 'text-secondary'
                } group flex items-center w-full px-2 py-2 text-sm`}
              >
                Logout
              </button>
            )}
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
};

export default UserMenu;
