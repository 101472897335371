import React, { useEffect, useState } from 'react';
import CustomButton from 'components/common/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';

const CookiePolicyPopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="bg-white fixed bottom-4 left-4 right-4 max-w-[510px] p-4 rounded-sm shadow-lg z-50">
      <div className="text-xs text-cookieNotificationText relative">
        <button
          onClick={handleClose}
          className="absolute -top-2 -right-0.5 focus:outline-none"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        <div className="font-light leading-normal pr-4 md:pr-8">
          <p>
            We use cookies on our website to give you the most relevant
            experience by remembering your preferences and repeat visits.
          </p>
          <p>
            By clicking “Accept All”, you consent to the use of ALL the cookies.
          </p>
        </div>
        <div className="pt-4 flex justify-between items-center">
          <p>
            For more information go to&nbsp;
            <a href="/privacy-policy" className="text-primary">
              Privacy Policy
            </a>
            .
          </p>
          <div className="flex justify-end items-center">
            <CustomButton
              label="Accept All"
              variant="primary"
              onClick={handleAccept}
              customStyle="w-[120px] !h-8 !text-xs"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicyPopup;
