import React from 'react';
import FriendProfileInfo from 'components/friend-profile-info/FriendProfileInfo';
import Textarea from 'components/common/TextArea';
import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import { timeZones } from 'utils/timezones';
import BookingCalendar from 'components/booking-calendar/BookingCalendar';
import { sampleProfileData } from './FriendProfile';
import Profile from 'assets/images/profile.png';

const ScheduleCallPage: React.FC = () => {
  const durationOptions = [
    { label: '15 minutes', value: '15' },
    { label: '30 minutes', value: '30' },
    { label: '45 minutes', value: '45' },
  ];

  const handleDurationChange = (e: any) => {};

  const handleOnSubmit = () => {};

  return (
    <div className="container max-w-7xl mx-auto mt-8  lg:px-8">
      <div className="text-secondary text-lg font-medium mb-3 px-6 lg:px-0">
        Schedule a Call with username
      </div>
      <div className="lg:grid lg:grid-cols-12 gap-x-8 xl:gap-x-16 gap-y-6 flex flex-col ">
        <div className="justify-center lg:col-span-3 col-span-12 px-6 lg:p-0">
          <div className="flex justify-center w-full ">
            <FriendProfileInfo
              userId={'1'}
              city={'Melbourne'}
              username={'user'}
              profilePictureUrl={Profile}
              yearsInCity={`2`}
              online={sampleProfileData.online}
              payment={sampleProfileData.payment}
              aboutMe={''}
              ratings={sampleProfileData.ratings}
              showScheduleButton={false}
            />
          </div>
        </div>
        <div className="lg:col-span-9 col-span-12 bg-white shadow-lg p-4 lg:p-8">
          <form onSubmit={handleOnSubmit} className="flex flex-col">
            <div className="mb-4">
              <Textarea
                label="Message to username *"
                value={''}
                onChange={() => {}}
                name="message"
                placeholder="Type your message here"
                maxLength={500}
                className="!mb-1"
              />
              <p className="text-xs text-secondary float-right font-normal">
                <b>Max:</b> 500 characters
              </p>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className=" flex flex-col md:w-1/2 z-50">
                <Dropdown
                  label="Select estimated call duration *"
                  options={durationOptions}
                  value=""
                  name="duration"
                  placeholder={durationOptions?.[0]?.label}
                  onChange={handleDurationChange}
                />
              </div>
              <div className="md:w-1/2">
                <div className="flex flex-col">
                  <Dropdown
                    label="Select your time zone *"
                    value=""
                    options={timeZones}
                    onChange={() => {}}
                    name="timeZone"
                    placeholder={'Select your time zone'}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <label className="text-sm mb-1 text-secondary">
                Select date and time *
              </label>
              <BookingCalendar />
            </div>
            <div className="mb-6">
              <Button
                label={
                  <span>
                    <i className={`pi pi-calendar`} /> Schedule a Call
                  </span>
                }
                variant="primary"
                customStyle=" !mt-9"
                type="submit"
              />
            </div>
            <div className="text-xs text-secondary mb-4">
              By scheduling a call, you agree with our{' '}
              <a href="/terms-and-conditions" className="text-primary">
                Terms of Service
              </a>
              .
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ScheduleCallPage;
