import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { blogArticles } from 'components/blog/dummy';
import SocialMediaShare from 'components/blog/SocialShare';
import EditorsPickCard from 'components/blog/EdtiorsPickCard';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

const calculateReadingTime = (content: string): string => {
  const wordsPerMinute = 200;
  const text = content.replace(/<[^>]+>/g, '');
  const wordCount = text.split(/\s+/).length;
  const readingTime = Math.ceil(wordCount / wordsPerMinute);
  return `${readingTime} min read`;
};

const SingleBlogPostPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [editorsPicks, setEditorsPicks] = useState(blogArticles.slice(0, 3));

  useEffect(() => {
    const article = blogArticles.find((article) => article.id === id);
    if (article) {
      const sameCategoryArticles = blogArticles.filter((a) =>
        a.categories.some((category) => article.categories.includes(category))
      );
      const shuffledArticles = sameCategoryArticles.sort(
        () => 0.5 - Math.random()
      );
      setEditorsPicks(shuffledArticles.slice(0, 3));
    }
  }, [id]);

  const article = blogArticles.find((article) => article.id === id);

  if (!article) {
    return (
      <div className=" flex flex-col justify-center items-center h-screen">
        <p className="font-semibold text-3xl text-primary">404</p>
        <p className="text-secondary font-medium">Article not found</p>
      </div>
    );
  }

  const readingTime = calculateReadingTime(article.content);

  return (
    <div>
      <div className="bg-blogBg h-full min-h-screen">
        <div className="relative w-full h-[600px] max-w-full overflow-hidden shadow-lg">
          <img
            className="w-full h-[600px] object-cover"
            src={article.image}
            alt={article.title}
          />
          <div className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center text-white">
            <div className="flex flex-col justify-center items-center text-center px-6 py-4 flex-wrap">
              <div className="flex justify-center mb-6 space-x-2">
                {article.categories.map((category, index) => (
                  <div
                    key={index}
                    className="bg-overlay text-white text-[10px] rounded-full px-3 py-1 uppercase w-min"
                  >
                    {category}
                  </div>
                ))}
              </div>
              <div className="text-2xl font-bold mb-4">{article.title}</div>
              <div className="text-xs mb-4">{article.description}</div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl container mx-auto px-6 lg:px-4 xl:px-0 mt-12 md:mt-20">
          <div className="flex items-center mb-8">
            <button
              className="flex items-center text-sm text-primary hover:text-secondary transition-colors"
              onClick={() => navigate(-1)}
            >
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              Go Back
            </button>
          </div>
          <div className="mb-6">
            <div className="flex items-center space-x-2 mb-6">
              <div className="text-xs font-semibold text-secondary mb-2">
                {article.date}
              </div>
              <hr className="border-b-[1px] border-b-primary w-10" />
              <div>
                <div className="text-xs font-semibold text-secondary mb-2">
                  {readingTime}
                </div>
              </div>
            </div>

            <div
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </div>
          <div className="flex justify-start mb-6 space-x-2">
            {article.categories.map((category, index) => (
              <div
                key={index}
                className="bg-overlay border-grayBorderColor border-[1px] text-borderline font-semibold text-[10px] rounded-md px-3 py-1 uppercase w-min"
              >
                {category}
              </div>
            ))}
          </div>
          <hr className="border-b-[1px] border-b-grayBorderColor w-full" />
          <div className="py-16 flex justify-end">
            <SocialMediaShare
              url={window.location.href}
              title={article.title}
            />
          </div>
        </div>
      </div>
      <div className="max-w-7xl container mx-auto px-6 lg:px-4 xl:px-0">
        <h2 className="lg:text-lg font-medium text-secondary mb-12 mt-10">
          Related Posts
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 mb-12 md:mb-20">
          {editorsPicks.map((article) => (
            <EditorsPickCard
              id={article.id}
              key={article.id}
              image={article.image}
              categories={article.categories}
              date={article.date}
              title={article.title}
              description={article.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleBlogPostPage;
