import React, { useState } from 'react';
import CustomButton from 'components/common/Button';
import Input from 'components/common/Input';
import CustomCheckbox from 'components/common/Checkbox';
import CustomRadioButton from 'components/common/RadioButton';
import Tooltip from 'components/common/Tooltip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import PasswordInput from 'components/common/PasswordInput';
import { SignupRequest } from 'model/Auth';
import { AxiosError } from 'axios';
import { signup } from 'api/requests/auth';
import { AccountType, UserRole } from 'constants/User';
import { generateOptionsFromEnum } from 'utils/generateOptions';
import notify, { NotifySeverity } from 'components/common/Notification';

type SignUpContentProps = {
  switchToSignIn: () => void;
};

const SignUpContent: React.FC<SignUpContentProps> = ({ switchToSignIn }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [emailExistsError, setEmailExistsError] = useState('');
  const [usernameExistsError, setUsernameExistsError] = useState('');
  const [accountType, setAccountType] = useState(AccountType.Newcomer);

  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const validateUsername = (username: string) => {
    const lengthRegex = /^[a-zA-Z0-9_-]{5,16}$/;
    const letterRegex = /[a-zA-Z]/;
    const invalidCharRegex = /^[-_ ]|[@ ]/;

    return (
      lengthRegex.test(username) &&
      letterRegex.test(username) &&
      !invalidCharRegex.test(username)
    );
  };

  const getUsernameErrorMessage = (username: string) => {
    if (!username) {
      return 'Username is required.';
    } else if (username.startsWith('_') || username.startsWith('-')) {
      return 'No starting hyphen or underscore allowed.';
    } else if (username.includes('@')) {
      return 'Username cannot contain the "@" sign.';
    } else if (/^\d+$/.test(username)) {
      return 'Username must contain at least one letter.';
    } else if (username.includes(' ')) {
      return 'Username cannot contain spaces.';
    } else if (username.length < 5) {
      return 'Your username must be at least 5 characters and can include a combination of numbers, letters, and special characters (- and _).';
    } else {
      return '';
    }
  };

  const handleSignup = async (values: SignupRequest) => {
    try {
      if (!values.username) {
        setUsernameExistsError('Username is required');
        return;
      }

      await signup(values);
      formik.resetForm();
      notify({
        title: 'User Created Successfully',
        content: '',
        severity: NotifySeverity.SUCCESS,
      });
      switchToSignIn();
    } catch (error) {
      console.error('Error during signup:', error);
      let errorMessage = 'An unexpected error occurred';
      if (error instanceof AxiosError && error.response) {
        errorMessage =
          error.response.data.message || 'An unexpected error occurred';
        if (errorMessage.includes('already exists')) {
          if (errorMessage.includes('email')) {
            setEmailExistsError(errorMessage);
          } else if (errorMessage.includes('username')) {
            setUsernameExistsError(errorMessage);
          }
          notify({
            title: 'Error Creating User',
            content: errorMessage,
            severity: NotifySeverity.ERROR,
          });
          return;
        }
      }
      setUsernameExistsError('');
      setEmailExistsError('');
      notify({
        title: 'Error Creating User',
        content: errorMessage,
        severity: NotifySeverity.ERROR,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      isFriend: accountType === AccountType.Friend,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim()
        .required('Username is required.')
        .matches(
          /^[a-zA-Z0-9_-]{5,16}$/,
          'Your username must be at least 5 characters and can include a combination of numbers, letters, and special characters (- and _).'
        )
        .matches(/[a-zA-Z]/, 'Username must contain at least one letter.')
        .matches(
          /^[^\s@]+$/,
          'Username cannot contain spaces or the "@" sign.'
        ),
      email: Yup.string()
        .trim()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email address'
        )
        .required('Email is required.'),
      password: Yup.string()
        .trim()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required.'),
    }),
    onSubmit: (values) => {
      handleSignup({
        username: values.username,
        email: values.email,
        password: values.password,
        isFriend: values.isFriend,
        role:
          accountType === AccountType.Newcomer
            ? UserRole.GUEST
            : UserRole.FRIEND,
      });
    },
  });

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const username = e.target.value;
    const isValidLength = username.length >= 5 && username.length <= 16;
    const isValidUsername = validateUsername(username);

    formik.setFieldTouched('username', true);
    formik.setFieldValue('username', username);
    formik.setFieldError(
      'username',
      isValidLength && isValidUsername ? undefined : 'Invalid username'
    );
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const password = e.target.value;
    const isValidLength = password.length >= 6;
    const isValidPassword = validatePassword(password);

    formik.setFieldTouched(
      'password',
      isValidLength && isValidPassword,
      !isValidPassword
    );
  };

  return (
    <div className="px-4 md:px-52">
      <form onSubmit={formik.handleSubmit} className="my-10">
        <div className="mb-4 border-b-[1px] border-b-gray-300">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-1 group">
              <p className="text-sm text-primaryTextColor flex items-center">
                Account type*
              </p>
              <Tooltip
                content={
                  <div className="p-2">
                    <p className="font-medium mb-1">Newcomer</p>
                    <p className="mb-2">
                      As a newcomer, you can post questions regarding your move
                      in the city.
                    </p>
                    <p className="font-medium mb-1">Become a Friend</p>
                    <p className="mb-2">
                      Becoming a friend, you can provide information on newcomer
                      questions.
                    </p>
                  </div>
                }
                tooltipClassName="w-60 p-4 mt-14"
                position="right"
              >
                <InformationCircleIcon className="w-5 h-5 text-categoryActive" />
              </Tooltip>
            </div>
            <CustomRadioButton
              groupLabel=""
              options={generateOptionsFromEnum(AccountType)}
              selectedValue={accountType}
              onChange={(e: any) => setAccountType(e)}
            />
          </div>
          <div className="mt-4 border-b border-b-grayBorderColor"></div>
        </div>
        <>
          <div className="mb-4">
            <Input
              label="Username*"
              name="username"
              type="text"
              placeholder="Enter your name"
              onChange={handleUsernameChange}
              value={formik.values.username}
              onBlur={formik.handleBlur}
            />
            {formik.touched.username && (
              <p className="text-errorMessageColor text-xs -mt-4">
                {getUsernameErrorMessage(formik.values.username)}
                {usernameExistsError && (
                  <span className="text-red-500 ml-1">
                    {usernameExistsError}
                  </span>
                )}
              </p>
            )}
          </div>
          <div className="mb-4">
            <Input
              label="Email*"
              name="email"
              type="email"
              placeholder="Enter your email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <p className="text-errorMessageColor text-xs -mt-4">
                {formik.errors.email}
              </p>
            )}
            {formik.touched.email && emailExistsError && (
              <p className="text-errorMessageColor text-xs -mt-4">
                {emailExistsError}
              </p>
            )}
          </div>
          <div className="mb-4">
            <PasswordInput
              label="Password*"
              name="password"
              placeholder="Enter your password"
              value={formik.values.password}
              onChange={handlePasswordChange}
            />
            {formik.values.password.length > 0 && !formik.touched.password && (
              <p className="text-errorMessageColor text-xs -mt-4">
                Please enter a password with at least 6 characters should
                include a combination of one uppercase character, one lower case
                character, one numeric, and one special character (!$@%).
              </p>
            )}
            {formik.touched.password && formik.errors.password && (
              <p className="text-errorMessageColor text-xs -mt-4">
                {formik.errors.password}
              </p>
            )}
            {formik.values.password &&
              formik.touched.password &&
              !formik.errors.password && (
                <p className="text-xs text-green-500 -mt-4">
                  Password is valid.
                </p>
              )}
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <CustomCheckbox
              name="termsAndConditions"
              label="I agree"
              checked={isChecked}
              onChange={setIsChecked}
            />
            <div className="text-sm">
              <a href="/terms-and-conditions" className="text-primary">
                Terms of service
              </a>{' '}
              &{' '}
              <a href="/privacy-policy" className="text-primary">
                Privacy policy
              </a>
              .
            </div>
          </div>
          <CustomButton
            label="Sign Up"
            variant="primary"
            customStyle={`w-full h-11 text-sm ${!formik.isValid || !isChecked ? 'bg-primaryDisabled hover:bg-primaryDisabled' : 'bg-primary'}`}
            disabled={!formik.isValid || !isChecked}
            type="submit"
          />
        </>
        <div className="mt-4 text-left flex items-center">
          <span className="text-gray-700 text-sm">
            Already have an account?
          </span>
          <button
            className="text-primary text-sm ml-2"
            onClick={switchToSignIn}
          >
            Sign In Here
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUpContent;
