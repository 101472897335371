import React, { useState } from 'react';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import TextArea from 'components/common/TextArea';

//TODO: Need to remove after API Integration
const cities = [
  { label: 'New York', value: 'NY' },
  { label: 'Los Angeles', value: 'LA' },
];

const ContactUs: React.FC = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    city: '',
    message: '',
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCityChange = (city: string) => {
    setFormValues({ ...formValues, city });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl md:text-4xl lg:text-5xl font-semibold mt-8 mb-4 text-primaryTextColor">
        Contact Us
      </h2>
      <div className="w-full text-center mb-6">
        <p className="text-base md:text-lg lg:text-xl text-primaryTextColor">
          For all enquiries, please message us using the form below.
        </p>
      </div>
      <div className="w-full container p-4 md:p-8 lg:p-12">
        <form
          onSubmit={handleSubmit}
          className="w-full bg-white rounded-lg shadow-md py-8 xs:px-8 sm:px-10 md:px-8 lg:px-56"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="md:col-span-2">
              <Input
                label="Name *"
                name="name"
                placeholder="e.g: Steve Rogers"
              />
            </div>
            <Input
              label="Email *"
              name="email"
              type="email"
              placeholder="e.g: steve@limarktech.com"
            />
            <Dropdown
              label="Your City *"
              name="city"
              options={cities}
              value={formValues.city}
              onChange={handleCityChange}
              placeholder="Select a city"
            />
            <div className="md:col-span-2">
              <TextArea
                label="Message *"
                name="message"
                placeholder="Your message"
                value={formValues.message}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <Button label="Send" variant="primary" customStyle="w-full mt-7" />
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
