import React, { ChangeEventHandler, TextareaHTMLAttributes } from 'react';

type CustomTextAreaProps = {
  label?: string;
  name: string;
  placeholder?: string;
  value: string;
  customStyle?: string;
  labelClass?: string;
  className?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  label,
  name,
  placeholder,
  value,
  customStyle,
  onChange,
  labelClass,
  className = '',
}) => {
  return (
    <div className={`mb-5 ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className={`block mb-1 text-primaryTextColor text-sm ${labelClass}`}
        >
          {label}
        </label>
      )}
      <textarea
        id={name}
        name={name}
        className={`${customStyle} w-full p-3 h-[100px] text-sm focus:outline-none border-blueBorderColor focus-within:border-categoryActive rounded-sm border-[1px] outline-none`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomTextArea;
