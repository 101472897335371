export enum ThreadsSortOptions {
  TOP_RATED_THREADS = 'Top Rated Threads',
  OLDEST_THREADS = 'Oldest Threads',
  LATEST_THREADS = 'Latest Threads',
  MY_QUESTIONS = 'My Questions',
}

export enum FriendFilterOptions {
  ALL = 'All',
  ONLINE_FRIENDS = 'Online Friends',
  TOP_RATED = 'Top Rated Friends',
}

export enum FriendSortOptions {
  TOP_RATED_THREADS = 'Top Rated Threads',
  OLDEST_THREADS = 'Oldest Threads',
  LATEST_THREADS = 'Latest Threads',
}
