import React, { useEffect, useState } from 'react';
import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import CustomCheckbox from 'components/common/Checkbox'; // Ensure this path is correct based on your project structure

type CustomDropdownProps = {
  onSelectionChange?: (selectedValues: string[]) => void;
  label?: string;
  name: string;
  options: { label: string; value: string }[];
  value: string | string[];
  placeholder: string;
  onChange?: (value: string) => void;
  labelClass?: string;
  optionStyle?: string;
  showCheckbox?: boolean;
};

const CheckboxDropdown: React.FC<CustomDropdownProps> = ({
  onSelectionChange,
  label,
  name,
  options,
  value,
  placeholder,
  optionStyle,
  labelClass,
  onChange,
  showCheckbox = false,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any>(() => {
    if (Array.isArray(value)) {
      return options.filter((option) => value.includes(option.value));
    } else {
      return options.filter((option) => option.value === value);
    }
  });

  const handleChange = (optionValue: string, checked: boolean) => {
    let newSelectedOptions = [...selectedOptions];
    if (optionValue === 'All') {
      newSelectedOptions = checked
        ? options
        : [options.find((option) => option.value === '15')!];
    } else {
      if (checked) {
        newSelectedOptions.push(
          options.find((option) => option.value === optionValue)!
        );
      } else {
        newSelectedOptions = newSelectedOptions.filter(
          (option) => option.value !== optionValue
        );
      }
      const allOtherOptions = options.slice(1);
      const allSelected = allOtherOptions.every((opt) =>
        newSelectedOptions.some((selected) => selected.value === opt.value)
      );
      if (allSelected) {
        newSelectedOptions = options;
      } else {
        newSelectedOptions = newSelectedOptions.filter(
          (option) => option.value !== 'All'
        );
      }
    }

    setSelectedOptions(newSelectedOptions);
    onSelectionChange &&
      onSelectionChange(newSelectedOptions.map((option) => option.value));
  };

  const optionTemplate = (option: any) => (
    <div
      className={`p-1 flex items-center ${optionStyle} ml-4 mr-4 border-b-dropDownItemBorder border-b-[1px] last:border-b-transparent`}
      onClick={(e) => e.stopPropagation()}
    >
      {showCheckbox && (
        <CustomCheckbox
          checked={selectedOptions.some(
            (so: { value: any }) => so.value === option.value
          )}
          onChange={(checked) => handleChange(option.value, checked)}
          label={option.label}
          checkBoxTextStyles="text-xs"
        />
      )}
      {!showCheckbox && (
        <span className={`text-textFieldBlue text-xs font-medium`}>
          {option.label}
        </span>
      )}
    </div>
  );

  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedOptions(
        options.filter((option) => value.includes(option.value))
      );
    } else {
      setSelectedOptions(options.filter((option) => option.value === value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="mb-5">
      <label
        htmlFor={name}
        className={`block mb-1 text-sm text-secondary font-medium ${labelClass}`}
      >
        {label}
      </label>
      <Listbox value={value} onChange={onChange}>
        <div className="relative">
          <ListboxButton className="w-full p-2 h-[42px] focus:outline-none rounded-sm text-textFieldBlue text-xs lg:text-sm border-[1px] border-textFieldBlue outline-none focus-within:border-categoryActive text-left flex justify-between items-center">
            {options.find((option) => option.value === value)?.label ||
              placeholder}
            <ChevronDownIcon className="size-4 fill-white/60" />
          </ListboxButton>
          <ListboxOptions className="mt-12 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-xs ring-1 ring-textFieldBlue ring-opacity-5 overflow-auto focus:outline-none lg:text-sm z-10">
            <div className="p-dropdown-panel p-component ">
              {options.map((option) => optionTemplate(option))}
            </div>
          </ListboxOptions>
        </div>
      </Listbox>
    </div>
  );
};

export default CheckboxDropdown;
