import LaptopPlatform from 'assets/images/about-us/laptop-platform.png';
import PlantImage from 'assets/images/how-it-works/plant.png';
import ManImage from 'assets/images/how-it-works/man.png';
import ScheduleImage from 'assets/images/how-it-works/schedule.png';
import { AboutUsItem } from 'model/AboutUsItem';

const HowItWorksData: AboutUsItem[] = [
  {
    title: '1. Search',
    description: (
      <span>
        Newcomers can search for cities that they are interested in relocating
        to, by typing the city name on the search bar or selecting a city from
        the &apos;Top Cities&apos; section and see useful questions posted by
        fellow Newcomers who are planning to move to that city and view answers
        provided by experts (Friends) from that city.
      </span>
    ),
    image: LaptopPlatform,
  },
  {
    title: '2. Ask',
    description: (
      <span>
        You can sign up as a Newcomer to connect with Friends from different
        cities around the world and get to know about their experience in that
        city.
      </span>
    ),
    image: ManImage,
  },
  {
    title: '3. Schedule',
    description: (
      <span>
        You can schedule calls with Friends living in different cities to get to
        know more about the city that you are planning to move to and clarify
        any issues or doubts regarding the city, which in return will help you
        in a smooth transition to the new city.
      </span>
    ),
    image: ScheduleImage,
  },
  {
    title: '4. Reward',
    description: (
      <span>
        As a Friend, share your lived experiences with Newcomers by connecting
        with them and be rewarded.
      </span>
    ),
    image: PlantImage,
  },
];

export default HowItWorksData;
