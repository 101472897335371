import React, { useState } from 'react';
import Modal from 'components/custom-modal/Modal';
import SignInContent from './SignInContent';
import SignUpContent from './SignUpContent';
import ForgotPasswordContent from './ForgotPasswordContent';

type SignInModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose }) => {
  const [isSignIn, setIsSignIn] = useState<boolean>(true);
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);

  const switchToSignUp = () => {
    setIsSignIn(false);
    setIsForgotPassword(false);
  };
  const switchToSignIn = () => {
    setIsSignIn(true);
    setIsForgotPassword(false);
  };
  const switchToForgotPassword = () => {
    setIsForgotPassword(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalStyles="max-w-3xl md:rounded-b-xl md:rounded-t-xxl min-h-screen md:min-h-0 md:mt-[120px]"
      fullScreenStyles="md:p-4 h-auto"
    >
      <Modal.Header
        title={
          isForgotPassword
            ? 'Forgot Password'
            : isSignIn
              ? 'Sign In'
              : 'Sign Up'
        }
        className="bg-secondary text-white md:rounded-t-xl"
        iconStyles="text-white hover:text-white"
      />
      <Modal.Content className="overflow-y-auto">
        {isForgotPassword ? (
          <ForgotPasswordContent switchToSignIn={switchToSignIn} />
        ) : isSignIn ? (
          <SignInContent
            switchToSignUp={switchToSignUp}
            switchToForgotPassword={switchToForgotPassword}
            onLoginSuccess={onClose}
          />
        ) : (
          <SignUpContent switchToSignIn={switchToSignIn} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default SignInModal;
