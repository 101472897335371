import React, { useState } from 'react';
import CustomButton from 'components/common/Button';
import Input from 'components/common/Input';
import EmailIcon from 'assets/images/auth/emailIcon.png';

const InteractiveDemoPage: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  return (
    <div className="container max-w-7xl mb-6 mx-auto p-6 sm:py-10 md:py-20">
      {isSubmitted ? (
        <h1 className="text-5xl text-center font-medium mb-20">
          Are you a Newcomer or a Friend?
        </h1>
      ) : (
        <>
          <h1 className="text-5xl text-center font-medium mb-20">
            Interactive Demo
          </h1>
        </>
      )}

      <div className="bg-white w-full lg:px-52 md:px-14 px-6 rounded-md shadow-md mb-[15px] text-[15px] text-darkblue pb-56 pt-20">
        {isSubmitted ? (
          <div className="flex  flex-col lg:flex-row items-center justify-center gap-10 lg:gap-y-0">
            <div className="flex flex-col items-center bg-white p-6 rounded-md shadow-md lg:w-[500px] ">
              <p className="text-lg font-medium mb-6">Newcomer</p>
              <p className="mb-6 text-xs text-center min-h-36">
                A Newcomer is someone who is planning to relocate to a different
                city. You will get the opportunity to post questions and
                schedule one-to-one calls related to the city and get answers
                from experts who have lived experiences in the city.
              </p>
              <a
                href="/"
                className="h-10 bg-primary w-[160px] flex items-center justify-center text-white rounded-sm text-sm"
              >
                View Demo
              </a>
            </div>
            <div className="flex flex-col items-center bg-white p-6 rounded-md shadow-md  lg:w-[500px] ">
              <p className="text-lg font-medium mb-6">Firend</p>
              <p className="mb-6 text-xs text-center min-h-36">
                A Friend is an individual with lived experience in a specific
                city. Sign-up and share your lived experiences about a city by
                answering questions posted on your expert categories. You can
                also schedule one-to-one calls with Newcomers and be rewarded
                for making their settlement process easier.
              </p>
              <a
                href="/"
                className="h-10 bg-primary w-[160px] flex items-center justify-center text-white rounded-sm text-sm"
              >
                View Demo
              </a>
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-center gap-4">
              <img
                src={EmailIcon}
                alt="email"
                className="mr-2 cursor-pointer"
              />
            </div>
            <p className="mb-4">
              Want to be a part of the newest and largest online community which
              connects individuals who are planning to relocate to a new city
              with people with lived experiences?
            </p>
            <p className="mb-6">
              Type in your email address to continue with your free interactive
              demo.
            </p>
            <label>Email*</label>
            <div className="flex flex-col lg:flex-row items-center mt-3">
              <Input
                type="email"
                placeholder="e.g: steve@limarktech.com"
                className="!mb-5 lg:!mb-0 flex-grow w-full lg:w-auto"
                inputStyles="w-full"
              />
              <CustomButton
                label="Send"
                variant="primary"
                customStyle="px-20 h-[42px] lg:!ml-5 w-full lg:w-auto"
                onClick={handleSubmit}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InteractiveDemoPage;
