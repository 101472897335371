import React from 'react';
import { useNavigate } from 'react-router-dom';

type BlogArticleCardProps = {
  id: string;
  image: string;
  categories: string[];
  date: string;
  title: string;
  description: string;
};

const BlogArticleCard: React.FC<BlogArticleCardProps> = ({
  id,
  image,
  categories,
  date,
  title,
  description,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/${id}`);
  };

  return (
    <div
      className="lg:max-w-[298px] w-full rounded overflow-hidden hover:shadow-lg duration-300 min-h-[450px] cursor-pointer bg-white"
      onClick={handleClick}
    >
      <div className="relative">
        <img className="w-full h-72 object-cover" src={image} alt={title} />
        <div className="absolute top-4 right-4 space-y-1">
          <div className="flex space-x-1 flex-wrap">
            {categories.map((category, index) => (
              <div
                key={index}
                className="bg-overlay text-white text-[10px] rounded-full px-3 py-1 uppercase w-min"
              >
                {category}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="text-xs mb-2 text-secondary">{date}</div>
        <div className="font-semibold text-base mb-2">{title}</div>
        <p className="text-gray-700 text-xs truncate">{description}</p>
      </div>
    </div>
  );
};

export default BlogArticleCard;
