import { useState, useEffect } from 'react';

type ScreenSize = {
  width: number;
  height: number;
};

const debounce = (
  func: { apply: (arg0: undefined, arg1: any[]) => void },
  wait: number | undefined
) => {
  let timeout: string | number | NodeJS.Timeout | undefined;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const useScreenSize = () => {
  const [windowSize, setWindowSize] = useState<ScreenSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 500);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useScreenSize;
