import React, { useState } from 'react';
import Calendar from 'components/common/Calendar';
import TimePills from './TimePills';

const BookingCalendar: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string>('');
  const availableTimes = [
    '9:00 AM - 9:15 AM',
    '10:00 AM - 10:15 AM',
    '11:00 AM - 11:15 AM',
    '1:00 PM - 1:15 PM',
    '1:15 PM - 1:30 PM',
    '2:00 PM - 2:15 PM',
    '2:15 PM - 2:30 PM',
    '3:00 PM - 3:15 PM',
    '3:30 PM - 3:45 PM',
  ];

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setSelectedTime('');
  };

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  return (
    <div className="booking-component flex flex-col md:flex-row md:space-x-8 lg:space-x-4 p-5 py-8 bg-white rounded-xl  md:h-[370px] shadow-md">
      <div className="md:w-1/2 ">
        <Calendar selectedDate={selectedDate} onDateChange={handleDateChange} />
      </div>
      <div className="border-r-[1px] border-r-categoryInactive"></div>
      <div className="mt-10  md:mt-0 md:w-1/2 flex justify-center">
        <TimePills
          selectedDate={selectedDate}
          availableTimes={availableTimes}
          selectedTime={selectedTime}
          onTimeChange={handleTimeChange}
        />
      </div>
    </div>
  );
};

export default BookingCalendar;
