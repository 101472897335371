import React, { ReactNode } from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import { City } from 'model/City';

type LayoutProps = {
  children?: ReactNode;
  session: any;
  cities: City[]; // Add cities prop
};

const Layout: React.FC<LayoutProps> = ({ children, cities }) => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="bg-mainBgColor z-0 flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
