import React, { useState } from 'react';
import PlaceHolderImg from 'assets/images/admin/uploadPlaceHolder.png';

interface ImageUploadProps {
  onChange: (imageUrl: string) => void; // Define the onChange prop
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onChange }) => {
  const [image, setImage] = useState<string | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const imageUrl = reader.result as string;
          setImage(imageUrl);
          onChange(imageUrl); // Trigger onChange with the image URL
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="border-dashed border-2 border-gray-300 p-4 flex justify-center items-center">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden"
        id="image-upload"
      />
      <label htmlFor="image-upload" className="cursor-pointer">
        {image ? (
          <img
            src={image}
            alt="Uploaded preview"
            className="w-full object-cover max-h-[180px]"
          />
        ) : (
          <div className="flex flex-col p-5 items-center justify-center mb-2">
            <img
              src={PlaceHolderImg}
              alt="Placeholder"
              className="w-15  object-cover mb-2"
            />
            <div className="flex">
              <p className="text-secondary text-sm font-medium mb-2">
                Drop your image here
              </p>
              <p className="text-secondary text-sm ml-1 font-medium">
                or{' '}
                <label
                  htmlFor="image-upload"
                  className="cursor-pointer text-blue-500"
                >
                  Browse
                </label>{' '}
                *
              </p>
            </div>

            <p className="text-gray-500 text-sm">Supported formats: JPG, PNG</p>
          </div>
        )}
      </label>
    </div>
  );
};

export default ImageUpload;
