import React, { useEffect, useState, ReactNode } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ModalContext } from 'components/custom-modal/ModalContext';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  modalStyles?: string;
  fullScreenStyles?: string;
};

const Modal: React.FC<ModalProps> & {
  Header: typeof Header;
  Footer: typeof Footer;
  Content: typeof Content;
} = ({ isOpen, onClose, children, modalStyles, fullScreenStyles }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
      document.body.style.overflow = 'hidden';
    } else {
      setShowModal(false);
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen && !showModal) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
          showModal ? 'opacity-100' : 'opacity-0'
        }`}
        aria-hidden="true"
      />
      <div
        className={`flex w-full items-center justify-center ${fullScreenStyles}`}
      >
        <div
          className={`relative bg-white w-full md:rounded-lg shadow-lg transition-transform duration-300 ${
            showModal ? 'transform scale-100' : 'transform scale-95'
          } ${modalStyles}`}
        >
          <ModalContext.Provider value={{ closeModal: onClose }}>
            {children}
          </ModalContext.Provider>
        </div>
      </div>
    </div>
  );
};

const Header: React.FC<{
  title: string;
  className?: string;
  children?: ReactNode;
  iconStyles?: string;
}> = ({ title, className, children, iconStyles }) => {
  const { closeModal } = React.useContext(ModalContext);

  return (
    <div className={`flex items-center justify-between p-4 ${className}`}>
      <h2 className="text-2xl font-medium">{title}</h2>
      {children}
      <button
        onClick={closeModal}
        className="p-1.5  hover:text-gray-700"
        aria-label="Close"
      >
        <XMarkIcon className={`h-6 w-6 ${iconStyles}`} />
      </button>
    </div>
  );
};

const Footer: React.FC<{ className?: string; children?: ReactNode }> = ({
  className,
  children,
}) => (
  <div className={`p-4 border-t border-gray-200 ${className}`}>{children}</div>
);

const Content: React.FC<{ className?: string; children?: ReactNode }> = ({
  className,
  children,
}) => <div className={`${className}`}>{children}</div>;

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Content = Content;

export default Modal;
