import React from 'react';
import Modal from 'components/custom-modal/Modal';
import CustomButton from 'components/common/Button';
import SendIcon from 'assets/images/auth/send.png';

const ConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalStyles="max-w-3xl md:rounded-b-xl md:rounded-t-xxl  md::min-h-screen h-full md:h-auto"
      fullScreenStyles="md:p-4 min-h-screen h-screen md:h-full"
    >
      <Modal.Header
        title=""
        className="bg-white text-secondary md:rounded-t-xl"
        iconStyles="text-secondary hover:text-secondary"
      />
      <Modal.Content>
        <div className=" px-4 md:px-11 py-10 min-h-[50vh]">
          <div className="flex flex-col items-center ">
            <div className="flex justify-center mb-6">
              <img src={SendIcon} alt="email" className="mr-2 cursor-pointer" />
            </div>
            <p className="text-2xl text-center text-secondary font-medium mb-6">
              Are you sure you want to publish this?
            </p>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className="flex md:space-x-4 px-4 md:px-11 justify-between flex-col md:flex-row">
        <CustomButton
          label="No, Go back to editing"
          variant="primary-transparent"
          onClick={onClose}
          customStyle="!mb-8 !md:mb-0"
        />
        <CustomButton
          label="Yes, I want to publish"
          variant="primary"
          onClick={onConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
