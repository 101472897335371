import React, { useEffect, useRef, useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';

type EditableAvatarProps = {
  name: string;
  initialImageUrl?: string;
  size?: string;
  isSelected?: boolean;
  borderStyle?: string;
  charBorderStyles?: string;
  onImageUpload?: (file: File) => void;
  hidePencil?: boolean;
};

const Avatar: React.FC<EditableAvatarProps> = ({
  name,
  borderStyle,
  charBorderStyles,
  initialImageUrl,
  size = 'w-32 h-32',
  isSelected = false,
  hidePencil = true,
  onImageUpload,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    initialImageUrl
  );

  useEffect(() => {
    if (!initialImageUrl) {
      setSelectedImage(undefined);
    }
  }, [initialImageUrl]);

  const handleFileChange = (newFiles: FileList | null) => {
    if (newFiles) {
      const validFiles = Array.from(newFiles).filter((file) =>
        file.type.startsWith('image/')
      );
      if (validFiles.length > 0) {
        const file = validFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result as string;
          setSelectedImage(result);
          if (onImageUpload) {
            onImageUpload(file);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleEditClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div
      className={`${isSelected ? 'border-[3px] border-avatarBorderColor rounded-full font-semibold' : ''}`}
    >
      {selectedImage ? (
        <div
          className={`${size} rounded-full border-2 border-gray-300 shadow-sm relative object-cover`}
        >
          <img
            src={selectedImage}
            alt="Profile"
            className="rounded-full absolute inset-0 w-full h-full object-cover"
          />
          {!hidePencil && (
            <button
              className="absolute bottom-0 right-0 mb-1 mr-1 p-2 bg-secondary text-white rounded-full cursor-pointer flex items-center justify-center"
              onClick={handleEditClick}
              aria-label="Edit"
            >
              <PencilIcon className={`w-3 h-3 `} />
            </button>
          )}
        </div>
      ) : initialImageUrl ? (
        <div
          className={`${size} rounded-full relative object-cover cursor-pointer ${borderStyle}`}
        >
          {initialImageUrl.includes('googleusercontent.com') ? (
            <img
              src={initialImageUrl}
              alt="Profile"
              className="rounded-full absolute inset-0 w-full h-full object-cover"
            />
          ) : (
            <img
              src={initialImageUrl || ''}
              alt="Profile"
              className="rounded-full absolute inset-0 w-full h-full object-cover"
            />
          )}
        </div>
      ) : (
        <div
          className={`${size} rounded-full relative flex items-center justify-center bg-primary text-white text-2xl font-medium cursor-pointer ${charBorderStyles}`}
        >
          {name?.charAt(0).toUpperCase()}
        </div>
      )}
      <input
        multiple
        ref={inputRef}
        type="file"
        onChange={(e) => handleFileChange(e.target.files)}
        accept="image/jpeg,image/png,image/jpg"
        className="hidden"
      />
    </div>
  );
};

export default Avatar;
