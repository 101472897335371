import { useGetAllCities } from 'api/queries/cities';
import DropdownSearchBar from './SearchButtonDropdown';

const HomePageBanner = () => {
  const { data: cities } = useGetAllCities();

  return (
    <div className="p-5 relative overflow-hidden flex h-[560px] 2xl:h-[660px]  3xl:h-[758px] justify-center lg:bg-[url('assets/images/home/hero-img.png')] bg-[url('assets/images/home/hero-mobile.png')] rounded-bl-[40px] rounded-br-[40px] w-full bg-center bg-no-repeat bg-cover">
      <div className="text-white text-center mt-[-100px]">
        <h1 className="text-2xl lg:text-3xl font-light mt-36 md:mt-40 3xl:mt-52">
          Are you settling down in a new city?
        </h1>
        <p className="lg:text-xl font-light text-white mt-9 max-w-[505px] mb-9">
          Learn about your new city from your new friends who are living there
        </p>
        <div className="flex justify-center">
          <DropdownSearchBar
            cities={cities}
            placeHolder="Where to?"
            height="80px"
            buttonStyle="bg-primary rounded-full p-2 w-[60px] h-[60px] cursor-pointer"
            inputStyles="font-sm text-secondary bg-homeSearchContainerColor"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePageBanner;
