import React, { ReactNode, useState } from 'react';

type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  className?: string;
  tooltipClassName?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
};

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  className = '',
  tooltipClassName = '',
  position = 'top',
}) => {
  const [visible, setVisible] = useState(false);

  const getPositionClasses = () => {
    switch (position) {
      case 'top':
        return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
      case 'bottom':
        return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
      case 'left':
        return 'right-full top-1/2 transform -translate-y-1/2 mr-2';
      case 'right':
        return 'left-full top-1/2 transform -translate-y-1/2 ml-2';
      default:
        return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
    }
  };

  return (
    <div
      className={`relative inline-block ${className}`}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div
          className={`absolute ${getPositionClasses()} px-2 py-1 bg-white border-[1px] border-categoryActive text-primaryTextColor text-xs rounded-md ${tooltipClassName}`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
