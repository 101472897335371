import React from 'react';
import Logo from 'assets/images/how-it-works/logo.png';
import TextImgGrid from 'components/common/TextImageGrid';
import HowItWorksData from 'components/how-it-works/HowItWorks';

const HowItWorks: React.FC = () => {
  return (
    <div className="container max-w-7xl flex flex-col justify-center mx-auto mt-14 md:mt-16">
      <div className="flex items-center justify-center mb-100 mx-5">
        <div className="text-2xl md:text-3xl lg:text-4xl text-secondary font-semibold mb-16 md:mb-20">
          How Move2NewCity Works?
        </div>
      </div>
      <TextImgGrid data={HowItWorksData} />
      <div className="flex flex-col items-center mt-4">
        <p className="text-lg text-secondary  font-semibold">Move2NewCity</p>
        <hr className="w-14  border-t-4 border-primary sm:mt-4 sm:mb-12 mt-2 mb-6" />
        <img src={Logo} alt="app-logo" />
      </div>
    </div>
  );
};

export default HowItWorks;
