import { toDate, format } from 'date-fns-tz';
import { toZonedTime } from 'date-fns-tz';

export const addMinutesToTime = (time: string, minutes: number) => {
  const [hour, minutePart] = time.split(':');
  const [minute, periodPart] = minutePart.split(' ');
  let hours = parseInt(hour, 10);
  let min = parseInt(minute, 10);
  let totalMinutes = hours * 60 + min + minutes;
  let period = periodPart;

  hours = Math.floor(totalMinutes / 60);
  min = totalMinutes % 60;

  if (hours === 12 && min === 0) {
    period = period === 'AM' ? 'PM' : 'AM';
  }
  hours = hours % 12;

  if (hours === 0) {
    hours = 12;
  }

  return `${hours.toString().padStart(2, '0')}:${min
    .toString()
    .padStart(2, '0')} ${period}`;
};

export const disableOptions = (
  selectedTime: string,
  options: { value: string }[],
  isStart: boolean
) => {
  const disabledOptions: string[] = [];
  const selectedIndex = options.findIndex(
    (option) => option.value === selectedTime
  );
  if (selectedIndex !== -1) {
    if (isStart) {
      for (let i = selectedIndex; i < options.length; i++) {
        disabledOptions.push(options[i].value);
      }
    } else {
      for (let i = 0; i <= selectedIndex; i++) {
        disabledOptions.push(options[i].value);
      }
    }
  }
  return disabledOptions;
};

export const formatDateTime = (date: Date, time: string, timeZone: string) => {
  const dateTime = new Date(date.toDateString() + ' ' + time);
  return toDate(
    format(dateTime, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone,
    }),
    { timeZone }
  ).toISOString();
};

export const formatDateTimeWithoutTime = (date: Date, timeZone: string) => {
  const dateTime = new Date(date.toDateString());
  return toDate(
    format(dateTime, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone,
    }),
    { timeZone }
  ).toISOString();
};

export const formatTo12HourTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = hours % 12 || 12;
  const period = hours >= 12 ? 'PM' : 'AM';

  return `${formattedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${period}`;
};
export const formatAppointmentTimes = (
  startDateTime: string,
  endDateTime: string,
  timezone: string
) => {
  const startDate = toZonedTime(startDateTime, timezone);
  const endDate = toZonedTime(endDateTime, timezone);

  const formattedStartTime = formatTo12HourTime(startDate);
  const formattedEndTime = formatTo12HourTime(endDate);

  return { formattedStartTime, formattedEndTime };
};

export const dropdownOptions = [
  { label: '12:00 AM', value: '12:00 AM' },
  { label: '12:30 AM', value: '12:30 AM' },
  { label: '01:00 AM', value: '01:00 AM' },
  { label: '01:30 AM', value: '01:30 AM' },
  { label: '02:00 AM', value: '02:00 AM' },
  { label: '02:30 AM', value: '02:30 AM' },
  { label: '03:00 AM', value: '03:00 AM' },
  { label: '03:30 AM', value: '03:30 AM' },
  { label: '04:00 AM', value: '04:00 AM' },
  { label: '04:30 AM', value: '04:30 AM' },
  { label: '05:00 AM', value: '05:00 AM' },
  { label: '05:30 AM', value: '05:30 AM' },
  { label: '06:00 AM', value: '06:00 AM' },
  { label: '06:30 AM', value: '06:30 AM' },
  { label: '07:00 AM', value: '07:00 AM' },
  { label: '07:30 AM', value: '07:30 AM' },
  { label: '08:00 AM', value: '08:00 AM' },
  { label: '08:30 AM', value: '08:30 AM' },
  { label: '09:00 AM', value: '09:00 AM' },
  { label: '09:30 AM', value: '09:30 AM' },
  { label: '10:00 AM', value: '10:00 AM' },
  { label: '10:30 AM', value: '10:30 AM' },
  { label: '11:00 AM', value: '11:00 AM' },
  { label: '11:30 AM', value: '11:30 AM' },
  { label: '12:00 PM', value: '12:00 PM' },
  { label: '12:30 PM', value: '12:30 PM' },
  { label: '01:00 PM', value: '01:00 PM' },
  { label: '01:30 PM', value: '01:30 PM' },
  { label: '02:00 PM', value: '02:00 PM' },
  { label: '02:30 PM', value: '02:30 PM' },
  { label: '03:00 PM', value: '03:00 PM' },
  { label: '03:30 PM', value: '03:30 PM' },
  { label: '04:00 PM', value: '04:00 PM' },
  { label: '04:30 PM', value: '04:30 PM' },
  { label: '05:00 PM', value: '05:00 PM' },
  { label: '05:30 PM', value: '05:30 PM' },
  { label: '06:00 PM', value: '06:00 PM' },
  { label: '06:30 PM', value: '06:30 PM' },
  { label: '07:00 PM', value: '07:00 PM' },
  { label: '07:30 PM', value: '07:30 PM' },
  { label: '08:00 PM', value: '08:00 PM' },
  { label: '08:30 PM', value: '08:30 PM' },
  { label: '09:00 PM', value: '09:00 PM' },
  { label: '09:30 PM', value: '09:30 PM' },
  { label: '10:00 PM', value: '10:00 PM' },
  { label: '10:30 PM', value: '10:30 PM' },
  { label: '11:00 PM', value: '11:00 PM' },
  { label: '11:30 PM', value: '11:30 PM' },
];

export const formatDate = (dateString: string) =>
  new Date(dateString)
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '.');

export const formatTime = (timeString: string) => {
  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
};

export const convert12ToTime = (
  startTimeString: string,
  endTimeString: string
) => {
  const [start, startPeriod] = startTimeString.split(' ');
  const [end, endPeriod] = endTimeString.split(' ');

  const [startHour, startMinutes] = start.split(':');
  const [endHour, endMinutes] = end.split(':');

  let slotStartHour = parseInt(startHour);
  let slotEndHour = parseInt(endHour);

  if (startPeriod === 'PM' && slotStartHour !== 12) {
    slotStartHour += 12;
  }

  if (startPeriod === 'AM' && slotStartHour === 12) {
    slotStartHour = 0;
  }

  if (endPeriod === 'PM' && slotEndHour !== 12) {
    slotEndHour += 12;
  }

  if (endPeriod === 'AM' && slotEndHour === 12) {
    slotEndHour = 0;
  }

  const startDate = new Date();
  startDate.setHours(slotStartHour, parseInt(startMinutes), 0, 0);
  const endDate = new Date();
  endDate.setHours(slotEndHour, parseInt(endMinutes), 0, 0);
  return { startDate, endDate };
};
