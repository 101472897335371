import apiClient from 'api/apiClient';
import {
  CreateAnswerResponse,
  CreatePostRequest,
  CreatePostResponse,
  QuestionVoteRequest,
  QuestionVoteResponse,
} from 'api/mutations/posts/types';

export const createPosts = async (
  payload: CreatePostRequest
): Promise<CreatePostResponse> => {
  try {
    return await apiClient.post('/v1/questions', payload);
  } catch (error) {
    throw new Error(`Error creating new post: ${error}`);
  }
};

export const answerPost = async (
  payload: string,
  questionId: string
): Promise<CreateAnswerResponse> => {
  try {
    return await apiClient.post(`/v1/questions/${questionId}/answers`, payload);
  } catch (error) {
    throw new Error(`Error answering new post: ${error}`);
  }
};

export const questionVote = async (
  payload: QuestionVoteRequest,
  questionId: string
): Promise<QuestionVoteResponse> => {
  try {
    return await apiClient.post(`/v1/questions/${questionId}/vote`, payload);
  } catch (error) {
    throw new Error(`Error question vote: ${error}`);
  }
};
