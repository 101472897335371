import React from 'react';

const Switch: React.FC<{ checked: boolean; onChange: () => void }> = ({
  checked,
  onChange,
}) => {
  return (
    <div
      className={`w-10 h-5 rounded-[12px] bg-white relative cursor-pointer border-[1px] border-categoryActive ${checked ? '!bg-categoryActive' : ''}`}
      onClick={onChange}
    >
      <div
        className={`w-4 h-4 top-[1px] left-[2px] rounded-[50%] bg-categoryActive absolute transition transform duration-200  ${checked ? '!bg-white transform translate-x-5' : ''}`}
      />
    </div>
  );
};

export default Switch;
