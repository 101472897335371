import React from 'react';

type TabButtonProps = {
  label: string;
  isActive: boolean;
  onClick: () => void; //TODO: Implement onClick
};

const TabButton: React.FC<TabButtonProps> = ({ label, isActive, onClick }) => {
  return (
    <button
      className={`w-full h-[50px] px-4 py-2 text-center flex justify-center items-center whitespace-nowrap bg-white ${
        isActive
          ? 'border-primary text-secondary font-bold'
          : 'text-secondary hover:bg-gray-100 font-medium'
      } border-b-2`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default TabButton;
