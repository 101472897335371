import React, {
  ChangeEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
} from 'react';
import CustomTooltip from 'components/common/Tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

type CustomInputProps = {
  label?: string;
  name?: string;
  type?: string;
  inputStyles?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isError?: boolean;
  errorText?: string;
  id?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  labelClass?: string;
  isToolTip?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  labelClass,
  name,
  type = 'text',
  inputStyles,
  placeholder,
  className,
  value,
  onChange,
  isError = false,
  errorText,
  id,
  onBlur,
  disabled,
  isToolTip = false,
  ...rest
}) => {
  return (
    <div className={`mb-5 ${className}`}>
      <label
        htmlFor={name}
        className={` mb-1 text-primaryTextColor text-sm flex items-center ${labelClass}`}
      >
        {label}
        {isToolTip && (
          <CustomTooltip
            position="right"
            content={
              <p className="!text-xs text-tooltipTextColor">
                The user name you provide here will be used when you publish
                question and answers
              </p>
            }
            tooltipClassName={`w-[300px]`}
          >
            <QuestionMarkCircleIcon
              className={`w-4 h-4 ml-2 text-xs cursor-pointer text-rejectButtonColor`}
            />
          </CustomTooltip>
        )}
      </label>

      <input
        id={name}
        name={name}
        type={type}
        className={`w-full p-3 h-[42px] focus:outline-none bg-white rounded-sm border-[1px] border-blueBorderColor outline-none focus-within:border-categoryActive text-sm ${inputStyles}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        {...rest}
      />
      {isError && (
        <label className={`-mt-5 text-errorMessageColor`}>{errorText}</label>
      )}
    </div>
  );
};

export default CustomInput;
