import React from 'react';
import { useRef, useState } from 'react';
import HandsImage from 'assets/images/become-a-friend/hands.png';
import GirlWithPaperImage from 'assets/images/become-a-friend/girl-w-paper.png';
import BulbImage from 'assets/images/become-a-friend/bulb.png';
import Button from 'components/common/Button';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import SignIn from 'components/auth/Signin';
import { useUser } from 'api/context/UserContext';
import { UserRole } from 'constants/User';
import SignOutModal from 'components/become-a-friend/SignOutModal';
import Cookies from 'universal-cookie';

const BecomeAFriend: React.FC = () => {
  const mainDivRef = useRef<HTMLDivElement>(null);
  const user = useUser();
  const isFriend = user?.user?.role === UserRole.FRIEND;
  const isLoggedIn = !!user?.user?.id; // Check if user is logged in
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState<boolean>(false);

  const handleScrollDown = () => {
    if (mainDivRef.current) {
      mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('accessToken', { path: '/' });
    window.location.reload();
  };

  const handleButtonClick = () => {
    if (!isLoggedIn) {
      setIsSignInModalOpen(true);
    } else if (!isFriend) {
      setIsSignOutModalOpen(true);
    }
    console.log('loggedin', isLoggedIn);
  };

  return (
    <div>
      <SignIn
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <SignOutModal
        handleLogout={handleLogout}
        isOpen={isSignOutModalOpen}
        onClose={() => setIsSignOutModalOpen(false)}
      />
      <div className="h-screen md:h-[70vh] lg:h-screen sm:flex">
        <div className="md:w-1/2 bg-darkblue text-white hidden md:flex flex-col items-center justify-center">
          <h1 className="lg:text-7xl md:text-5xl sm:text-6xl">BECOME A</h1>
          <h1 className="lg:text-8xl md:text-6xl  sm:text-7xl font-bold">
            FRIEND
          </h1>
          <ChevronDownIcon
            className="h-10 w-10 text-white mt-16 sm:mt-6 cursor-pointer animate-bounce"
            onClick={handleScrollDown}
          />
        </div>
        <div className="md:w-1/2 relative">
          <img
            src={HandsImage}
            alt="Hands"
            className="w-full h-screen md:h-[70vh] lg:h-screen object-cover"
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center md:hidden">
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-5xl sm:text-7xl">BECOME A</h1>
              <h1 className="text-6xl mb-32 sm:text-8xl font-bold">FRIEND</h1>
              <ChevronDownIcon
                className="h-10 w-10 text-white  sm:mt-6 cursor-pointer animate-bounce"
                onClick={handleScrollDown}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        ref={mainDivRef}
        className="flex flex-col items-center mt-6 justify-center px-5 sm:mx-auto lg:flex-row container w-full max-w-7xl"
      >
        <div className="w-full lg:w-7/12 bg-white py-10 px-5 md:py-20 md:px-11 lg:mr-11 rounded-lg mb-8">
          <h1 className="text-lg md:text-4xl text-secondary font-medium">
            Who Should be a FRIEND?
          </h1>
          <div className={`w-16 h-0.5 bg-primary mt-2`}></div>
          <p className="text-primaryTextColor text-sm md:text-lg mt-6 md:mt-12">
            What better way is there to help people than through a platform
            where you, along with other people, can come together and help
            newcomers? Not only that, being a FRIEND through Move2NewCity can be
            your new side hustle. You can be a FRIEND if you would like to share
            your lived experiences of a city. By sharing your experience and
            knowledge with others, you are helping newcomers have a seamless
            settlement. One piece of advice or a helping hand can go a long way,
            especially to someone settling in a new city.
          </p>
        </div>
        <div className="lg:w-5/12">
          <img
            src={GirlWithPaperImage}
            alt="Girl-with-paper"
            className="mb-12 h-[294px] lg:h-[594px] object-cover rounded-lg"
          />
        </div>
      </div>
      <div>
        <div className="bg-darkblue sm:h-96 sm:p-8 text-white flex text-center flex-col items-center justify-center">
          <h1 className="text-2xl p-4 sm:text-6xl font-medium ">
            Share your lived experiences
          </h1>
          <h1 className="text-2xl sm:text-6xl font-medium">and be rewarded</h1>
          <div className={`w-16 h-0.5 mb-4 bg-primary mt-8`}></div>
        </div>
      </div>
      <div className="flex flex-col-reverse items-center my-12 justify-center px-5 sm:mx-auto lg:flex-row container w-full max-w-7xl">
        {(!isLoggedIn || !isFriend) && (
          <Button
            label="Become a Friend"
            variant="primary"
            customStyle="py-2 px-4 rounded text-center lg:hidden block w-full sm:w-5/6 md:w-4/6"
            onClick={handleButtonClick}
          />
        )}
        <div className="lg:w-5/12 lg:mr-11 my-12 lg:my-0">
          <img src={BulbImage} alt="bulb" className="rounded-lg object-cover" />
        </div>
        <div className="lg:w-7/12 flex flex-col  bg-white py-10 px-5 md:py-20 md:px-11 rounded-lg">
          <h1 className="text-lg md:text-4xl text-secondary font-medium">
            How It Works?
          </h1>
          <div className={`w-16 h-0.5 bg-primary mt-2`}></div>
          <p className="text-primaryTextColor text-sm md:text-lg mt-6 md:mt-12 ">
            A person moving to a new city can find lived experiences here.
            <b> Friends</b> who share their lived experiences will help
            Newcomers to gain valuable insights about the city that they are
            moving to. It is just as easy to <b> make a video call</b> and talk
            face-to-face with the Newcomer if you wish, based on rates
            determined by you. You will also get the opportunity to help
            Newcomers through video calls at a rate that is determined by
            Friends. Move2NewCity will help the
            <b> Newcomer</b> connect with Friends through the platform. So, what
            are you waiting for? Become a friend by completing this quick
            process. Move2NewCity is completely <b>free to sign-up.</b>
          </p>
          {(!isLoggedIn || !isFriend) && (
            <Button
              label="Become a Friend"
              variant="primary"
              customStyle="mt-8 mr-80 py-2 px-4 rounded text-center hidden lg:block whitespace-nowrap"
              onClick={handleButtonClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BecomeAFriend;
