import React from 'react';
import { useNavigate } from 'react-router-dom';

type BlogArticleCardProps = {
  id: string;
  image: string;
  categories: string[];
  date: string;
  title: string;
  description: string;
};

const EditorsPickCard: React.FC<BlogArticleCardProps> = ({
  id,
  image,
  categories,
  date,
  title,
  description,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/${id}`);
  };

  return (
    <div
      className="relative w-full max-w-full rounded overflow-hidden hover:shadow-lg duration-300"
      onClick={handleClick}
    >
      <img className="w-full h-[400px] object-cover" src={image} alt={title} />
      <div className="absolute inset-0 hover:bg-black hover:bg-opacity-50 duration-300 hover:cursor-pointer">
        <div className="absolute top-4 right-4 flex space-x-1 flex-wrap">
          {categories.map((category, index) => (
            <div
              key={index}
              className="bg-overlay text-white text-[10px] rounded-full px-3 py-1 uppercase w-min"
            >
              {category}
            </div>
          ))}
        </div>
        <div className="absolute bottom-4 left-4  text-white p-4 w-full">
          <div className="text-xs mb-6">{date}</div>
          <div className="text-2xl font-bold mb-2">{title}</div>
          <div className="text-xs mb-2">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default EditorsPickCard;
