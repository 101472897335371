import React, { useState } from 'react';
import faqData from 'components/faqs/FaqsData';
import { FAQData, FAQItem, FAQSection } from 'model/Faq';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const FAQs: React.FC = () => {
  const [openType, setOpenType] = useState<number | null>(null);
  const [openSection, setOpenSection] = useState<number | null>(null);

  const toggleType = (typeId: number) => {
    setOpenType((prevOpenType) => (prevOpenType === typeId ? null : typeId));
    setOpenSection(null);
  };

  const toggleSection = (sectionId: number) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === sectionId ? null : sectionId
    );
  };

  return (
    <div className="container max-w-7xl mb-6 mx-auto p-6 sm:py-10 md:py-20">
      <h1 className="text-5xl text-center font-medium">FAQs</h1>
      <p className="text-lg text-center mt-8 mb-20">
        Frequently asked questions about Move2NewCity
      </p>

      <div>
        {faqData.map((faq: FAQData) => (
          <div key={faq.id}>
            <div
              className="flex cursor-pointer text-[22px] mt-12 pb-2"
              onClick={() => toggleType(faq.id)}
            >
              <strong className={`font-semibold text-secondary`}>
                {faq.type}
              </strong>
              <span className="ml-auto">
                {openType === faq.id ? (
                  <ChevronUpIcon className="h-6 w-6 text-black  cursor-pointer mr-5 " />
                ) : (
                  <ChevronDownIcon className="h-6 w-6 text-black  cursor-pointer mr-5" />
                )}
              </span>
            </div>
            <div className={`w-20 h-[1.5px] bg-primary`}></div>
            <div
              className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                openType === faq.id ? 'min-h-auto' : 'max-h-0'
              }`}
            >
              {faq.sections.map((section: FAQSection) => (
                <div key={section.id}>
                  <div
                    className="flex items-center cursor-pointer text-lg bg-white mt-7 p-3 rounded-sm px-5"
                    onClick={() => toggleSection(section.id)}
                  >
                    <span></span>
                    <strong className={`font-semibold text-secondary`}>
                      {section.title}
                    </strong>
                    <span className="ml-auto">
                      {openSection === section.id ? (
                        <ChevronUpIcon className="h-6 w-6 text-black  cursor-pointer " />
                      ) : (
                        <ChevronDownIcon className="h-6 w-6 text-black  cursor-pointer " />
                      )}
                    </span>
                  </div>
                  <div
                    className={`transition-max-height duration-500 ease-in-out overflow-hidden ${
                      openSection === section.id ? 'max-h-auto' : 'max-h-0'
                    }`}
                  >
                    <div className="p-3 px-5">
                      {section.items.map((item: FAQItem) => (
                        <div key={item.id} className="mb-6">
                          <div className="flex items-center">
                            <span
                              className={`w-6 h-[2px] bg-primary mr-4 mb-6`}
                            ></span>
                            <strong className="text-secondary text-lg font-semibold mb-6">
                              {item.question}
                            </strong>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.answer,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
