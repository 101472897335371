import React, { createContext, useContext, ReactNode } from 'react';
import { useGetLoggedUserDetails } from 'api/queries/users';
import Cookies from 'universal-cookie';

type UserContextType = {
  user: any;
  isLoading: boolean;
  refetch: () => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const cookies = new Cookies();

  const {
    data: user,
    isLoading,
    refetch,
  } = useGetLoggedUserDetails({
    enabled: cookies.get('accessToken') ? true : false,
  });

  return (
    <UserContext.Provider value={{ user: user, isLoading, refetch }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
