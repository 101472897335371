export enum AccountType {
  Friend = 'Friend',
  Newcomer = 'Newcomer',
}

export enum UserRole {
  GUEST = 'GUEST',
  FRIEND = 'FRIEND',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'super_admin',
}
