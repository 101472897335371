import { useEffect, useMemo, useState } from 'react';
import AvailabilitySlots from 'components/availability-slots/AvailabilitySlots';
import CustomDropdown from 'components/common/Dropdown';
import { timeZones } from 'utils/timezones';
import CustomCheckboxDropdown from 'components/common/CheckboxDropdown';
import AvailabilityComponent, {
  DateCategory,
} from 'components/data-override-modal/AvailabilityComponent';
import { useUser } from 'api/context/UserContext';
import { useGetAvailabilityByUserId } from 'api/queries/availability';
import { updateUser } from 'api/requests/auth';
import { DaysOfWeek } from 'constants/Common';
import { useAddNewAvailability } from 'api/mutations/availability';
import { formatDateTime } from 'utils/availabilityUtils';
import notify, { NotifySeverity } from 'components/common/Notification';

export enum DurationOption {
  All = 'All',
  Minutes15 = '15',
  Minutes30 = '30',
  Minutes45 = '45',
}

const durationLabels: { [key in DurationOption]: string } = {
  [DurationOption.All]: 'All',
  [DurationOption.Minutes15]: '15 minutes',
  [DurationOption.Minutes30]: '30 minutes',
  [DurationOption.Minutes45]: '45 minutes',
};
const availabilityDropdownOptions = Object.keys(DurationOption).map((key) => ({
  label: durationLabels[DurationOption[key as keyof typeof DurationOption]],
  value: DurationOption[key as keyof typeof DurationOption],
}));

const Availability = () => {
  const { user } = useUser();
  const timeZone = user?.timeZone;
  const availabilityDuration = useMemo(
    () => user?.appointmentDuration || [],
    [user]
  );
  const updateSegment = useAddNewAvailability();
  const [selectedTime, setSelectedTime] = useState<
    Map<string, { startTime: Date; endTime: Date }[]>
  >(new Map());
  const [dateCategoryLines, setDateCategoryLines] = useState<DateCategory[]>(
    []
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(timeZone);
  const [estimatedDuration, setEstimatedDuration] =
    useState<string[]>(availabilityDuration);

  const { data: availability = [] } = useGetAvailabilityByUserId(
    user?.id || ''
  );

  useEffect(() => {
    const generatedOptions = availabilityDuration.map((value: number) =>
      value.toString()
    );
    setEstimatedDuration(generatedOptions);
  }, [availabilityDuration]);

  const handleTimeZoneChange = async (value: any) => {
    setSelectedTimeZone(value);
    try {
      const payload = { ...user };
      payload.timeZone = value;
      await updateUser(user?.id, payload);

      const updateAvailabilityPromises = [];

      for (let [day, times] of Array.from(selectedTime)) {
        const dayNumber =
          DaysOfWeek[day.toUpperCase() as keyof typeof DaysOfWeek];
        const availabilityId = availability?.find(
          (availability) => Number(availability.dayOfWeek) === dayNumber
        )?.id;

        if (availabilityId) {
          const payload = {
            availabilityId,
            userId: user?.id,
            availabilityRequest: {
              friendId: user?.id,
              dayOfWeek: dayNumber,
              segments: times.map(
                (segment: { startTime: Date; endTime: Date }) => {
                  const formattedStartTime = segment.startTime.toLocaleString(
                    value,
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }
                  );
                  const formattedEndTime = segment.startTime.toLocaleString(
                    value,
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }
                  );
                  return {
                    startTime: formattedStartTime,
                    endTime: formattedEndTime,
                  };
                }
              ),
              available: true,
              type: 'DAY',
            },
          };

          updateAvailabilityPromises.push(
            updateSegment.mutate(payload, {
              onSuccess: (data) => {},
            })
          );
        }
      }

      for (const category of dateCategoryLines) {
        const availabilityId = category.availabilityId;
        const formattedDate = formatDateTime(
          new Date(category.date),
          '12:00 AM',
          value
        );
        const segments = category.category.map((segment) => {
          const [startTimeStr, endTimeStr] = segment.timeSlot.split(' - ');
          const formattedStartTime = formatDateTime(
            new Date(category.date),
            startTimeStr,
            value
          );
          const formattedEndTime = formatDateTime(
            new Date(category.date),
            endTimeStr,
            value
          );

          return {
            startTime: formattedStartTime,
            endTime: formattedEndTime,
          };
        });

        const payload = {
          availabilityId,
          userId: user?.id,
          availabilityRequest: {
            friendId: user?.id,
            date: formattedDate,
            segments,
            available: category.isAvailable,
            type: 'DATE',
          },
        };
        updateAvailabilityPromises.push(
          updateSegment.mutate(payload, {
            onSuccess: (data) => {},
          })
        );
      }

      await Promise.all(updateAvailabilityPromises).then((value) => {
        notify({
          title: 'Success!',
          content: 'Time zone and availabilities updated successfully.',
          severity: NotifySeverity.SUCCESS,
        });
      });
    } catch (error) {
      notify({
        title: 'Error!',
        content:
          'An error occurred while updating the time zone and availabilities.',
        severity: NotifySeverity.ERROR,
      });
    }
  };

  const handleDurationChange = async (selectedDurations: string[]) => {
    setEstimatedDuration(selectedDurations);
    const payload = { ...user };
    payload.appointmentDuration = selectedDurations;
    await updateUser(user?.id, payload);
  };

  const getLabelByValue = (value: string) => {
    return durationLabels[value as DurationOption] || value;
  };

  const filteredDurations = estimatedDuration.filter(
    (duration) => duration.toString() !== DurationOption.All
  );
  const selectedLabels = filteredDurations.map(getLabelByValue).join(', ');

  return (
    <div className="lg:px-10 px-4 py-10 lg:py-0">
      <h2 className="text-lg font-semibold mb-4 text-secondary">
        Schedule your available times
      </h2>
      <div className="flex flex-col lg:flex-row mb-3 w-full lg:gap-10">
        <div className="lg:w-1/2">
          <CustomCheckboxDropdown
            placeholder={selectedLabels}
            options={availabilityDropdownOptions}
            onSelectionChange={handleDurationChange}
            value={estimatedDuration}
            showCheckbox={true}
            name="duration"
            label="Set Estimated Call Duration *"
            labelClass="!mb-3"
          />
        </div>
        <div className="lg:w-1/2">
          <CustomDropdown
            placeholder={timeZone}
            options={timeZones}
            onChange={handleTimeZoneChange}
            value={selectedTimeZone}
            label="Select Your Time Zone *"
            labelClass="text-sm text-secondary font-medium !mb-3"
            name=""
            customPlaceHolder="text-textFieldBlue border-textFieldBlue"
          />
        </div>
      </div>
      <hr className="my-4 pr-3 border-textFieldBlue" />
      <AvailabilitySlots
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        availabilitiesData={availability}
        user={user}
      />
      <div className="mt-9">
        <div className="text-sm font-semibold pb-4 text-secondary">
          Add Date Overrides
        </div>
        <div className="">
          <AvailabilityComponent
            availabilitiesData={availability}
            dateCategoryLines={dateCategoryLines}
            setDateCategoryLines={setDateCategoryLines}
          />
        </div>
      </div>
    </div>
  );
};

export default Availability;
