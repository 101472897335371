import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from 'components/custom-modal/Modal';
import CustomInput from 'components/common/Input';
import CustomDropdown from 'components/common/Dropdown';
import CustomTextArea from 'components/common/TextArea';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CustomButton from 'components/common/Button';
import ConfirmationModal from 'components/city/ConfirmationModal';
import { useGetAllCities } from 'api/queries/cities';
import { useGetAllCategories } from 'api/queries/categories';
import { useCreatePosts } from 'api/mutations/posts';
import { CreatePostRequest } from 'api/mutations/posts/types';
import { City } from 'model/City';
import { Category } from 'model/Category';
import notify, { NotifySeverity } from 'components/common/Notification';

const AskQuestionModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const createNewPost = useCreatePosts();

  const handleModalClose = () => {
    formik.resetForm(); // This will reset both form values and error messages
    onClose(); // Call the onClose function passed from the parent
  };

  const {
    data: allCities,
    isLoading: citiesLoading,
    isError: citiesError,
  } = useGetAllCities();
  const {
    data: allCategories,
    isLoading: categoriesLoading,
    isError: categoriesError,
  } = useGetAllCategories();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);

  const citiesOptions = allCities?.map((city: City) => ({
    label: city.name,
    value: city.id,
  }));

  const categoriesOptions = allCategories?.map((category: Category) => ({
    label: category.name,
    value: category.id,
  }));

  const validationSchema = Yup.object().shape({
    question: Yup.string().required('Question is required.'),
    city: Yup.string().required('City is required.'),
    description: Yup.string(),
    categories: Yup.array().min(1, 'Select at least one category.'),
  });

  const formik = useFormik({
    initialValues: {
      question: '',
      city: '',
      description: '',
      categories: [] as string[],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsConfirmationModalVisible(true);
    },
  });

  const handleSave = async (values: CreatePostRequest) => {
    try {
      createNewPost.mutate(values, {
        onSuccess: (data) => {
          notify({
            title: 'Success',
            content: 'Published successfully.',
            severity: NotifySeverity.SUCCESS,
          });
          setSelectedCategories([]);
          formik.resetForm();
          setIsConfirmationModalVisible(false);
          onClose();
          //navigate(`/city/${data.data.cityId}`);
        },
      });
    } catch (error) {
      notify({
        title: 'Error!',
        content: createNewPost.error?.message ?? 'Something went wrong!',
        severity: NotifySeverity.ERROR,
      });
    }
  };

  const handleConfirm = async () => {
    handleSave({
      title: formik.values.question,
      cityId: formik.values.city,
      body: formik.values.description,
      categoryIds: formik.values.categories,
      type: 'QUESTION',
    });
  };

  const handleCategorySelect = (categoryId: string) => {
    setSelectedCategories((prevSelected) => {
      const updatedCategories = prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId)
        : [...prevSelected, categoryId];
      formik.setFieldValue('categories', updatedCategories);
      return updatedCategories;
    });
  };

  useEffect(() => {
    if (createNewPost.error) {
      notify({
        title: 'Something went wrong!',
        content: createNewPost.error?.message,
        severity: NotifySeverity.ERROR,
      });
    }
  }, [createNewPost]);

  if (citiesLoading || categoriesLoading) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }

  if (citiesError || categoriesError) {
    return (
      <div className="flex items-center justify-center">
        <div>Error fetching data. Please try again later.</div>
      </div>
    );
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        fullScreenStyles="lg:p-0"
        modalStyles="md:rounded-none min-h-screen p-5"
      >
        <div className="flex justify-end py-4">
          <button
            onClick={handleModalClose}
            className="p-1.5 text-gray-500 hover:text-gray-700"
            aria-label="Close"
          >
            <XMarkIcon className="h-8 w-8 text-black" />
          </button>
        </div>
        <div className="flex items-center justify-center">
          <Modal.Content>
            <div className="text-2xl font-medium mb-10">
              <span> Ask a question</span>
              <hr className="w-14 border-t-4 border-primary sm:mt-4 sm:mb-12 lg:mb-11" />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <CustomInput
                  label="Question *"
                  name="question"
                  placeholder="e.g: Type your question here"
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.question && formik.errors.question && (
                  <p className="text-errorMessageColor text-xs -mt-3 mb-5">
                    {formik.errors.question as React.ReactNode}
                  </p>
                )}
              </div>
              <div>
                <CustomTextArea
                  label="Description"
                  name="description"
                  placeholder="e.g: Explain your question"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.description && formik.errors.description && (
                  <p className="text-errorMessageColor text-xs -mt-3 mb-5">
                    {formik.errors.description as React.ReactNode}
                  </p>
                )}
              </div>
              <div>
                <div className="mb-5">
                  <label className="block mb-3 text-primaryTextColor text-sm">
                    Under what category does your question fall? *
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {categoriesOptions?.map((cat) => (
                      <button
                        key={cat.value}
                        type="button"
                        className={`px-3 py-2 rounded-full border text-xs ${
                          selectedCategories.includes(cat.value)
                            ? 'bg-categoryActive text-white'
                            : 'bg-categoryInactive text-gray-700'
                        }`}
                        onClick={() => handleCategorySelect(cat.value)}
                      >
                        {cat.label}
                      </button>
                    ))}
                  </div>
                </div>
                {formik.touched.categories && formik.errors.categories && (
                  <p className="text-errorMessageColor text-xs -mt-3 mb-5">
                    {formik.errors.categories as React.ReactNode}
                  </p>
                )}
              </div>
              <div>
                <CustomDropdown
                  label="City *"
                  name="city"
                  options={citiesOptions || []}
                  value={formik.values.city}
                  placeholder="Select City"
                  onChange={(value) => formik.setFieldValue('city', value)}
                />
                {formik.touched.city && formik.errors.city && (
                  <p className="text-errorMessageColor text-xs -mt-3 mb-5">
                    {formik.errors.city as React.ReactNode}
                  </p>
                )}
              </div>

              <CustomButton
                label="Submit"
                variant="primary"
                customStyle="mt-7 w-[100px] md:text-sm"
                type="submit"
              />
            </form>
          </Modal.Content>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalVisible}
        onClose={() => setIsConfirmationModalVisible(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default AskQuestionModal;
