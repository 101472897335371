import React from 'react';
import CookiePolicyData from 'components/cookie-policy/CookiePolicyData';
import { CookiePolicyItem } from 'model/CookiePolicyItem';

const CookiePolicy: React.FC = () => {
  return (
    <div className="min-h-screen p-6 sm:py-10 md:py-20 container text-center mx-auto max-w-7xl">
      <div className="flex flex-col justify-center mb-5">
        <h1 className="text-xl md:text-2xl lg:text-5xl font-semibold mb-2">
          Cookie Policy
        </h1>
        <h2 className="sm:text-base md:text-lg lg:text-xl text-gray-600 ">
          <b>Last Updated</b>: December 1st, 2023
        </h2>
      </div>

      <div className="text-left">
        {CookiePolicyData.map((item: CookiePolicyItem, index: number) => (
          <div key={index} className="mb-8">
            <h3 className="text-lg lg:text-2xl font-semibold mb-2 mx-4">
              {item.title}
            </h3>
            <div className="w-20 h-[3px] bg-primary mt-2 mx-4"></div>
            <p className="text-sm md:text-base text-gray-700 mx-4">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CookiePolicy;
