import React from 'react';

import IconEmployment from 'assets/images/home/emplyement.svg';
import IconEducation from 'assets/images/home/education.svg';
import IconCommmunity from 'assets/images/home/community.svg';
import IconFood from 'assets/images/home/food.svg';
import IconHealthCare from 'assets/images/home/healthcare.svg';
import IconRecreational from 'assets/images/home/recreational.svg';
import IconTransport from 'assets/images/home/transport.svg';
import IconHousing from 'assets/images/home/housing.svg';

type Feature = {
  id: number;
  title: string;
  icon: string;
};

const featureData: Feature[] = [
  {
    id: 1,
    title: 'Employment',
    icon: IconEmployment,
  },
  {
    id: 2,
    title: 'Education',
    icon: IconEducation,
  },
  {
    id: 3,
    title: 'Healthcare',
    icon: IconHealthCare,
  },
  {
    id: 4,
    title: 'Recreational',
    icon: IconRecreational,
  },
  {
    id: 5,
    title: 'Housing',
    icon: IconHousing,
  },
  {
    id: 6,
    title: 'Transport',
    icon: IconTransport,
  },
  {
    id: 7,
    title: 'Food',
    icon: IconFood,
  },
  {
    id: 8,
    title: 'Community',
    icon: IconCommmunity,
  },
];

const FeatureItem: React.FC<Feature> = ({ title, icon }) => {
  return (
    <div className="flex flex-col flex-wrap items-center">
      <div className="flex justify-center items-center bg-homeItemBgColor border border-homeItemBorder w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-20 lg:h-20 xl:w-28 xl:h-28  shadow-md rounded-lg">
        <img className="w-10 h-auto" src={icon} alt={`icon-${title}`} />
      </div>
      <div className="text-primaryTextColor text-[10px] md:text-sm text-center mt-2">
        {title}
      </div>
    </div>
  );
};

const HomeFeatures: React.FC = () => {
  return (
    <div className="bg-white py-12 px-5 md:px-9 w-full lg:w-7/12 rounded-md flex flex-col">
      <h3 className="text-sm font-medium sm:text md:text-[28px] lg:leading-7 md:font-normal text-secondary">
        Wish you had a friend in the new city?
      </h3>
      <hr className="w-12 md:w-14 border-t-2 md:border-t-4 border-primary mb-4 sm:mt-4 sm:mb-12 lg:mb-11" />
      <p className="text-xs font-medium text-secondary md:font-medium md:text-xl">
        Find them on Move2NewCity
      </p>
      <p className="text-[10px] md:text-sm mt-4 text-primaryTextColor">
        With Move2NewCity, you can find friends who can share lived experiences
        of the new city you are moving to. It is the platform that helps you
        with the settlement process when moving to the new city. Newcomers can
        find friends to gain knowledge and hear real stories to make their
        settlement experience seamless.
      </p>
      <div className=" grid grid-cols-3 md:grid-cols-4 gap-x-9 md:gap-x-10 gap-y-9 md:gap-y-11 pt-9 md:pt-16">
        {featureData.map((feature, index) => (
          <FeatureItem
            id={feature.id}
            title={feature.title}
            icon={feature.icon}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeFeatures;
