import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

enum NotifySeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARN = 'warn',
}

enum NotifyType {
  APP = 'app',
  REALTIME = 'real-time',
}

type AppNotificationProps = {
  type?: NotifyType;
  severity: NotifySeverity;
  title: string;
  content?: string;
  selectedCategories?: string[];
  timestamp?: string;
};

const notify = ({
  type = NotifyType.APP,
  severity = NotifySeverity.INFO,
  title = 'Success',
  content = 'Action succeeded',
  selectedCategories = [],
  timestamp,
}: AppNotificationProps): void => {
  const toastContent = (
    <div className="toast-content">
      <p className="font-semibold text-sm">{title}</p>
      <p className="content text-xs">{content}</p>
      {type === NotifyType.REALTIME && (
        <div className="realtime-categories flex flex-wrap">
          {selectedCategories.map((category) => (
            <div
              key={category}
              className="realtime-category bg-categoryBg mr-2 p-1 rounded-sm"
            >
              {category}
            </div>
          ))}
        </div>
      )}
      <p className="timestamp text-timestamp">{timestamp}</p>
    </div>
  );

  const notifyTheme: string = (() => {
    switch (severity) {
      case NotifySeverity.ERROR:
        return 'bg-errorMsgBg text-black';
      case NotifySeverity.SUCCESS:
        return 'bg-successMsgBg text-black';
      case NotifySeverity.INFO:
        return 'text-black';
      case NotifySeverity.WARN:
        return 'text-black';
      default:
        return '';
    }
  })();

  toast[severity](toastContent, {
    position: type === NotifyType.APP ? 'top-right' : 'bottom-left',
    className: notifyTheme,
    hideProgressBar: true,
    autoClose: 10000,
  });
};

export { NotifySeverity, NotifyType };
export default notify;
