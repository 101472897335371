export const AUTH_ERROR = 'Access Denied';

interface StringIndexedObject {
  [key: string]: number;
}

export const DaysOfWeek: StringIndexedObject = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};
