import React from 'react';
import { RadioGroup, Label, Radio } from '@headlessui/react';

type Option = {
  value: string;
  label: React.ReactNode;
};

type CustomRadioButtonProps = {
  options: Option[];
  selectedValue: string;
  onChange: (value: string) => void;
  groupLabel?: string;
};

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  options,
  selectedValue,
  onChange,
  groupLabel,
}) => {
  return (
    <RadioGroup
      value={selectedValue}
      onChange={onChange}
      className="flex items-center space-x-4 justify-between"
    >
      {groupLabel && (
        <Label className="text-sm  text-primaryTextColor">{groupLabel}</Label>
      )}
      <div className="flex space-x-4 text-sm">
        {options.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            className="flex items-center gap-2"
          >
            {({ checked }) => (
              <>
                <div
                  className={`w-4 h-4 min-h-4 flex items-center justify-center rounded-full border-2 transition duration-200 ease-in-out ${
                    checked
                      ? 'border-categoryActive bg-white'
                      : 'border-grayBorderColor'
                  }`}
                >
                  {checked && (
                    <div className="w-2 h-2 rounded-full bg-categoryActive"></div>
                  )}
                </div>
                <span className="text-gray-700">{option.label}</span>
              </>
            )}
          </Radio>
        ))}
      </div>
    </RadioGroup>
  );
};

export default CustomRadioButton;
