import { FC, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  UserIcon,
  PhoneIcon,
  ClockIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import ScheduledCalls from 'components/scheduled-calls/ScheduledCall';
import EditUser from 'components/profile/EditUser';
import Availability from 'components/availability/Availability';
import { useUser } from 'api/context/UserContext';
import { UserRole } from 'constants/User';

enum AccountType {
  Friend = 'friend',
  Newcomer = 'newcomer',
}

enum TabIdentifier {
  Account = 'account',
  Notifications = 'notifications',
  Payments = 'payments',
  ScheduledCalls = 'scheduled-calls',
  Availability = 'availability',
}

const verticalTabs = [
  {
    id: 1,
    identifier: TabIdentifier.Account,
    name: 'Account',
    icon: <UserIcon className="h-4 w-4 hidden lg:block" />,
    accessPolicy: [AccountType.Friend, AccountType.Newcomer],
    isVisible: true,
  },
  {
    id: 2,
    identifier: TabIdentifier.ScheduledCalls,
    name: 'Scheduled calls',
    icon: <PhoneIcon className="h-4 w-4 hidden lg:block" />,
    accessPolicy: [AccountType.Friend, AccountType.Newcomer],
    isVisible: true,
  },
  {
    id: 3,
    identifier: TabIdentifier.Availability,
    name: 'Availability',
    icon: <ClockIcon className="h-4 w-4 hidden lg:block" />,
    accessPolicy: [AccountType.Friend],
    isVisible: true,
  },
];

const RenderViewWithCurrentTab = (
  idx: TabIdentifier,
  accountStatus: boolean
) => {
  switch (idx) {
    case TabIdentifier.Account:
      return <EditUser />;
    case TabIdentifier.Notifications:
      return (
        <div>
          Notifications Component - Newcomer: {accountStatus.toString()}
        </div>
      );
    case TabIdentifier.Payments:
      return (
        <div>Payments Component - Newcomer: {accountStatus.toString()}</div>
      );
    case TabIdentifier.ScheduledCalls:
      return <ScheduledCalls />;
    case TabIdentifier.Availability:
      return <Availability />;
    default:
      return null;
  }
};

const Profile: FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const queryParams = new URLSearchParams(search);
  const tabParam = queryParams.get('tabs');

  const isNewComer = user?.user?.role === UserRole.GUEST;
  const [currentTab, setCurrentTab] = useState<TabIdentifier>(
    tabParam ? (tabParam as TabIdentifier) : TabIdentifier.Account
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (tabParam) {
      setCurrentTab(tabParam as TabIdentifier);
    }
  }, [tabParam]);

  const handleTabChange = (identifier: TabIdentifier) => {
    const accessibleTabs = verticalTabs.some(
      (e) =>
        e.identifier === identifier &&
        e.accessPolicy.includes(
          isNewComer ? AccountType.Newcomer : AccountType.Friend
        )
    );
    if (accessibleTabs) {
      setCurrentTab(identifier);
      setIsDropdownOpen(false);
      navigate(`/user-profile?tabs=${identifier}`);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-6 lg:px-8 min-h-screen">
      <div className="bg-mainBgColor flex flex-col lg:flex-row w-full gap-6 min-h-screen">
        <div className="bg-white w-full lg:w-3/12 lg:p-10 pt-2 text-sm shadow-sm lg:shadow-none rounded-b-sm">
          <div className="lg:hidden">
            <button
              className={`w-full text-center flex justify-center items-center transition-colors duration-300 font-semibold ${
                isDropdownOpen ? 'bg-gray-100 ' : 'bg-transparent py-2'
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {!isDropdownOpen && (
                <>
                  <span>
                    {
                      verticalTabs.find((tab) => tab.identifier === currentTab)
                        ?.name
                    }
                  </span>
                  {isDropdownOpen ? (
                    <ChevronUpIcon className="h-5 w-5 text-placeholderTextColor" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-secondary ml-2" />
                  )}
                </>
              )}
            </button>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden mt-2 ${
                isDropdownOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
              }`}
            >
              {verticalTabs
                .filter((e) =>
                  e.accessPolicy.includes(
                    isNewComer ? AccountType.Newcomer : AccountType.Friend
                  )
                )
                .map((tab, index) => (
                  <div
                    key={index}
                    className="flex lg:gap-3 mb-[20px] cursor-pointer justify-center items-center"
                    onClick={() => handleTabChange(tab.identifier)}
                  >
                    <span className="text-placeholderTextColor">
                      {tab.icon}
                    </span>
                    <span
                      className={`${
                        currentTab === tab.identifier
                          ? 'text-secondary font-semibold'
                          : 'text-placeholderTextColor'
                      }`}
                    >
                      {tab.name}
                    </span>
                  </div>
                ))}
              {isDropdownOpen && (
                <div className="flex justify-end pt-2 pr-2 sm:pr-5 pb-2 sm:pb-5">
                  <ChevronUpIcon
                    className="h-5 w-5 text-secondary cursor-pointer"
                    onClick={() => setIsDropdownOpen(false)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="hidden lg:block">
            {verticalTabs
              .filter((e) =>
                e.accessPolicy.includes(
                  isNewComer ? AccountType.Newcomer : AccountType.Friend
                )
              )
              .map((tab, index) => (
                <div key={index} className="flex gap-3 mb-[20px]">
                  <span className="text-placeholderTextColor">{tab.icon}</span>
                  <span
                    className={`${
                      currentTab === tab.identifier
                        ? 'text-secondary font-semibold'
                        : 'text-placeholderTextColor'
                    } cursor-pointer`}
                    onClick={() => handleTabChange(tab.identifier)}
                  >
                    {tab.name}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div
          className={`lg:py-10 w-full lg:w-9/12 ${
            currentTab === TabIdentifier.ScheduledCalls
              ? 'bg-transparent px-0'
              : 'bg-white px-0'
          }`}
        >
          {RenderViewWithCurrentTab(currentTab, isNewComer)}
        </div>
      </div>
    </div>
  );
};

export default Profile;
