import React from 'react';
import RatingStars from './RatingProfile';
import { ratings } from './dummy';
import RectangleWithDot from './UserStatusIndicator';
import Avatar from 'components/common/Avatar';
import { useNavigate } from 'react-router-dom';

type UserProfileProps = {
  username: string;
  userId?: string;
  profilePictureUrl: string;
};

const UserProfileCard: React.FC<UserProfileProps> = ({
  username,
  userId,
  profilePictureUrl,
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/friends/${userId}`);
  };

  return (
    <div
      className="bg-white p-4 rounded-md shadow-md flex flex-col items-center w-full lg:max-w-[280px] min-w-[240px] cursor-pointer"
      onClick={handleNavigate}
    >
      <h2 className="text-sm font-bold mb-4 text-center">{username}</h2>
      <div className="flex items-center justify-center mb-4 rounded-full">
        <Avatar
          name={username}
          initialImageUrl={profilePictureUrl}
          size="w-12 h-12"
          borderStyle="border-2 border-avatarBorderColor"
          charBorderStyles="border-2 border-avatarBorderColor"
        />
      </div>
      <RectangleWithDot online={true} />
      <div className="mt-2">
        <RatingStars ratings={ratings} />
      </div>
    </div>
  );
};

export default UserProfileCard;
