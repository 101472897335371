import React from 'react';
import { useNavigate } from 'react-router-dom';

type BlogArticleCardProps = {
  id: string;
  image: string;
  categories: string[];
  date: string;
  title: string;
  description: string;
  onSeeMore: () => void;
};

const SingleBlogCard: React.FC<BlogArticleCardProps> = ({
  id,
  image,
  categories,
  date,
  title,
  description,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/${id}`);
  };

  return (
    <div
      className="relative w-full max-w-full overflow-hidden shadow-lg"
      onClick={handleClick}
    >
      <img className="w-full h-[600px] object-cover" src={image} alt={title} />
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white hover:cursor-pointer">
        <div className=" flex flex-col justify-center items-center text-center px-6 py-4 flex-wrap">
          <div className="flex justify-center mb-6">
            {categories.map((category, index) => (
              <div
                key={index}
                className="bg-overlay text-white text-[10px] rounded-full px-3 py-1 uppercase w-min"
              >
                {category}
              </div>
            ))}
          </div>

          <div className="text-2xl font-bold mb-4">{title}</div>
          <div className="text-xs mb-4">{description}</div>
          <div className="w-14 border-t-2 border-white sm:mt-4 mb-4" />
          <div className="text-xs">{date}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlogCard;
