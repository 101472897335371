import React from 'react';
import Modal from 'components/custom-modal/Modal';
import CustomButton from 'components/common/Button';
import { useNavigate } from 'react-router-dom';

type SignOutModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleLogout: () => void;
};

const SignOutModal: React.FC<SignOutModalProps> = ({
  isOpen,
  onClose,
  handleLogout,
}) => {
  const navigate = useNavigate();
  const onLogout = () => {
    handleLogout();
    navigate('/', { replace: true });
    window.location.reload();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalStyles="md:max-w-lg rounded-2xl"
      fullScreenStyles="p-5 min-h-screen h-screen "
    >
      <Modal.Header
        title=""
        className="bg-white text-secondary rounded-2xl pb-0 "
        iconStyles="text-secondary hover:text-secondary"
      />
      <Modal.Content>
        <div className=" px-4 md:px-11">
          <div className="flex flex-col items-center ">
            <h2 className="text-secondary text-lg font-medium lg:text-2xl mb-4">
              Already Signed Up
            </h2>
            <div className="flex justify-center mb-6"></div>
            <span className="text-base text-center mb-3">
              You have already Signed up as a newcomer. To create new account,
              you need to Sign out.
            </span>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className="px-4 md:px-11 border-t-0 block">
        <span className="text-base font-semibold text-center text-secondary">
          Do you need to Sign out?
        </span>
        <div className="justify-between flex flex-col md:flex-row w-full mt-4">
          <CustomButton
            label="No, Go back"
            variant="primary-transparent"
            onClick={onClose}
            customStyle="!mb-3 !md:mb-0 min-w-[200px]"
          />
          <CustomButton
            label="Yes, Signout"
            variant="primary"
            onClick={onLogout}
            customStyle="min-w-[200px]"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SignOutModal;
