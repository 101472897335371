import React from 'react';

type TimePillsProps = {
  selectedDate: Date;
  availableTimes: string[];
  selectedTime: string;
  onTimeChange: (time: string) => void;
};

const TimePills: React.FC<TimePillsProps> = ({
  selectedDate,
  availableTimes,
  selectedTime,
  onTimeChange,
}) => {
  return (
    <div>
      <div className="w-full flex flex-col items-center">
        <h2 className="text-sm font-semibold h-8 flex items-center text-secondary">
          Available Times for {selectedDate.toDateString()}
        </h2>
      </div>
      <div className="my-4 border-b border-b-grayBorderColor"></div>
      <div className="flex justify-center xl:justify-between flex-wrap gap-2 overflow-x-auto max-h-[235px]">
        {availableTimes.map((time) => (
          <button
            key={time}
            className={`p-2 rounded-full cursor-pointer font-medium text-[10px] md:text-xs  w-32 sm:w-36  h-8 lg:w-40 xl:w-[170px]  ${
              time === selectedTime
                ? 'bg-categoryActive text-white'
                : 'bg-categoryInactive hover:bg-blue-200 text-categoryActive'
            }`}
            onClick={() => onTimeChange(time)}
          >
            {time}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TimePills;
