import React, { useEffect, useState } from 'react';
import CustomButton from 'components/common/Button';
import CategoryButton from 'components/category/CategoryButton';
import AddDateOverrideModal from 'components/add-data-override/AddDateOverrideModal';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { Availability } from 'model/Availability';
import { formatDateOverride } from 'utils/searchCityDate';

export type DateCategory = {
  date: string;
  category: { timeSlot: string; segmentId: string }[];
  isAvailable: boolean;
  availabilityId: string;
};

const AvailabilityComponent: React.FC<{
  dateCategoryLines: DateCategory[];
  setDateCategoryLines: React.Dispatch<React.SetStateAction<DateCategory[]>>;
  availabilitiesData: Availability[];
}> = ({ dateCategoryLines, setDateCategoryLines, availabilitiesData }) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (availabilitiesData) {
      const today = new Date().setHours(0, 0, 0, 0);
      const lines = availabilitiesData
        .filter((queryResult: Availability) => {
          if (!queryResult.date) return false;
          const queryDate = new Date(queryResult.date).setHours(0, 0, 0, 0);
          return queryDate >= today;
        })
        .map((queryResult: Availability) => ({
          date:
            queryResult instanceof Date
              ? formatDateOverride(new Date(queryResult?.date))
              : formatDateOverride(new Date(queryResult.date!)),
          category: queryResult.segments.map((segment: any, index: number) => ({
            timeSlot: `${segment?.startTime} - ${segment.endTime}`,
            segmentId: `${index}${segment?.startTime} - ${segment.endTime}`,
          })),
          isAvailable: queryResult.available,
          availabilityId: queryResult.id,
        }));
      setDateCategoryLines(lines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitiesData]);

  const handleAddDateOverrideClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setTimeout(() => {
      setModalVisible(false);
    }, 800);
  };

  return (
    <>
      <div className="mb-8">
        <div
          className={` border border-textFieldBlue rounded-lg  bg-white flex flex-col ${
            dateCategoryLines?.length > 0 ? 'border-b' : ''
          }`}
        >
          <div className="flex flex-col md:flex-row md:justify-between md:items-center p-4 md:p-8">
            <span className=" text-sm mb-6 md:mb-0">
              Add dates when your availability changes from your weekly hours
            </span>
            <CustomButton
              label={
                <>
                  <PlusIcon className={`w-6 h-6 pr-2`} /> Add a Date Override
                </>
              }
              variant="primary"
              customStyle="bg-textFieldBlue hover:bg-textFieldBlue text-sm  font-medium min-w-56 w-56"
              onClick={handleAddDateOverrideClick}
            />
          </div>
          {dateCategoryLines?.length > 0 && (
            <div className="border-t border-textFieldBlue mb-2"></div>
          )}
          {dateCategoryLines?.map((line, lineIndex) => (
            <DateCategoryLine
              key={lineIndex}
              date={line.date}
              category={line.category}
              isAvailable={line.isAvailable}
              onDelete={() => {}}
              onDeleteSegment={() => {}}
            />
          ))}
        </div>
      </div>
      {modalVisible && (
        <AddDateOverrideModal
          visible={modalVisible}
          onHide={handleModalClose}
          dateCategoryLines={dateCategoryLines}
        />
      )}
    </>
  );
};

type DateCategoryLineProps = {
  date: string;
  category: { timeSlot: string; segmentId: string }[];
  isAvailable: boolean;
  onDelete: () => void;
  onDeleteSegment: (segmentId: string, segmentIndex: number) => void;
};

const DateCategoryLine: React.FC<DateCategoryLineProps> = ({
  date,
  category,
  isAvailable,
  onDelete,
  onDeleteSegment,
}) => {
  return (
    <div className="flex items-center w-full flex-wrap  my-2 border-b pb-4 lg:justify-between border-textFieldBlue last:border-b-transparent px-4 md:px-8 gap-4">
      <div className="text-sm">{date}</div>
      <div className="flex justify-between items-end w-full lg:w-auto flex-grow">
        <div className="flex flex-wrap gap-4">
          {isAvailable ? (
            category.map((timeSlot, segmentIndex) => (
              <CategoryButton
                key={segmentIndex}
                label={timeSlot.timeSlot}
                isActive={false}
                customStyle="mr-2 text-xs font-medium !text-black pr-4 !pl-1 min-w-[160px]"
                onIconClick={() =>
                  onDeleteSegment(timeSlot.segmentId, segmentIndex)
                }
              />
            ))
          ) : (
            <CategoryButton
              label="Unavailable"
              isActive={false}
              customStyle="mr-2 text-xs font-medium !text-black !px-4 min-w-[160px]"
              onIconClick={onDelete}
            />
          )}
        </div>

        <div className="flex w-10 h-10 justify-center items-center  border-[1px] border-textFieldBlue p-2 rounded-full cursor-pointer">
          <TrashIcon
            onClick={onDelete}
            className={`w-5 h-5 text-textFieldBlue`}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailabilityComponent;
