import React, { useState } from 'react';
import CustomInput from 'components/common/Input';
import CustomDropdown from 'components/common/Dropdown';
import ImageUpload from 'components/common/ImageUpload';
import CustomButton from 'components/common/Button';
import TextEditor from 'components/admin/TextEditor';
import dayjs from 'dayjs';

const NewArticle: React.FC = () => {
  const [title, setTitle] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [image, setImage] = useState<string>(''); // Add state for image

  const handleCategoryChange = (value: string) => {
    setCategory(value);
  };

  const handleImageChange = (imageUrl: string) => {
    setImage(imageUrl); // Update state with the uploaded image URL
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ title, slug, category, content, image });
  };

  const handlePreview = () => {
    const previewData = {
      title,
      category,
      content,
      image,
      date: dayjs().format('DD-MM-YYYY'),
    };

    // Store data in sessionStorage
    sessionStorage.setItem('previewData', JSON.stringify(previewData));

    // Open the preview page in a new window
    window.open('/preview', '_blank');
  };

  const categoryOptions = [
    { label: 'Education', value: 'education' },
    { label: 'Accommodation', value: 'accommodation' },
    { label: 'Other', value: 'other' },
    // Add more options
  ];

  return (
    <div className="max-w-7xl mx-auto px-6 lg:px-8 min-h-screen mt-10">
      <h1 className="text-2xl font-bold mb-4">New Article</h1>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-8/12 md:pr-8">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <CustomInput
                label="Title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <CustomInput
                label="Slug"
                name="slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Content</label>
              <TextEditor
                value={content}
                onChange={(value) => setContent(value)}
                placeholder="Write your content here..."
              />
            </div>
          </form>
        </div>
        <div className="md:w-4/12">
          <div className="mb-4">
            <CustomDropdown
              label="Category"
              name="category"
              options={categoryOptions}
              value={category}
              placeholder="Select a category"
              onChange={handleCategoryChange}
              customPlaceHolder="text-categoryActive bg-white"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="image" className="text-primaryTextColor text-sm">
              Featured Image*
            </label>
            <ImageUpload onChange={handleImageChange} />
          </div>
          <div className="mb-4">
            <CustomButton
              label="Preview"
              variant="primary-transparent"
              customStyle="w-full md:text-sm"
              onClick={handlePreview}
            />
          </div>
          <div className="flex justify-between items-center gap-x-4">
            <CustomButton
              label="Save Draft"
              variant="primary"
              customStyle="w-full md:text-sm bg-secondary hover:bg-secondary"
              onClick={handleSubmit}
            />
            <CustomButton
              label="Publish"
              variant="primary"
              customStyle="w-full md:text-sm"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewArticle;
