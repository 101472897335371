import React, { useRef, useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

type DateCarouselProps = {
  size?: string;
  className?: string;
};

const DateCarousel: React.FC<DateCarouselProps> = ({
  size = 'w-20 h-20',
  className = '',
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(30);
  const [isAtStart, setIsAtStart] = useState<boolean>(true);
  const [isAtEnd, setIsAtEnd] = useState<boolean>(false);

  const currentDate = dayjs();
  const pastDaysToShow = 30;
  const futureMonthsToShow = 3;
  const futureDaysToShow = futureMonthsToShow * 30;

  const pastDates = Array.from({ length: pastDaysToShow }, (_, i) =>
    currentDate.subtract(i + 1, 'day')
  ).reverse();

  const futureDates = Array.from({ length: futureDaysToShow }, (_, i) =>
    currentDate.add(i + 1, 'day')
  );

  const dates = [...pastDates, currentDate, ...futureDates];

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const element = scrollContainer.children[pastDaysToShow] as HTMLElement;
      if (element) {
        scrollContainer.scrollTo({
          left: element.offsetLeft,
          behavior: 'smooth',
        });
      }
    }
  }, []);

  return (
    <div className={`relative w-full max-w-3xl ${className}`}>
      <div className="text-left mb-2 text-sm font-medium xl:ml-[-60px] text-secondary">
        {currentDate.format('MMMM, YYYY')}
      </div>
      {!isAtStart && (
        <div
          className="absolute -left-4 lg:-left-4 xl:-left-10 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-50"
          onClick={scrollLeft}
        >
          <ChevronLeftIcon className="h-5 w-5 text-primary" />
        </div>
      )}
      {!isAtEnd && (
        <div
          className="absolute -right-4  md:-right-6 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-50"
          onClick={scrollRight}
        >
          <ChevronRightIcon className="h-5 w-5 text-primary" />
        </div>
      )}
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto space-x-4 scrollbar-hide py-10 pt-2"
      >
        {dates.map((date, index) => {
          const isToday = date.isSame(currentDate, 'day');
          const dayLabel = isToday ? 'Today' : date.format('ddd');
          const dayNumber = date.format('D');

          return (
            <div
              key={index}
              className={`flex-shrink-0 ${size} ml-2 mt-1 flex flex-col items-center cursor-pointer`}
              onClick={() => setSelectedIndex(index)}
            >
              <div
                className={`flex flex-col items-center justify-center h-24 w-16 rounded-lg border ${
                  index === selectedIndex
                    ? 'border-transparent bg-categoryActive text-white font-semibold shadow-md'
                    : 'border-transparent bg-white text-secondary font-medium shadow-none'
                }`}
              >
                <span className="text-sm pb-1">{dayLabel}</span>
                <span className="text-sm">{dayNumber}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DateCarousel;
