import React from 'react';
import Button from 'components/common/Button';
import Avatar from 'components/common/Avatar';
import SocialInteraction from 'components/question-panel/SocialInteraction';

type AnswerPanelProps = {
  userName: string;
  date: string;
  content: string;
  avatarUrl?: string;
  isTopAnswer?: boolean;
  showScheduleCallButton?: boolean;
  useBackgroundColor?: boolean;
  isAnswerByUser?: boolean;
  status?: string;
  questionId: string;
  handleSendClick?: (comment: string, questionId: string) => void;
};

const AnswerPanel: React.FC<AnswerPanelProps> = ({
  userName,
  date,
  content,
  isTopAnswer = false,
  showScheduleCallButton = false,
  useBackgroundColor = false,
  isAnswerByUser,
  status,
  questionId,
  handleSendClick,
}) => {
  //TODO: Remove dummy data with API Integration
  const user = userName || 'username';
  const image =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXSTblEVkkdJh15jlAbC3FpvuzCKb1o-pQQA&s';
  const _id = 1;

  const variantStyles = (() => {
    switch (status) {
      case 'REJECTED':
        return 'text-avatarPinkBorder';
      case 'PENDING':
        return 'text-pendingStatusColor';
    }
  })();

  const displayStatus = (() => {
    switch (status) {
      case 'REJECTED':
        return 'Rejected';
      case 'PENDING':
        return 'Pending';
      default:
        return status;
    }
  })();

  return (
    <div className="w-full mx-auto rounded-t-lg border-b border-borderline">
      <div className="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 mb-4 px-14 my-4">
        <Avatar
          name={user || ''}
          initialImageUrl={image}
          size="w-20 h-20"
          borderStyle="border-2 border-imageBorderColor"
          charBorderStyles="border-2 border-imageBorderColor"
        />
        <div className=" flex flex-col !ml-8">
          {isTopAnswer && (
            <span className="text-primary font-bold">Top Answer</span>
          )}
          <div className="flex justify-between">
            <div className="flex justify-start items-center">
              <a href={`/friends/${_id}`}>
                <span className="font-medium ml-0 text-xs">{user}</span>
              </a>
              <p className="ml-4 text-xs sm:text-xs text-secondary">{date}</p>
            </div>

            {isAnswerByUser && status !== 'APPROVED' && (
              <div
                className={`bg-homeItemBgColor p-2 !capitalize text-xs ${variantStyles}`}
              >
                {displayStatus}
              </div>
            )}
          </div>
          <div
            className={`${
              useBackgroundColor ? 'bg-homeItemBgColor' : ''
            } rounded-md text-gray-800 break-words mt-2 text-xs p-3 w-fit`}
          >
            {content}
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 mt-3">
            <SocialInteraction
              showThumbsUp
              showThumbsDown
              questionId={questionId}
              handleSendClick={handleSendClick}
            />
            {showScheduleCallButton && (
              <Button
                label="Schedule a Call"
                variant="primary"
                customStyle="w-full sm:!w-[150px] !h-[30px]"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerPanel;
