import apiClient from 'api/apiClient';
import { User } from 'model/User';

export const getUsersByRoleAndCityId = async (
  role: string,
  cityId: string
): Promise<User[]> => {
  const response = await apiClient.get('/v1/users', {
    params: { role, cityId },
  });
  return response.data;
};
