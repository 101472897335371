import React from 'react';
import QuestionFriend from 'components/question-panel/QuestionFriend';
import AnswerPanelFriend from 'components/answer-panel/AnswerPanelFriend';
import { formatDate } from 'utils/searchCityDate';

type QuestionPanelFriendProps = {
  userId: string | undefined;
  date: string;
  content: string;
  description: string | undefined;
  answers: any[]; //TODO: add correct type after api integration
  questionId: string;
  signInUserId?: string;
};

const QuestionPanelFriend: React.FC<QuestionPanelFriendProps> = ({
  userId,
  date,
  content,
  description,
  answers,
  questionId,
  signInUserId,
}) => {
  return (
    <div className="bg-white mb-4 rounded-sm pb-4">
      <QuestionFriend
        userId={userId}
        date={date}
        content={content}
        description={description}
        questionId={questionId}
        signInUserId={signInUserId}
        useBackgroundColor={true}
      />
      {answers.map((answer, index) => (
        <AnswerPanelFriend
          key={index}
          userName={answer.createdBy}
          date={formatDate(answer.createdAt)}
          content={answer.title}
          questionId={questionId}
          useBackgroundColor={false}
        />
      ))}
    </div>
  );
};

export default QuestionPanelFriend;
