import { CookiePolicyItem } from 'model/CookiePolicyItem';

enum CookieCategory {
  Functional = 'Functional',
  AnalyticsPerformance = 'Analytics/Performance',
  AdvertisingMarketing = 'Advertising/Marketing',
  Essential = 'Essential',
}

const data = [
  { cookieName: '_RIGHT_PANEL_STATE', category: CookieCategory.Functional },
  { cookieName: '_PROFILE_SAVED', category: CookieCategory.Functional },
  { cookieName: '_PROFILE_EMPTY_FIELDS', category: CookieCategory.Functional },
  {
    cookieName: '_PROFILE_IS_EMPTY_FIELDS',
    category: CookieCategory.Functional,
  },
  { cookieName: '_PREV_LOCATION', category: CookieCategory.Functional },
  {
    cookieName: '_SELECTED_SCHEDULE_CALL',
    category: CookieCategory.Functional,
  },
  { cookieName: '_PAGINATION_COUNT', category: CookieCategory.Functional },
  {
    cookieName: '_VISITOR_DETAILS',
    category: CookieCategory['AnalyticsPerformance'],
  },
  { cookieName: '_SEARCH_HISTORY', category: CookieCategory.Functional },
  { cookieName: '_REMEMBER_ME', category: CookieCategory.Functional },
  { cookieName: '_SCHEDULE_LOCATION', category: CookieCategory.Functional },
  { cookieName: '_REQUEST_RESET', category: CookieCategory.Functional },
  {
    cookieName: '_ACCEPT_COOKIES',
    category: CookieCategory['AdvertisingMarketing'],
  },
  { cookieName: '_ACTION_CREATE_PROFILE', category: CookieCategory.Functional },
  { cookieName: '_ACTION_SOCIAL_LOGIN', category: CookieCategory.Functional },
  { cookieName: '_ACTION_HOME', category: CookieCategory.Functional },
  { cookieName: '_ACTION_BECOME_FRIEND', category: CookieCategory.Functional },
  { cookieName: '_ACTION_NOTIFICATION', category: CookieCategory.Functional },
  { cookieName: '_ACTION_SEARCHBAR', category: CookieCategory.Functional },
  { cookieName: '_ACTION_SCHEDULED_CALL', category: CookieCategory.Functional },
  { cookieName: '_ACTION_AVAILABILITY', category: CookieCategory.Functional },
  { cookieName: '_ACTION_MYPROFILE', category: CookieCategory.Functional },
  { cookieName: '_ACTION_LOGOUT', category: CookieCategory.Functional },
  { cookieName: '_TIMEZONE', category: CookieCategory.Essential },
  {
    cookieName: '_USER_IDENTIFIER',
    category: CookieCategory['AdvertisingMarketing'],
  },
  {
    cookieName: '_CURRENT_LOCATION',
    category: CookieCategory['AnalyticsPerformance'],
  },
];

const cookiePolicyData: CookiePolicyItem[] = [
  {
    title: 'Introduction',
    description: (
      <span>
        <br />
        Welcome to Move2NewCity (&ldquo;us,&rdquo; &ldquo;we,&rdquo; or
        &ldquo;our&rdquo;). At Move2NewCity, we are committed to protecting your
        privacy and providing you with the best possible online experience.
        <br />
        This Cookie Policy explains how we use cookies and similar tracking
        technologies on our website https://www.move2newcity.com/
        (&ldquo;Website&rdquo;) and how you can manage your preferences
        regarding cookies.
        <br />
        <br />
        By using our Website, you consent to the use of cookies in accordance
        with this Cookie Policy. If you do not agree to the use of cookies as
        described in this policy, please disable cookies or refrain from using
        our Website.
      </span>
    ),
  },
  {
    title: 'What are Cookies?',
    description: (
      <span>
        <br />
        Cookies are small text files that are placed on your device (computer,
        tablet, smartphone, etc.) when you visit a website. They are widely used
        to enhance the user experience
        <br />
        and gather information about user behavior on websites.
      </span>
    ),
  },
  {
    title: 'How we use Cookies',
    description: (
      <span>
        <br />
        We use cookies on our Website for various purposes, including:
        <p>
          <b>a. Essential Cookies</b>: These cookies are necessary for the basic
          functioning of our Website and cannot be disabled. They enable core
          functionalities such as security, page navigation, and access to
          secure areas of the Website.
        </p>
        <br />
        <p>
          <b>b. Analytical/Performance Cookies</b>: These cookies help us
          understand how visitors interact with our Website by collecting and
          reporting information anonymously. This
          <br />
          helps us improve our Website&apos;s performance and user experience.
        </p>
        <br />
        <p>
          <b>c. Functional Cookies</b>: These cookies enable enhanced
          functionality and personalization of the Website. They may remember
          your preferences, such as language or location settings, to provide
          you with a more tailored experience.
        </p>
        <br />
        <p>
          <b>d. Advertising/Marketing Cookies</b>: These cookies are used to
          deliver relevant advertisements to you based on your browsing behavior
          and interests. They may also help us measure the effectiveness of our
          advertising campaigns.
        </p>
      </span>
    ),
  },
  {
    title: 'Types of Cookies we use',
    description: (
      <div>
        <br />
        <div className="overflow-x-auto w-auto">
          {' '}
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-3 border">Cookie Name</th>
                <th className="py-2 px-3 border">Category</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.cookieName} className="border">
                  <td className="py-2 px-3 border">{item.cookieName}</td>
                  <td className="py-2 px-3 border">{item.category}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title: 'Managing Cookies',
    description: (
      <span>
        <br />
        You can manage your cookie preferences by adjusting your browser
        settings. Most web browsers allow you to control cookies through their
        settings. You can typically choose
        <br />
        to accept, reject, or delete cookies. Please note that disabling
        essential cookies may impact the functionality of our Website.
      </span>
    ),
  },
  {
    title: 'Changes to This Cookie Policy',
    description: (
      <span>
        <br />
        We may update this Cookie Policy from time to time to reflect changes in
        our practices or for other operational, legal, or regulatory reasons.
        Please revisit this page periodically
        <br />
        to stay informed about how we use cookies.
      </span>
    ),
  },
  {
    title: 'Contact Us ',
    description: (
      <span>
        <br />
        If you have any questions or concerns about this Cookie Policy or our
        data practices, please contact us at:
        <br />
        <br />
        Move2NewCity
        <br />
        148, Greenhill Road,
        <br />
        Parkside SA 5063
        <br />
        Australia
        <br />
        +61 466 308 435
        <br />
        <br />
        We are committed to addressing your inquiries and concerns promptly.
        <br />
        <br />
        By using our Website, you agree to the terms outlined in this Cookie
        Policy. If you do not agree with these terms, please discontinue your
        use of our Website or adjust your cookie preferences accordingly.
      </span>
    ),
  },
];
export default cookiePolicyData;
