import { useMutation } from '@tanstack/react-query';
import { signin, signup } from 'api/requests/auth';
import {
  LoginDetails,
  SignupRequest,
  SignupResponse,
  UserSession,
} from 'model/Auth';

export const useSignIn = () => {
  return useMutation<UserSession, Error, LoginDetails>({
    mutationFn: async (registerData: LoginDetails) => signin(registerData),
  });
};

export const useSignup = () => {
  return useMutation<SignupResponse, Error, SignupRequest>({
    mutationFn: async (registerData: SignupRequest) => signup(registerData),
  });
};
