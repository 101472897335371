import React, { useState, useEffect } from 'react';
import BlogArticleCard from 'components/blog/ArticleCard';
import { blogArticles } from 'components/blog/dummy';
import CustomButton from 'components/common/Button';

const AllPostsPage: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [visibleArticles, setVisibleArticles] = useState<number>(8);
  const [articlesToShow, setArticlesToShow] = useState<any[]>([]);
  const [showArticles, setShowArticles] = useState<boolean>(false);

  const categories = Array.from(
    new Set(blogArticles.flatMap((article) => article.categories))
  );

  const filteredArticles = React.useMemo(() => {
    return selectedCategory === 'All'
      ? blogArticles
      : blogArticles.filter((article) =>
          article.categories.includes(selectedCategory)
        );
  }, [selectedCategory]);

  const handleLoadMore = () => {
    setVisibleArticles((prevVisibleArticles) => prevVisibleArticles + 8);
  };

  useEffect(() => {
    setArticlesToShow(filteredArticles.slice(0, visibleArticles));
    setShowArticles(false);

    const timer = setTimeout(() => {
      setShowArticles(true);
    }, 100);

    return () => clearTimeout(timer);
  }, [filteredArticles, visibleArticles]);

  const shouldShowLoadMore = visibleArticles < filteredArticles.length;

  return (
    <div className="bg-blogBg h-full">
      <div className="max-w-7xl container mx-auto px-6 lg:px-4 xl:px-0 pt-12 md:pt-20">
        <div>
          <h2 className="lg:text-3xl font-medium text-secondary mb-2">
            All Blog Entries
          </h2>
          <hr className="w-14 border-t-4 border-primary sm:mt-4 sm:mb-12 mt-2 mb-6" />
        </div>
        <div className="flex justify-between items-center mb-2">
          <div className="flex flex-wrap">
            <button
              className={`mr-4 text-xs ${
                selectedCategory === 'All'
                  ? 'text-primary font-semibold'
                  : 'text-secondary'
              }`}
              onClick={() => setSelectedCategory('All')}
            >
              All
            </button>
            {categories.map((category) => (
              <button
                key={category}
                className={`mr-4 text-xs ${
                  selectedCategory === category
                    ? 'text-primary font-semibold'
                    : 'text-secondary'
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        <div className="flex flex-wrap gap-x-7 gap-y-10 py-9 pt-6">
          {articlesToShow.map((article) => (
            <div
              key={article.id}
              className={`transition-opacity duration-500 lg:max-w-[298px] w-full ${
                showArticles ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <BlogArticleCard
                id={article.id}
                image={article.image}
                categories={article.categories}
                date={article.date}
                title={article.title}
                description={article.description}
              />
            </div>
          ))}
        </div>
        {shouldShowLoadMore && (
          <div className="flex justify-center pt-12 pb-24">
            <CustomButton
              onClick={handleLoadMore}
              label="Load More"
              variant="primary-transparent"
              customStyle="w-full mt-7 !rounded-full max-w-[175px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllPostsPage;
