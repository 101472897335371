import apiClient from 'api/apiClient';
import {
  AvailabilityRequest,
  AvailabilityResponse,
  CreateAvailabilitiesRequest,
} from 'api/mutations/availability/types';
import { DaysOfWeek } from 'constants/Common';
import { Availability } from 'model/Availability';
import { formatTime } from 'utils/availabilityUtils';

export const getUserAvailability = async (
  userId: string
): Promise<Availability[]> => {
  const response = await apiClient.get(`v1/resources/${userId}/availability`);
  const generatedResponse = response?.data?.map((element: Availability) => {
    return {
      ...element,
      dayOfWeek: DaysOfWeek[element.dayOfWeek],
      segments: element.segments.map((segment: any) => {
        return {
          startTime: formatTime(segment.startTime),
          endTime: formatTime(segment.endTime),
        };
      }),
    };
  });
  return generatedResponse;
};
export const addNewAvailability = async (
  availabilityRequest: CreateAvailabilitiesRequest,
  availabilityId: string,
  userId: string
): Promise<AvailabilityResponse> => {
  try {
    return await apiClient.put(
      `v1/resources/${userId}/availability/${availabilityId}`,
      availabilityRequest
    );
  } catch (error) {
    throw new Error(`Error adding new availability segment: ${error}`);
  }
};

export const overrideAvailability = async (
  availabilityRequest: AvailabilityRequest,
  userId: string
): Promise<AvailabilityResponse> => {
  try {
    return await apiClient.put(
      `v1/resources/${userId}/availability`,
      availabilityRequest
    );
  } catch (error) {
    throw new Error(`Error override availability segment: ${error}`);
  }
};
