import React from 'react';
import SeePost from 'assets/images/home/see-posts.svg';
import FriendSelect from 'assets/images/home/friend-select.svg';
import ScheduleCall from 'assets/images/home/schedule-call.svg';
import CustomButton from 'components/common/Button';
import { useNavigate } from 'react-router-dom';

type NewComerFeature = {
  id: number;
  title: string;
  icon: string;
};

const newcomerFeatureData: NewComerFeature[] = [
  {
    id: 1,
    title: 'Select a friend you prefer',
    icon: FriendSelect,
  },
  {
    id: 2,
    title: 'Schedule calls with them',
    icon: ScheduleCall,
  },
  {
    id: 3,
    title: 'See their posts in expertise areas',
    icon: SeePost,
  },
];

const NewcomerFeatureItem: React.FC<NewComerFeature> = ({ title, icon }) => {
  return (
    <div className="flex flex-col flex-wrap items-center">
      <div className="flex justify-center items-center bg-homeItemBgColor border border-homeItemBorder w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 lg:w-20 lg:h-20 xl:w-28 xl:h-28 rounded-lg">
        <img className="w-10 h-auto" src={icon} alt={`icon-${title}`} />
      </div>
      <div className="text-white text-[10px] md:text-sm text-center mt-2">
        {title}
      </div>
    </div>
  );
};

const WhoIsFriend: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-secondary py-12 px-5 md:px-9 gap-4 col-span-5 w-full lg:w-5/12 rounded-md">
      <h3 className="text-sm font-medium md:text-[28px] md:font-normal text-white">
        Who’s a Friend?
      </h3>
      <hr className="w-12 md:w-14 border-t-2 md:border-t-4 border-primary mb-4 sm:mt-4 sm:mb-12 lg:mb-11" />
      <p className="text-white text-[10px] md:text-sm mt-4">
        A <b>FRIEND</b> is a person who lives in a city and has various lived
        experiences in that city. This can include knowledge, information, and
        know-how on different areas such as accommodation, education, and
        transportation. A <b>FRIEND</b> is someone who is willing to share this
        knowledge with a <b>NEWCOMER</b> to help them settle in.
      </p>
      <p className="text-white text-xs md:text-sm mt-8">
        As a ‘Newcomer’ you can,
      </p>
      <div className="grid grid-cols-3 gap-x-9 md:gap-x-16  gap-y-9 md:gap-y-11 pt-3">
        {newcomerFeatureData.map((feature, index) => (
          <NewcomerFeatureItem
            id={feature.id}
            title={feature.title}
            icon={feature.icon}
            key={index}
          />
        ))}
      </div>
      <div className="mt-5">
        <p className="text-white font-medium text-xs md:text-sm mt-8">
          Become a friend
        </p>
        <div className="mt-3">
          <div className="bg-[url('assets/images/home/become-friend.png')] flex w-full h-28 sm:h-36 bg-cover bg-center rounded-md">
            <p className="text-white font-normal flex items-center float-right justify-end w-1/2"></p>
            <p className="text-white font-normal flex items-center float-right justify-end w-1/2 text-xs md:text-sm ">
              Earn rewards by sharing your knowledge about your home city
            </p>
          </div>
        </div>
        <CustomButton
          label="Become a Friend"
          variant="primary"
          customStyle="w-full mt-7"
          onClick={() => {
            navigate(`/become-a-friend`);
          }}
        />
      </div>
    </div>
  );
};

export default WhoIsFriend;
