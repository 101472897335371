import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserStatusIndicator from 'components/rating-profile/UserStatusIndicator';
import Button from 'components/common/Button';
import RatingStars, {
  RatingItem,
} from 'components/rating-profile/RatingProfile';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/24/outline';
import SignIn from 'components/auth/Signin';
import FriendScheduleNotifiacation from './FriendScheduleNotifiaction';
import { useUser } from 'api/context/UserContext';
import { UserRole } from 'constants/User';
// import { useGetUserByUserId } from 'api/queries/users';

type FriendProfileProps = {
  userId: string;
  username: string;
  profilePictureUrl: string;
  city: string;
  yearsInCity: string;
  online: boolean;
  payment: string;
  aboutMe: string;
  ratings: RatingItem[];
  showScheduleButton?: boolean;
};

const FriendProfileInfo: React.FC<FriendProfileProps> = ({
  userId,
  username,
  profilePictureUrl,
  city,
  online,
  ratings,
  aboutMe,
  yearsInCity,
  showScheduleButton = true,
}) => {
  const User = useUser();
  const isLoggedIn = !!User?.user?.id;
  const isFriend = User?.user?.role === UserRole.FRIEND;
  const params = useParams() || '';
  // const { data: user = [] } = useGetUserByUserId(params.friendId || '');

  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [
    isFriendScheduleNotifiacationOpen,
    setFriendScheduleNotifiacationOpen,
  ] = useState<boolean>(false);
  const [attemptedSchedule, setAttemptedSchedule] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn && isFriend && attemptedSchedule) {
      setFriendScheduleNotifiacationOpen(true);
      setAttemptedSchedule(false);
    }
  }, [isLoggedIn, isFriend, attemptedSchedule]);

  const handleOpenScheduleCalendar = () => {
    if (!isLoggedIn) {
      setAttemptedSchedule(true);
      setIsSignInModalOpen(true);
    } else if (isFriend) {
      setFriendScheduleNotifiacationOpen(true);
    } else {
      console.log('User is logged in, schedule a call');
    }
  };
  return (
    <>
      <SignIn
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <FriendScheduleNotifiacation
        isOpen={isFriendScheduleNotifiacationOpen}
        onClose={() => setFriendScheduleNotifiacationOpen(false)}
      />

      <div className="bg-mainBgColor text-secondary xs:w-full">
        <div className="bg-white p-4 mb-4 flex flex-col items-center justify-center rounded-lg shadow-lg text-center sm:text-left">
          <div className="text-base text-black font-medium mb-4">
            {username}
          </div>
          <div className="mb-4">
            <Avatar
              name={username}
              initialImageUrl={''}
              size="w-24 h-24"
              borderStyle="border-2 border-avatarBorderColor"
              charBorderStyles="border-2 border-avatarBorderColor"
            />
          </div>
          <div className="text-xs font-medium mb-2">{city}</div>
          <div className="text-xs font-medium mb-2">
            Time Spent - {yearsInCity} Years
          </div>
          <div className="text-xs font-medium mb-4">
            <UserStatusIndicator online={online} />
          </div>
        </div>
        {showScheduleButton &&
          (isLoggedIn ? (
            <Link to={`/friends/${params.friendId}/schedule`}>
              <Button
                icon={<CalendarIcon className="h-5 w-5 mr-1" />}
                label={<span className="text-xs ">Schedule a Call</span>}
                variant="primary"
                customStyle="w-full h-10  mb-4 flex justify-center"
              />
            </Link>
          ) : (
            <Button
              label="Schedule a Call"
              variant="primary"
              customStyle="w-full h-8 text-xs mb-4"
              onClick={handleOpenScheduleCalendar}
            />
          ))}
        <div className="">
          <div className="text-xs font-semibold mb-2 mt-6 text-center sm:text-left">
            Description
          </div>
          <div className="bg-white text-black text-xs h-fit p-4 mb-4 flex flex-col items-center justify-center rounded-lg shadow-lg  text-center sm:text-left">
            {aboutMe}
          </div>
          <div className="text-xs font-semibold mb-2 mt-6 text-center sm:text-left">
            Contributing in :
          </div>
          <div className="bg-white p-4 mb-4 flex flex-col items-center justify-center rounded-lg shadow-lg text-xs">
            <RatingStars ratings={ratings} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendProfileInfo;
