export const formatDate = (createdAt: Date | string | undefined) => {
  if (createdAt) {
    const date = new Date(createdAt);
    const day = date.toLocaleDateString('en-US', { day: 'numeric' });
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    const year = date.toLocaleDateString('en-US', { year: 'numeric' });

    return `${day} ${month}, ${year}`;
  } else {
    return '';
  }
};

export const formatDateOverride = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
