import React from 'react';
import { ChevronDoubleUpIcon } from '@heroicons/react/24/solid';

const ScrollToTop: React.FC = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      onClick={handleScrollToTop}
      className=" p-3  text-secondary text-xs font-medium hover:font-semibold transition duration-300 flex"
    >
      Go to Top
      <ChevronDoubleUpIcon className="h-54 w-4 ml-2 hover:font-semibold" />
    </button>
  );
};

export default ScrollToTop;
