import React, { useMemo } from 'react';
import CategoryButton from 'components/category/CategoryButton';
import { Vote } from 'model/Question';
import { InteractionTypes } from 'constants/Interaction';
import SocialInteraction from './SocialInteraction';
import { voteType } from 'model/City';

type QuestionProps = {
  userId: string | undefined;
  date: string;
  content: string;
  description?: string;
  categories?: string[];
  useBackgroundColor?: boolean;
  showSocialIcons?: boolean;
  questionId: string;
  signInUserId?: string;
  voting?: Vote[];
  questionStatus?: string;
  handleVoteClick?: (voteType: voteType) => void;
  handleSendClick?: (comment: string, questionId: string) => void;
  onSelectQuestion?: (questionId: string) => void;
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const Question: React.FC<QuestionProps> = ({
  userId,
  date,
  content,
  description = '',
  categories = [],
  useBackgroundColor = false,
  showSocialIcons = true,
  questionId,
  signInUserId,
  voting,
  questionStatus,
  handleSendClick,
  handleVoteClick,
  onSelectQuestion,
}) => {
  const truncatedDescription = truncateText(description, 100);

  const { likes, dislikes } = useMemo(() => {
    const upVotes =
      voting?.filter(
        (interaction) => interaction.type === InteractionTypes.UP_VOTE
      ) || [];

    const downVotes =
      voting?.filter(
        (interaction) => interaction.type === InteractionTypes.DOWN_VOTE
      ) || [];

    const relevantLikesForQuestion = upVotes.filter(
      (vote) => vote.parentId === questionId
    );

    const relevantUnLikesForQuestion = downVotes.filter(
      (vote) => vote.parentId === questionId
    );

    return {
      likes: relevantLikesForQuestion.length,
      dislikes: relevantUnLikesForQuestion.length,
    };
  }, [voting, questionId]);

  const handleQuestionId = () => {
    onSelectQuestion?.(questionId);
  };

  const variantStyles = (() => {
    switch (questionStatus) {
      case 'REJECTED':
        return 'text-avatarPinkBorder';
      case 'PENDING':
        return 'text-pendingStatusColor';
    }
  })();

  const displayStatus = (() => {
    switch (questionStatus) {
      case 'REJECTED':
        return 'Rejected';
      case 'PENDING':
        return 'Pending';
      default:
        return questionStatus;
    }
  })();

  return (
    <div className="w-full mx-auto">
      <div
        className={`${
          useBackgroundColor ? 'bg-white px-8 pt-8 rounded-sm' : ''
        }`}
      >
        <div className="flex justify-between">
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
            <h3 className="font-medium m-0 text-xs">User</h3>
            <p className="m-0 text-xs text-secondary">{date}</p>
          </div>
          {signInUserId === userId && questionStatus !== 'APPROVED' && (
            <div className={`bg-homeItemBgColor p-2 ${variantStyles}`}>
              {displayStatus}
            </div>
          )}
        </div>
        <div className="text-base font-medium mt-3 leading-loose">
          {content}
        </div>
        {description && <p className="mt-1 text-xs">{truncatedDescription}</p>}
        {categories && categories.length > 0 && (
          <div className="flex flex-wrap space-x-2 mt-3">
            {categories.map((category, index) => (
              <CategoryButton
                key={index}
                label={category}
                isActive={false}
                forQuestionPanel={true}
                onClick={() => {}}
                disabled={false}
                customStyle="rounded-sm text-xs !text-cookieNotificationText !bg-homeItemBgColor"
              />
            ))}
          </div>
        )}
      </div>

      {showSocialIcons && (
        <div className="w-full mx-auto my-4 px-8 py-4 border-t border-b border-borderline">
          <SocialInteraction
            showThumbsUp
            showThumbsDown
            showShare
            likes={likes}
            unLikes={dislikes}
            questionId={questionId}
            onReplyClick={handleQuestionId}
            handleVoteClick={handleVoteClick}
            handleSendClick={handleSendClick}
          />
        </div>
      )}
    </div>
  );
};

export default Question;
