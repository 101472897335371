import React from 'react';
import CustomButton from 'components/common/Button';

type ScheduleButtonsProps = {
  status: string;
  isNewcomer: boolean;
  onReschedule: () => void;
  onCancel?: () => void;
  onJoin?: () => void;
  onConfirm?: () => void; // Add onConfirm
  onDecline?: () => void; // Add onDecline
  appointmentId: string;
  refetchAppointmentsData?: () => void;
};

const ScheduleButtons: React.FC<ScheduleButtonsProps> = ({
  status,
  isNewcomer,
  onReschedule,
  onCancel,
  onJoin,
  onConfirm,
  onDecline,
  appointmentId,
  refetchAppointmentsData,
}) => {
  let buttons;
  switch (status) {
    case 'SUGGESTED':
      if (!isNewcomer) {
        buttons = (
          <CustomButton
            label="Suggest a new time"
            disabled={true}
            variant="primary"
            customStyle="disabled:bg-secondaryDisabled !h-[40px] !px-2 !text-xs bg-secondary hover:bg-secondary w-full"
          />
        );
      } else {
        buttons = (
          <>
            <CustomButton
              label="Confirm"
              onClick={onConfirm}
              variant="primary"
              customStyle="!h-[40px] !px-2 !text-xs"
            />
            <CustomButton
              label="Reschedule"
              onClick={onReschedule}
              variant="primary"
              customStyle="!h-[40px] w-full !px-2 !text-xs"
            />
            {onCancel && (
              <CustomButton
                label="Cancel the call"
                onClick={onCancel}
                variant="primary-transparent"
                customStyle="!h-[40px] !px-2 !text-xs w-full"
              />
            )}
          </>
        );
      }
      break;
    case 'RESCHEDULED':
      if (!isNewcomer) {
        buttons = (
          <>
            <CustomButton
              label="Confirm"
              onClick={onConfirm}
              variant="primary"
              customStyle="!h-[40px] !px-2 w-full !text-xs"
            />
            <CustomButton
              label="Suggest a new time"
              onClick={onReschedule}
              variant="primary"
              customStyle="!h-[40px] !px-2 w-full !text-xs bg-secondary hover:bg-secondary"
            />
          </>
        );
      } else {
        buttons = (
          <>
            <CustomButton
              label="Reschedule"
              disabled={true}
              variant="primary"
              customStyle="!h-[40px] w-full !px-2 !text-xs"
            />
            {onCancel && (
              <CustomButton
                label="Cancel the call"
                onClick={onCancel}
                variant="primary-transparent"
                customStyle="!h-[40px] !px-2 w-full !text-xs"
              />
            )}
          </>
        );
      }
      break;
    case 'CONFIRMED':
      if (!isNewcomer) {
        buttons = (
          <CustomButton
            label="Join"
            onClick={onJoin}
            variant="primary"
            customStyle="!h-[40px] !px-2 w-full !text-xs bg-secondary hover:bg-secondary"
          />
        );
      } else {
        buttons = (
          <>
            <CustomButton
              label="Join"
              onClick={onJoin}
              variant="primary"
              customStyle="!h-[40px] !px-2 w-full !text-xs bg-secondary hover:bg-secondary"
            />
            <CustomButton
              label="Reschedule"
              onClick={onReschedule}
              variant="primary"
              customStyle="!h-[40px] w-full !px-2 !text-xs"
            />
          </>
        );
      }
      break;
    case 'PENDING':
      if (!isNewcomer) {
        buttons = (
          <>
            <CustomButton
              label="Confirm"
              onClick={onConfirm}
              variant="primary"
              customStyle="!h-[40px] !px-2 w-full !text-xs"
            />
            <CustomButton
              label="Decline"
              onClick={onDecline}
              variant="primary-transparent"
              customStyle="!h-[40px] !px-2 w-full !text-xs"
            />
          </>
        );
      } else {
        buttons = (
          <>
            <CustomButton
              label="Reschedule"
              onClick={onReschedule}
              variant="primary"
              customStyle="!h-[40px] !px-2 !text-xs"
            />
            {onCancel && (
              <CustomButton
                label="Cancel the call"
                onClick={onCancel}
                variant="primary-transparent"
                customStyle="!h-[40px] !px-2 w-full !text-xs"
              />
            )}
          </>
        );
      }
      break;
    default:
      break;
  }

  return (
    <div className="flex flex-col space-y-2 self-end justify-self-end w-full md:w-[185px]">
      {buttons}
    </div>
  );
};

export default ScheduleButtons;
