import React from 'react';

type UserStatusIndicatorProps = {
  online: boolean;
};

const UserStatusIndicator: React.FC<UserStatusIndicatorProps> = ({
  online,
}) => {
  const colorClasses = online
    ? { border: 'border-green-500', dot: 'bg-green-500' }
    : { border: 'border-gray-500', dot: 'bg-gray-500' };

  return (
    <div
      className={`rounded-full p-2 flex items-center ${colorClasses.border} h-[15px] w-[70px] border-[0.5px]`}
    >
      <div className="text-black text-xs">{online ? 'Online' : 'Offline'}</div>
      <div
        className={`h-[6px] w-[6px] rounded-full ${colorClasses.dot} ml-1.5`}
      ></div>
    </div>
  );
};

export default UserStatusIndicator;
