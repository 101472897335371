import React from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';

type SortDropdownProps = {
  options: { label: string; value: string }[];
  value?: string;
  onChange?: (value: string) => void;
};

const FriendFilter: React.FC<SortDropdownProps> = ({
  options,
  value,
  onChange,
}) => {
  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative">
        <ListboxButton className="w-full focus:outline-none mt-1">
          <FunnelIcon className="ml-4 w-4 h-4" />
        </ListboxButton>
        <ListboxOptions className="absolute mt-7 !left-8 transform -translate-x-full  bg-dropDownBg shadow-lg max-h-60 rounded-b-sm  py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-10">
          {options.map((option) => (
            <ListboxOption
              key={option.value}
              value={option.value}
              className={({ active }) =>
                `cursor-default select-none relative py-2 pl-4 pr-4 ${
                  active ? 'text-white bg-primary' : 'text-secondary'
                }`
              }
            >
              {({ selected }) => (
                <span
                  className={`block truncate ${
                    selected ? 'font-semibold' : 'font-normal'
                  }`}
                >
                  {option.label}
                </span>
              )}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export default FriendFilter;
