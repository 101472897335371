import React from 'react';
import SocialInteraction from 'components/question-panel/SocialInteraction';

type AnswerPanelFriendProps = {
  userName: string;
  date: string;
  content: string;
  isTopAnswer?: boolean;
  useBackgroundColor?: boolean;
  questionId: string;
  handleSendClick?: (comment: string, questionId: string) => void;
};

const AnswerPanelFriend: React.FC<AnswerPanelFriendProps> = ({
  userName,
  date,
  content,
  isTopAnswer = false,
  useBackgroundColor = false,
  questionId,
  handleSendClick,
}) => {
  return (
    <div
      className={`w-full mx-auto rounded-t-lg border-b border-borderline last:border-none`}
    >
      <div className="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 mb-4 px-8 my-4">
        <div className=" flex flex-col">
          {isTopAnswer && (
            <span className="text-primary font-bold">Top Answer</span>
          )}
          <div className="flex justify-between">
            <div className="flex justify-start items-center">
              <span className="font-medium ml-0 text-xs">{'User'}</span>
              <p className="ml-4 text-xs sm:text-xs text-secondary">{date}</p>
            </div>
          </div>
          <div
            className={`${
              useBackgroundColor ? 'bg-homeItemBgColor' : ''
            } rounded-md text-gray-800 break-words mt-2 text-xs pb-5`}
          >
            {content}
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 mt-3">
            <SocialInteraction
              showThumbsUp
              showThumbsDown
              questionId={questionId}
              handleSendClick={handleSendClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerPanelFriend;
