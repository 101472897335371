import { useQuery } from '@tanstack/react-query';
import { getAllCities } from 'api/mutations/cities';

export const useGetAllCities = () => {
  return useQuery({
    queryKey: ['cities'],
    queryFn: async () => {
      const data = await getAllCities();
      return data;
    },
  });
};
