import Cookies, { CookieSetOptions, CookieGetOptions } from 'universal-cookie';

const cookies = new Cookies();

export const setCookies = (
  name: string,
  value: any,
  options?: CookieSetOptions
) => cookies.set(name, value, options);

export const removeCookies = (name: string, options?: CookieSetOptions) =>
  cookies.remove(name, options);

export const getCookies = (name: string, options?: CookieGetOptions) =>
  cookies.get(name, options);
