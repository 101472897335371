export const blogArticles = [
  {
    id: '1',
    image: 'https://via.placeholder.com/150',
    categories: ['Education', 'Technology'],
    date: '2024-07-30',
    title: 'Latest in Tech',
    description: 'An overview of the latest developments in technology.',
    content: `
        <div>
          <p style="color: blue; font-size: 46px; line-height:48px; margin:0px 0px 30px 0px;">This is the full content of the article, including <strong>HTML elements</strong>.</p>
          <p>Technology is rapidly advancing, and staying up-to-date is crucial for anyone interested in the field. In this article, we explore some of the latest trends and innovations.</p>
          <ul>
            <li><strong>AI and Machine Learning:</strong> These technologies are transforming industries by enabling smarter decision-making and automation.</li>
            <li><strong>Quantum Computing:</strong> A new frontier in computing that promises to solve complex problems much faster than classical computers.</li>
            <li><strong>5G Networks:</strong> The next generation of mobile networks that will significantly enhance connectivity and data transfer speeds.</li>
          </ul>
          <p>Stay tuned for more updates and detailed insights into these exciting technological advancements!</p>
          <p style="color: blue; font-size: 46px; line-height:48px; margin:40px 0px 30px 0px;">This is the full content of the article, including <strong>HTML elements</strong>.</p>
          <p>Technology is rapidly advancing, and staying up-to-date is crucial for anyone interested in the field. In this article, we explore some of the latest trends and innovations.</p>
          <ul>
            <li><strong>AI and Machine Learning:</strong> These technologies are transforming industries by enabling smarter decision-making and automation.</li>
            <li><strong>Quantum Computing:</strong> A new frontier in computing that promises to solve complex problems much faster than classical computers.</li>
            <li><strong>5G Networks:</strong> The next generation of mobile networks that will significantly enhance connectivity and data transfer speeds.</li>
          </ul>
          <p>Stay tuned for more updates and detailed insights into these exciting technological advancements!</p>
        </div>
      `,
  },
  {
    id: '2',
    image: 'https://via.placeholder.com/150',
    categories: ['Education', 'Health'],
    date: '2024-07-29',
    title: 'Health Tips',
    description: 'Daily health tips for a better lifestyle.',
    content: `
        <div>
          <h2>Health Tips for a Better Lifestyle</h2>
          <p>Maintaining good health is essential for a long and fulfilling life. Here are some daily health tips to help you stay fit and healthy:</p>
          <ol>
            <li><strong>Eat a Balanced Diet:</strong> Include a variety of fruits, vegetables, proteins, and whole grains in your diet.</li>
            <li><strong>Stay Hydrated:</strong> Drink plenty of water throughout the day to keep your body hydrated.</li>
            <li><strong>Exercise Regularly:</strong> Engage in physical activity like walking, jogging, or yoga to keep your body active.</li>
            <li><strong>Get Enough Sleep:</strong> Aim for 7-8 hours of quality sleep each night to allow your body to rest and rejuvenate.</li>
            <li><strong>Manage Stress:</strong> Practice relaxation techniques such as meditation or deep breathing to manage stress effectively.</li>
          </ol>
          <p>Incorporating these tips into your daily routine can lead to improved health and well-being. Remember to consult with a healthcare professional for personalized advice.</p>
        </div>
      `,
  },
  {
    id: '3',
    image: 'https://via.placeholder.com/150',
    categories: ['Accommodation', 'Travel'],
    date: '2024-07-28',
    title: 'Travel Guide',
    description: 'Your guide to traveling the world.',
    content: `
        <div>
          <h2>Travel Guide: Exploring the World</h2>
          <p>Traveling opens up new perspectives and experiences. Whether you're a seasoned traveler or a first-time explorer, this guide will help you make the most of your journeys.</p>
          <h3>Planning Your Trip</h3>
          <p>Start by researching your destination, creating an itinerary, and booking accommodations. Consider the best time to visit and any travel advisories.</p>
          <h3>Packing Essentials</h3>
          <p>Pack light and smart. Include essentials like travel documents, a good pair of shoes, and any medications you might need.</p>
          <h3>While Traveling</h3>
          <p>Immerse yourself in the local culture, try new foods, and interact with locals. Always stay aware of your surroundings and keep your belongings secure.</p>
          <h3>Returning Home</h3>
          <p>Reflect on your travel experiences and organize your photos and souvenirs. Share your journey with friends and family.</p>
          <p>Happy travels!</p>
        </div>
      `,
  },
  {
    id: '4',
    image: 'https://via.placeholder.com/150',
    categories: ['Food', 'Other'],
    date: '2024-07-27',
    title: 'Delicious Recipes',
    description: 'Explore delicious recipes from around the world.',
    content: `
        <div>
          <h2>Delicious Recipes to Try</h2>
          <p>Food is a universal language that brings people together. Here are some mouth-watering recipes you can try at home:</p>
          <h3>1. Spaghetti Carbonara</h3>
          <p>A classic Italian pasta dish made with eggs, cheese, pancetta, and pepper. It's creamy, savory, and incredibly satisfying.</p>
          <h3>2. Chicken Tikka Masala</h3>
          <p>A flavorful Indian dish featuring marinated chicken cooked in a spicy tomato cream sauce. Serve it with rice or naan bread.</p>
          <h3>3. Chocolate Lava Cake</h3>
          <p>An indulgent dessert with a gooey chocolate center. Perfect for a special occasion or a sweet treat.</p>
          <p>Enjoy cooking and savoring these delicious dishes!</p>
        </div>
      `,
  },
  {
    id: '5',
    image: 'https://via.placeholder.com/150',
    categories: ['Food'],
    date: '2024-07-27',
    title: 'Richard Norton: Photorealistic Rendering as Real Photos',
    description:
      'Explore photorealistic rendering techniques in art and design.',
    content: `
        <div>
          <h2>Richard Norton: The Art of Photorealistic Rendering</h2>
          <p>Richard Norton is renowned for his photorealistic renderings that capture images with astonishing detail. In this article, we explore his techniques and the impact of his work.</p>
          <h3>Understanding Photorealism</h3>
          <p>Photorealism is a genre of art where the artist strives to create images as realistic as possible. This involves meticulous attention to detail and advanced rendering techniques.</p>
          <h3>Richard Norton's Techniques</h3>
          <p>Norton uses a combination of traditional artistic skills and modern digital tools to achieve his realistic results. His work often involves intricate textures and precise lighting.</p>
          <h3>Impact and Influence</h3>
          <p>Norton's renderings have influenced both the art and design communities, setting new standards for realism and detail.</p>
          <p>Explore his work and be inspired by the level of detail he brings to his renderings.</p>
        </div>
      `,
  },
  {
    id: '6',
    image: 'https://via.placeholder.com/150',
    categories: ['Food'],
    date: '2024-07-27',
    title: 'Delicious Recipes',
    description: 'Explore delicious recipes from around the world.',
    content: `
        <div>
          <h2>More Delicious Recipes</h2>
          <p>If you love cooking and trying new dishes, here are some more recipes to add to your collection:</p>
          <h3>1. Thai Green Curry</h3>
          <p>A spicy and aromatic curry made with green curry paste, coconut milk, and fresh vegetables.</p>
          <h3>2. Beef Stroganoff</h3>
          <p>A rich and creamy beef dish served with mushrooms and sour cream. Perfect over egg noodles or rice.</p>
          <h3>3. Vegan Buddha Bowl</h3>
          <p>A nutritious and vibrant bowl filled with grains, vegetables, and a flavorful dressing.</p>
          <p>Happy cooking!</p>
        </div>
      `,
  },

  {
    id: '7',
    image: 'https://via.placeholder.com/150',
    categories: ['Education', 'Technology'],
    date: '2024-07-30',
    title: 'Latest in Tech',
    description: 'An overview of the latest developments in technology.',
    content: `
        <div>
          <p style="color: blue; font-size: 46px; line-height:48px; margin:0px 0px 30px 0px;">This is the full content of the article, including <strong>HTML elements</strong>.</p>
          <p>Technology is rapidly advancing, and staying up-to-date is crucial for anyone interested in the field. In this article, we explore some of the latest trends and innovations.</p>
          <ul>
            <li><strong>AI and Machine Learning:</strong> These technologies are transforming industries by enabling smarter decision-making and automation.</li>
            <li><strong>Quantum Computing:</strong> A new frontier in computing that promises to solve complex problems much faster than classical computers.</li>
            <li><strong>5G Networks:</strong> The next generation of mobile networks that will significantly enhance connectivity and data transfer speeds.</li>
          </ul>
          <p>Stay tuned for more updates and detailed insights into these exciting technological advancements!</p>
          <p style="color: blue; font-size: 46px; line-height:48px; margin:40px 0px 30px 0px;">This is the full content of the article, including <strong>HTML elements</strong>.</p>
          <p>Technology is rapidly advancing, and staying up-to-date is crucial for anyone interested in the field. In this article, we explore some of the latest trends and innovations.</p>
          <ul>
            <li><strong>AI and Machine Learning:</strong> These technologies are transforming industries by enabling smarter decision-making and automation.</li>
            <li><strong>Quantum Computing:</strong> A new frontier in computing that promises to solve complex problems much faster than classical computers.</li>
            <li><strong>5G Networks:</strong> The next generation of mobile networks that will significantly enhance connectivity and data transfer speeds.</li>
          </ul>
          <p>Stay tuned for more updates and detailed insights into these exciting technological advancements!</p>
        </div>
      `,
  },
  {
    id: '8',
    image: 'https://via.placeholder.com/150',
    categories: ['Education', 'Health'],
    date: '2024-07-29',
    title: 'Health Tips',
    description: 'Daily health tips for a better lifestyle.',
    content: `
        <div>
          <h2>Health Tips for a Better Lifestyle</h2>
          <p>Maintaining good health is essential for a long and fulfilling life. Here are some daily health tips to help you stay fit and healthy:</p>
          <ol>
            <li><strong>Eat a Balanced Diet:</strong> Include a variety of fruits, vegetables, proteins, and whole grains in your diet.</li>
            <li><strong>Stay Hydrated:</strong> Drink plenty of water throughout the day to keep your body hydrated.</li>
            <li><strong>Exercise Regularly:</strong> Engage in physical activity like walking, jogging, or yoga to keep your body active.</li>
            <li><strong>Get Enough Sleep:</strong> Aim for 7-8 hours of quality sleep each night to allow your body to rest and rejuvenate.</li>
            <li><strong>Manage Stress:</strong> Practice relaxation techniques such as meditation or deep breathing to manage stress effectively.</li>
          </ol>
          <p>Incorporating these tips into your daily routine can lead to improved health and well-being. Remember to consult with a healthcare professional for personalized advice.</p>
        </div>
      `,
  },
  {
    id: '9',
    image: 'https://via.placeholder.com/150',
    categories: ['Accommodation', 'Travel'],
    date: '2024-07-28',
    title: 'Travel Guide',
    description: 'Your guide to traveling the world.',
    content: `
        <div>
          <h2>Travel Guide: Exploring the World</h2>
          <p>Traveling opens up new perspectives and experiences. Whether you're a seasoned traveler or a first-time explorer, this guide will help you make the most of your journeys.</p>
          <h3>Planning Your Trip</h3>
          <p>Start by researching your destination, creating an itinerary, and booking accommodations. Consider the best time to visit and any travel advisories.</p>
          <h3>Packing Essentials</h3>
          <p>Pack light and smart. Include essentials like travel documents, a good pair of shoes, and any medications you might need.</p>
          <h3>While Traveling</h3>
          <p>Immerse yourself in the local culture, try new foods, and interact with locals. Always stay aware of your surroundings and keep your belongings secure.</p>
          <h3>Returning Home</h3>
          <p>Reflect on your travel experiences and organize your photos and souvenirs. Share your journey with friends and family.</p>
          <p>Happy travels!</p>
        </div>
      `,
  },
  {
    id: '10',
    image: 'https://via.placeholder.com/150',
    categories: ['Food', 'Other'],
    date: '2024-07-27',
    title: 'Delicious Recipes',
    description: 'Explore delicious recipes from around the world.',
    content: `
        <div>
          <h2>Delicious Recipes to Try</h2>
          <p>Food is a universal language that brings people together. Here are some mouth-watering recipes you can try at home:</p>
          <h3>1. Spaghetti Carbonara</h3>
          <p>A classic Italian pasta dish made with eggs, cheese, pancetta, and pepper. It's creamy, savory, and incredibly satisfying.</p>
          <h3>2. Chicken Tikka Masala</h3>
          <p>A flavorful Indian dish featuring marinated chicken cooked in a spicy tomato cream sauce. Serve it with rice or naan bread.</p>
          <h3>3. Chocolate Lava Cake</h3>
          <p>An indulgent dessert with a gooey chocolate center. Perfect for a special occasion or a sweet treat.</p>
          <p>Enjoy cooking and savoring these delicious dishes!</p>
        </div>
      `,
  },
  {
    id: '11',
    image: 'https://via.placeholder.com/150',
    categories: ['Food'],
    date: '2024-07-27',
    title: 'Richard Norton: Photorealistic Rendering as Real Photos',
    description:
      'Explore photorealistic rendering techniques in art and design.',
    content: `
        <div>
          <h2>Richard Norton: The Art of Photorealistic Rendering</h2>
          <p>Richard Norton is renowned for his photorealistic renderings that capture images with astonishing detail. In this article, we explore his techniques and the impact of his work.</p>
          <h3>Understanding Photorealism</h3>
          <p>Photorealism is a genre of art where the artist strives to create images as realistic as possible. This involves meticulous attention to detail and advanced rendering techniques.</p>
          <h3>Richard Norton's Techniques</h3>
          <p>Norton uses a combination of traditional artistic skills and modern digital tools to achieve his realistic results. His work often involves intricate textures and precise lighting.</p>
          <h3>Impact and Influence</h3>
          <p>Norton's renderings have influenced both the art and design communities, setting new standards for realism and detail.</p>
          <p>Explore his work and be inspired by the level of detail he brings to his renderings.</p>
        </div>
      `,
  },
  {
    id: '12',
    image: 'https://via.placeholder.com/150',
    categories: ['Food'],
    date: '2024-07-27',
    title: 'Delicious Recipes',
    description: 'Explore delicious recipes from around the world.',
    content: `
        <div>
          <h2>More Delicious Recipes</h2>
          <p>If you love cooking and trying new dishes, here are some more recipes to add to your collection:</p>
          <h3>1. Thai Green Curry</h3>
          <p>A spicy and aromatic curry made with green curry paste, coconut milk, and fresh vegetables.</p>
          <h3>2. Beef Stroganoff</h3>
          <p>A rich and creamy beef dish served with mushrooms and sour cream. Perfect over egg noodles or rice.</p>
          <h3>3. Vegan Buddha Bowl</h3>
          <p>A nutritious and vibrant bowl filled with grains, vegetables, and a flavorful dressing.</p>
          <p>Happy cooking!</p>
        </div>
      `,
  },
];
