import React, { ButtonHTMLAttributes, ReactNode } from 'react';

export type CustomButtonProps = {
  label: ReactNode;
  icon?: ReactNode; // Change icon prop type to ReactNode
  customStyle?: string;
  isLoading?: boolean;
  iconStyle?: string;
  variant?:
    | 'primary'
    | 'primary-transparent'
    | 'category-active'
    | 'category-inactive';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  icon,
  customStyle,
  variant = 'primary-transparent',
  isLoading = false,
  iconStyle,
  ...props
}) => {
  const variantStyles = (() => {
    switch (variant) {
      case 'primary':
        return 'bg-[rgb(238,77,103)] text-white';
      case 'primary-transparent':
        return 'text-[rgb(238,77,103)] border-[rgb(238,77,103)] border-[1px] bg-transparent';
      case 'category-active':
        return 'bg-primary text-white';
      case 'category-inactive':
        return 'bg-gray-200 text-gray-600';
      default:
        return '';
    }
  })();

  return (
    <button
      className={`flex items-center justify-center h-10 hover:bg-primaryHover px-6 text-sm hover:text-white rounded-sm delay-150 duration-300 ${variantStyles} ${customStyle}`}
      {...props}
    >
      {icon && <span className={`mr-2 ${iconStyle}`}>{icon}</span>}
      {isLoading ? <span>Loading...</span> : label}
    </button>
  );
};

export default CustomButton;
