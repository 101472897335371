import React from 'react';
import { AboutUsItem } from 'model/AboutUsItem';

type CustomTextImgGridProps = {
  data: AboutUsItem[];
};

const CustomTextImgGrid: React.FC<CustomTextImgGridProps> = ({ data }) => {
  return (
    <div className="grid gap-7 md:gap-10 mx-6">
      {data.map(({ title, description, image }, index) => (
        <div
          key={index}
          className="flex flex-col md:flex-row items-center mb-8"
        >
          {index % 2 === 0 ? (
            <>
              <div className="md:w-1/2 sm:pr-6 md:pr-12">
                <h2 className="lg:text-3xl font-medium text-secondary mb-2">
                  {title}
                </h2>
                <hr className="w-14  border-t-4 border-primary sm:mt-4 sm:mb-12 mt-2 mb-6" />
                <p className="text-xs sm:text-based md:text-sm mb-6 text-primaryTextColor">
                  {description}
                </p>
              </div>
              <div className="md:w-1/2 ">
                <img
                  src={image}
                  alt={title}
                  className="lg:w-1/2 md:float-right h-auto rounded-lg"
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col-reverse md:flex-row items-center">
                <div className="md:w-1/2 md:pr-12">
                  <img
                    src={image}
                    alt={title}
                    className="lg:w-1/2 md:float-left h-auto rounded-lg"
                  />
                </div>
                <div className="md:w-1/2 sm:pr-6 md:pr-12">
                  <h2 className="lg:text-3xl font-medium text-secondary mb-2">
                    {title}
                  </h2>
                  <hr className="w-14  border-t-4 border-primary sm:mt-4 sm:mb-12 mt-2 mb-6" />
                  <p className="text-xs sm:text-based md:text-sm mb-6 text-primaryTextColor">
                    {description}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default CustomTextImgGrid;
