import React, { useState, useEffect } from 'react';
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
  ArrowUturnLeftIcon,
} from '@heroicons/react/24/solid';

import TextArea from 'components/common/TextArea';
import Button from 'components/common/Button';
import SignIn from 'components/auth/Signin';
import NewcomerNotification from 'components/city/NewcomerNotifiaction';
import { voteType } from 'model/City';
import { useUser } from 'api/context/UserContext';
import { UserRole } from 'constants/User';

type SocialInteractionProps = {
  showThumbsUp?: boolean;
  showThumbsDown?: boolean;
  showShare?: boolean;
  likes?: number;
  unLikes?: number;
  questionId: string;
  onReplyClick?: () => void;
  handleSendClick?: (comment: string, questionId: string) => void;
  handleVoteClick?: (voteType: voteType) => void;
};

const SocialInteraction: React.FC<SocialInteractionProps> = ({
  showThumbsUp = true,
  showThumbsDown = false,
  showShare = false,
  likes = 0,
  unLikes = 0,
  handleSendClick,
  questionId,
  handleVoteClick,
}) => {
  const [socialState, setSocialState] = useState({
    thumbsUpActive: true,
    thumbsDownActive: false,
    thumbsUpCount: likes,
    thumbsDownCount: unLikes,
    shareCount: 0,
  });
  const [comment, setComment] = useState<string>('');
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const [isNewcomerNotificationOpen, setIsNewcomerNotificationOpen] =
    useState<boolean>(false);
  const [isShareHidden, setIsShareHidden] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const user = useUser();
  const isFriend = user?.user?.role === UserRole.FRIEND;

  useEffect(() => {
    // Simulate loading state
    if (user?.user) {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!loading) {
      if (user?.user && !isFriend) {
        setIsShareHidden(true);
      }
    }
  }, [user, isFriend, loading]);

  useEffect(() => {
    if (!loading) {
      const queryParams = new URLSearchParams(window.location.search);
      const tryReply = queryParams.get('reply');
      if (tryReply === 'true' && !isFriend) {
        setIsNewcomerNotificationOpen(true);
      } else {
        setIsNewcomerNotificationOpen(false);
      }
      if (isFriend) {
        const url = new URL(window.location.href);
        url.searchParams.delete('reply');
        window.history.replaceState({}, '', url.toString());
      }
    }
  }, [isFriend, loading]);

  const handleInteraction = (callback: () => void) => {
    if (!user?.user) {
      const currentUrl = window.location.href;
      let urlWithReply = currentUrl;

      if (!isFriend) {
        // Add reply=true to URL if not a friend
        if (!currentUrl.includes('reply=true')) {
          urlWithReply += currentUrl.includes('?')
            ? '&reply=true'
            : '?reply=true';
        }
      }

      localStorage.setItem('redirectAfterSignIn', urlWithReply);
      setIsSignInModalOpen(true);
    } else {
      callback();
    }
  };

  const handleThumbsUpClick = () => {
    handleInteraction(() => {
      setSocialState((prevState) => ({
        ...prevState,
        thumbsUpActive: !prevState.thumbsUpActive,
        thumbsDownActive: prevState.thumbsUpActive
          ? prevState.thumbsDownActive
          : false, // Reset downvote if upvote is active
        thumbsUpCount: prevState.thumbsUpActive
          ? prevState.thumbsUpCount - 1
          : prevState.thumbsUpCount + 1,
        thumbsDownCount: prevState.thumbsUpActive
          ? prevState.thumbsDownCount
          : 0, // Reset downvote count
      }));
      handleVoteClick?.(voteType.UPVOTE);
    });
  };

  const handleThumbsDownClick = () => {
    handleInteraction(() => {
      setSocialState((prevState) => ({
        ...prevState,
        thumbsDownActive: !prevState.thumbsDownActive,
        thumbsUpActive: prevState.thumbsDownActive
          ? prevState.thumbsUpActive
          : false, // Reset upvote if downvote is active
        thumbsDownCount: prevState.thumbsDownActive
          ? prevState.thumbsDownCount - 1
          : prevState.thumbsDownCount + 1,
        thumbsUpCount: prevState.thumbsDownActive ? prevState.thumbsUpCount : 0, // Reset upvote count
      }));
      handleVoteClick?.(voteType.DOWNVOTE);
    });
  };
  const handleShareClick = () => {
    handleInteraction(() => {
      if (isFriend) {
        setShowCommentBox(!showCommentBox);
      }
    });
  };

  const handleNotificationClose = () => {
    setIsNewcomerNotificationOpen(false);
    const url = new URL(window.location.href);
    url.searchParams.delete('reply');
    window.history.replaceState({}, '', url.toString());
  };

  return (
    <>
      <SignIn
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <NewcomerNotification
        isOpen={isNewcomerNotificationOpen}
        onClose={handleNotificationClose}
      />
      <div className="interactive-icons flex items-center space-x-2">
        {showThumbsUp && (
          <>
            <HandThumbUpIcon
              className={`h-5 w-5 ${
                socialState.thumbsUpActive ? 'thumbs-up-fill' : 'thumbs-up'
              } interactive-icon cursor-pointer ${
                socialState.thumbsUpActive ? 'text-primary' : ''
              }`}
              onClick={handleThumbsUpClick}
            />
            <span>{socialState.thumbsUpCount}</span>
          </>
        )}
        {showThumbsDown && (
          <>
            <HandThumbDownIcon
              className={`h-5 w-5 !ml-5 ${
                socialState.thumbsDownActive
                  ? 'thumbs-down-fill'
                  : 'thumbs-down'
              } interactive-icon cursor-pointer ${
                socialState.thumbsDownActive ? 'text-primary' : ''
              }`}
              onClick={handleThumbsDownClick}
            />
            <span>{socialState.thumbsDownCount}</span>
          </>
        )}
        {showShare && !isShareHidden && (
          <>
            <ArrowUturnLeftIcon
              onClick={handleShareClick}
              className="h-5 w-5  interactive-icon cursor-pointer !ml-5"
            />
          </>
        )}
      </div>
      {showCommentBox && (
        <>
          <div className="text-xs font-medium mt-4">Write a comment</div>
          <div>
            <TextArea
              name="reply"
              placeholder="Type your Reply"
              value={comment}
              onChange={(e: {
                target: { value: React.SetStateAction<string> };
              }) => setComment(e.target.value)}
              className="!mb-0"
            />
          </div>
          <div className="flex justify-end">
            <Button
              label="SEND"
              customStyle="px-2 mt-4 !text-xs !h-[32px]"
              variant="primary-transparent"
              onClick={() =>
                handleInteraction(() => handleSendClick?.(comment, questionId))
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default SocialInteraction;
