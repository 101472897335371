import { useQuery } from '@tanstack/react-query';
import { getLoggedUserDetails } from 'api/requests/auth';
import { getUserByUserId } from 'api/requests/users';

export const useGetLoggedUserDetails = ({ enabled }: { enabled: boolean }) => {
  console.log('Query enabled:', enabled);
  return useQuery({
    queryKey: ['loggedUser'],
    queryFn: getLoggedUserDetails,
    enabled,
    staleTime: Infinity,
  });
};

export const useGetUserByUserId = (userId: string) => {
  return useQuery({
    queryKey: ['userByUserId', userId],
    queryFn: () => getUserByUserId(userId),
  });
};
