import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import useScreenSize from 'hooks/useScreenSize';
import { City } from 'model/City';
import { useNavigate } from 'react-router-dom';

type CarouselProps = {
  data: City[];
};

const CityCarousel: React.FC<CarouselProps> = ({ data }) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const { width } = useScreenSize();

  const getVisibleImages = () => {
    if (width >= 668) return 4;
    else return 3;
  };

  const visibleImages = getVisibleImages();

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + visibleImages) % data?.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - visibleImages + data?.length) % data?.length
    );
  };

  const displayedImages = data
    ?.slice(currentIndex, currentIndex + visibleImages)
    ?.concat(
      data?.slice(0, Math.max(0, currentIndex + visibleImages - data.length))
    );
  const showArrows = data?.length > visibleImages;

  return (
    <div className="relative w-full max-w-6xl mx-auto">
      <div className="flex overflow-hidden">
        {displayedImages?.map((item, index) => (
          <div
            key={index}
            className={`flex-shrink-0 ${visibleImages === 4 ? 'lg:w-1/4' : visibleImages === 3 ? 'md:w-1/3' : visibleImages === 2 ? 'sm:w-1/2' : 'w-full'}`}
            onClick={() => {
              navigate(`/city/${item.id}`);
            }}
          >
            <div className="relative p-2">
              <img
                src={
                  item?.image
                    ? `${process.env.REACT_APP_BASE_URL}/${item.image}`
                    : ''
                }
                alt={item?.name}
                className="lg:w-52 lg:h-72 w-32 h-40 object-cover rounded-sm shadow-lg"
              />
              <div className="absolute left-10 right-10 bottom-10 text-center text-white">
                <h2 className="text-xs sm:text-base font-bold">{item?.name}</h2>
                <p className="text-[7px] sm:text-sm">{item?.country}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showArrows && (
        <>
          <button
            onClick={prevImage}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full p-2"
          >
            <ChevronLeftIcon className="h-6 w-6 text-black" />
          </button>
          <button
            onClick={nextImage}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full p-2"
          >
            <ChevronRightIcon className="h-6 w-6 text-black" />
          </button>
        </>
      )}
    </div>
  );
};

export default CityCarousel;
