import Profile from 'assets/images/become-a-friend/bulb.png';

export const ratings = [
  { name: 'Education', rating: 4, count: 3 },
  { name: 'Accommodation', rating: 0, count: 3 },
  { name: 'Transport', rating: 5, count: 3 },
  { name: 'Other', rating: 0, count: 3 },
];

export const userData = {
  username: 'Steve Rogers',
  profilePictureUrl: Profile,
};

export const friendsList = [
  {
    _id: '1',
    username: 'Steve Rogers',
    profilePictureUrl: Profile,
  },
  {
    _id: '2',
    username: 'Steve Rogers',
    profilePictureUrl: Profile,
  },
  {
    _id: '3',
    username: 'Steve Rogers',
    profilePictureUrl: Profile,
  },
  {
    _id: '4',
    username: 'Steve Rogers',
    profilePictureUrl: Profile,
  },
  {
    _id: '5',
    username: 'Steve Rogers',
    profilePictureUrl: Profile,
  },
];
