export enum DurationOptions {
  _15_MINUTES = '15',
  _30_MINUTES = '30',
  _45_MINUTES = '45',
  _1_HOUR = '60',
}

export enum AppointmentStatus {
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  SUGGESTED = 'SUGGESTED',
  RESCHEDULED = 'RESCHEDULED',
  APPROVED = 'APPROVED',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
}
