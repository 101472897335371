import React from 'react';
import Button, { CustomButtonProps } from 'components/common/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface CategoryButtonProps extends Omit<CustomButtonProps, 'variant'> {
  label: string;
  isActive: boolean;
  customStyle?: string;
  variantStyle?: string | null;
  forQuestionPanel?: boolean;
  onIconClick?: () => void;
}

const categoryStyles = {
  active:
    'flex justify-center items-center text-xs px-3 w-auto text-white !bg-categoryActive hover:bg-[#365781] border-none',
  inactive:
    'flex justify-center items-center text-xs px-3 w-auto text-secondary !bg-categoryInactive border-none',
  questionPanel:
    'flex justify-center items-center h-[36px] rounded-xs px-3 w-auto !text-cookieNotificationText !text-lg !bg-homeItemBgColor border-none cursor-default !text-xs',
  past: 'flex justify-center items-center px-3 w-auto !text-secondry !bg-categoryInactive opacity-60 pointer-events-none',
};

const CategoryButton: React.FC<CategoryButtonProps> = ({
  label,
  isActive,
  customStyle,
  variantStyle = null,
  forQuestionPanel = false,
  disabled,
  onIconClick,
  ...props
}) => {
  let buttonStyle = variantStyle
    ? variantStyle
    : isActive
      ? categoryStyles.active
      : categoryStyles.inactive;

  if (forQuestionPanel) {
    buttonStyle = categoryStyles.questionPanel;
  }

  if (disabled) {
    buttonStyle = categoryStyles.past;
  }

  return (
    <div className="relative inline-flex items-center">
      <Button
        label={label}
        type="button"
        className={`h-[30px] rounded-full button-d-container ${buttonStyle} ${customStyle}`}
        disabled={disabled}
        {...props}
      />
      {onIconClick && (
        <XMarkIcon
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            onIconClick();
          }}
          className={`w-4 h-4 text-black absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer stroke-3`}
        />
      )}
    </div>
  );
};

export default CategoryButton;
