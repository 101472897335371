import React, { useState, useEffect } from 'react';
import CustomTabView, { Tab } from 'components/scheduled-calls/CallTab';
import { TabHeader } from 'constants/TabHeader';
import DateCarousel from './DateCarousel';
import SortDropdown from 'components/sort-dropdown/SortDropdown';
import CallScheduleCard from 'components/scheduled-calls/ScheduledCallCard';
import { AppointmentStatus } from 'constants/scheduleCallEnums';

export type CallScheduleCardProps = {
  onReschedule: (
    availabilityId: string,
    duration: number,
    newcomerTimezone: string,
    status: AppointmentStatus
  ) => void;
};

const ScheduledCall: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(TabHeader.AllCalls);
  const [appointments, setAppointments] = useState<any[]>([]); // Replace `any` with actual appointment data type

  const handleReschedule = (
    availabilityId: string,
    duration: number,
    newcomerTimezone: string,
    status:
      | 'SCHEDULED'
      | 'CANCELLED'
      | 'DECLINED'
      | 'SUGGESTED'
      | 'RESCHEDULED'
      | 'APPROVED'
      | 'CONFIRMED'
      | 'PENDING'
  ) => {
    console.log(`Reschedule for appointment ${availabilityId}`);
  };

  const handleCancel = () => {
    console.log('Call cancelled');
  };

  const handleJoin = () => {
    console.log('Joining the call');
  };

  const handleConfirm = () => {
    console.log('Appointment confirmed');
  };

  const handleDecline = () => {
    console.log('Appointment declined');
  };

  const handleTabChange = (index: number) => {
    setSelectedTab(tabs[index].header);
  };

  const tabs: Tab[] = [
    {
      header: TabHeader.AllCalls,
      content: (
        <>
          {appointments.map((appointment) => (
            <CallScheduleCard
              key={appointment.id}
              username={appointment.username}
              location={appointment.location}
              duration={appointment.duration}
              date={appointment.date}
              startTime={appointment.startTime}
              endTime={appointment.endTime}
              CreatedDate={appointment.CreatedDate}
              rescheduledSuggestedDate={appointment.rescheduledSuggestedDate}
              newcomerTimezone={appointment.newcomerTimezone}
              timeSpent={appointment.timeSpent}
              profilePictureUrl={appointment.profilePictureUrl}
              status={appointment.status}
              meetingLink={appointment.meetingLink}
              reminders={appointment.reminders}
              question={appointment.question}
              onReschedule={handleReschedule}
              onCancel={handleCancel}
              onJoin={handleJoin}
              onConfirm={handleConfirm}
              onDecline={handleDecline}
              suggestion={appointment.suggestion}
              friendTimezone={appointment.friendTimezone}
              statusText={appointment.statusText}
              isNewcomer={appointment.isNewcomer}
              appointmentId={appointment.id}
              isConfirmedByFriend={appointment.isConfirmedByFriend}
            />
          ))}
        </>
      ),
    },
    {
      header: TabHeader.Pending,
      content: (
        <>
          {appointments
            .filter(
              (appointment) => appointment.status === AppointmentStatus.PENDING
            )
            .map((appointment) => (
              <CallScheduleCard
                key={appointment.id}
                username={appointment.username}
                location={appointment.location}
                duration={appointment.duration}
                date={appointment.date}
                startTime={appointment.startTime}
                endTime={appointment.endTime}
                CreatedDate={appointment.CreatedDate}
                rescheduledSuggestedDate={appointment.rescheduledSuggestedDate}
                newcomerTimezone={appointment.newcomerTimezone}
                timeSpent={appointment.timeSpent}
                profilePictureUrl={appointment.profilePictureUrl}
                status={appointment.status}
                meetingLink={appointment.meetingLink}
                reminders={appointment.reminders}
                question={appointment.question}
                onReschedule={handleReschedule}
                onCancel={handleCancel}
                onJoin={handleJoin}
                onConfirm={handleConfirm}
                onDecline={handleDecline}
                suggestion={appointment.suggestion}
                friendTimezone={appointment.friendTimezone}
                statusText={appointment.statusText}
                isNewcomer={appointment.isNewcomer}
                appointmentId={appointment.id}
                isConfirmedByFriend={appointment.isConfirmedByFriend}
              />
            ))}
        </>
      ),
    },
    {
      header: TabHeader.Confirmed,
      content: (
        <>
          {appointments
            .filter(
              (appointment) =>
                appointment.status === AppointmentStatus.CONFIRMED
            )
            .map((appointment) => (
              <CallScheduleCard
                key={appointment.id}
                username={appointment.username}
                location={appointment.location}
                duration={appointment.duration}
                date={appointment.date}
                startTime={appointment.startTime}
                endTime={appointment.endTime}
                CreatedDate={appointment.CreatedDate}
                rescheduledSuggestedDate={appointment.rescheduledSuggestedDate}
                newcomerTimezone={appointment.newcomerTimezone}
                timeSpent={appointment.timeSpent}
                profilePictureUrl={appointment.profilePictureUrl}
                status={appointment.status}
                meetingLink={appointment.meetingLink}
                reminders={appointment.reminders}
                question={appointment.question}
                onReschedule={handleReschedule}
                onCancel={handleCancel}
                onJoin={handleJoin}
                onConfirm={handleConfirm}
                onDecline={handleDecline}
                suggestion={appointment.suggestion}
                friendTimezone={appointment.friendTimezone}
                statusText={appointment.statusText}
                isNewcomer={appointment.isNewcomer}
                appointmentId={appointment.id}
                isConfirmedByFriend={appointment.isConfirmedByFriend}
              />
            ))}
        </>
      ),
    },
    {
      header: TabHeader.Rescheduled,
      content: (
        <>
          {appointments
            .filter(
              (appointment) =>
                appointment.status === AppointmentStatus.RESCHEDULED
            )
            .map((appointment) => (
              <CallScheduleCard
                key={appointment.id}
                username={appointment.username}
                location={appointment.location}
                duration={appointment.duration}
                date={appointment.date}
                startTime={appointment.startTime}
                endTime={appointment.endTime}
                CreatedDate={appointment.CreatedDate}
                rescheduledSuggestedDate={appointment.rescheduledSuggestedDate}
                newcomerTimezone={appointment.newcomerTimezone}
                timeSpent={appointment.timeSpent}
                profilePictureUrl={appointment.profilePictureUrl}
                status={appointment.status}
                meetingLink={appointment.meetingLink}
                reminders={appointment.reminders}
                question={appointment.question}
                onReschedule={handleReschedule}
                onCancel={handleCancel}
                onJoin={handleJoin}
                onConfirm={handleConfirm}
                onDecline={handleDecline}
                suggestion={appointment.suggestion}
                friendTimezone={appointment.friendTimezone}
                statusText={appointment.statusText}
                isNewcomer={appointment.isNewcomer}
                appointmentId={appointment.id}
                isConfirmedByFriend={appointment.isConfirmedByFriend}
              />
            ))}
        </>
      ),
    },
    {
      header: TabHeader.Cancelled,
      content: (
        <>
          {appointments
            .filter(
              (appointment) =>
                appointment.status === AppointmentStatus.CANCELLED
            )
            .map((appointment) => (
              <CallScheduleCard
                key={appointment.id}
                username={appointment.username}
                location={appointment.location}
                duration={appointment.duration}
                date={appointment.date}
                startTime={appointment.startTime}
                endTime={appointment.endTime}
                CreatedDate={appointment.CreatedDate}
                rescheduledSuggestedDate={appointment.rescheduledSuggestedDate}
                newcomerTimezone={appointment.newcomerTimezone}
                timeSpent={appointment.timeSpent}
                profilePictureUrl={appointment.profilePictureUrl}
                status={appointment.status}
                meetingLink={appointment.meetingLink}
                reminders={appointment.reminders}
                question={appointment.question}
                onReschedule={handleReschedule}
                onCancel={handleCancel}
                onJoin={handleJoin}
                onConfirm={handleConfirm}
                onDecline={handleDecline}
                suggestion={appointment.suggestion}
                friendTimezone={appointment.friendTimezone}
                statusText={appointment.statusText}
                isNewcomer={appointment.isNewcomer}
                appointmentId={appointment.id}
                isConfirmedByFriend={appointment.isConfirmedByFriend}
              />
            ))}
        </>
      ),
    },
    {
      header: TabHeader.Declined,
      content: (
        <>
          {appointments
            .filter(
              (appointment) => appointment.status === AppointmentStatus.DECLINED
            )
            .map((appointment) => (
              <CallScheduleCard
                key={appointment.id}
                username={appointment.username}
                location={appointment.location}
                duration={appointment.duration}
                date={appointment.date}
                startTime={appointment.startTime}
                endTime={appointment.endTime}
                CreatedDate={appointment.CreatedDate}
                rescheduledSuggestedDate={appointment.rescheduledSuggestedDate}
                newcomerTimezone={appointment.newcomerTimezone}
                timeSpent={appointment.timeSpent}
                profilePictureUrl={appointment.profilePictureUrl}
                status={appointment.status}
                meetingLink={appointment.meetingLink}
                reminders={appointment.reminders}
                question={appointment.question}
                onReschedule={handleReschedule}
                onCancel={handleCancel}
                onJoin={handleJoin}
                onConfirm={handleConfirm}
                onDecline={handleDecline}
                suggestion={appointment.suggestion}
                friendTimezone={appointment.friendTimezone}
                statusText={appointment.statusText}
                isNewcomer={appointment.isNewcomer}
                appointmentId={appointment.id}
                isConfirmedByFriend={appointment.isConfirmedByFriend}
              />
            ))}
        </>
      ),
    },
  ];

  const handleDropdownChange = (value: string) => {
    const tabIndex = tabs.findIndex((tab) => tab.header === value);
    handleTabChange(tabIndex);
  };

  const fetchAppointmentsData = async (status: string) => {
    const dummyData = [
      {
        id: '1',
        username: 'John Doe',
        location: 'Conference Room',
        duration: 30,
        date: '2024-07-01',
        startTime: '4:00 PM',
        endTime: '4:30 PM',
        CreatedDate: '2024-06-30T12:00:00Z',
        rescheduledSuggestedDate: null,
        newcomerTimezone: 'America/New_York',
        timeSpent: 20,
        profilePictureUrl:
          'https://fastly.picsum.photos/id/593/200/200.jpg?hmac=E26lTUTkzs_AeuWXrkT-kFTudfYDTVCjgKVE_HDzRmk',
        status: 'CONFIRMED',
        meetingLink: 'https://meeting.example.com',
        reminders: ['Reminder 1', 'Reminder 2'],
        question: 'What is your favorite color?',
        suggestion: null,
        friendTimezone: 'America/Los_Angeles',
        statusText: 'Confirmed',
        isNewcomer: true,
        appointmentId: '1',
        isConfirmedByFriend: true,
      },
      {
        id: '2',
        username: 'John Doe',
        location: 'Conference Room',
        duration: 30,
        date: '2024-07-01',
        startTime: '4:00 PM',
        endTime: '4:30 PM',
        CreatedDate: '2024-06-30T12:00:00Z',
        rescheduledSuggestedDate: null,
        newcomerTimezone: 'America/New_York',
        timeSpent: 20,
        profilePictureUrl:
          'https://fastly.picsum.photos/id/593/200/200.jpg?hmac=E26lTUTkzs_AeuWXrkT-kFTudfYDTVCjgKVE_HDzRmk',
        status: 'PENDING',
        meetingLink: 'https://meeting.example.com',
        reminders: ['Reminder 1', 'Reminder 2'],
        question: 'What is your favorite color?',
        suggestion: null,
        friendTimezone: 'America/Los_Angeles',
        statusText: 'Confirmed',
        isNewcomer: true,
        appointmentId: '1',
        isConfirmedByFriend: true,
      },
      {
        id: '3',
        username: 'John Doe',
        location: 'Conference Room',
        duration: 30,
        date: '2024-07-01',
        startTime: '4:00 PM',
        endTime: '4:30 PM',
        CreatedDate: '2024-06-30T12:00:00Z',
        rescheduledSuggestedDate: '2024-07-02T14:00:00Z',
        newcomerTimezone: 'America/New_York',
        timeSpent: 20,
        profilePictureUrl:
          'https://fastly.picsum.photos/id/593/200/200.jpg?hmac=E26lTUTkzs_AeuWXrkT-kFTudfYDTVCjgKVE_HDzRmk',
        status: 'RESCHEDULED',
        meetingLink: 'https://meeting.example.com',
        reminders: ['Reminder 1', 'Reminder 2'],
        question: 'What is your favorite color?',
        suggestion: {
          username: 'Jane Doe',
          friendNewcomerName: 'John Doe',
          newDate: '2023-07-20',
          newTime: '11:00 AM',
          onConfirm: () => {},
        },
        friendTimezone: 'America/Los_Angeles',
        statusText: 'Rescheduled',
        isNewcomer: true,
        appointmentId: '1',
        isConfirmedByFriend: true,
      },

      // remove this after API integrate
    ];
    console.log('Simulated data:', dummyData); // Check simulated data
    setAppointments(dummyData);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('Fetching data for tab:', selectedTab);
    fetchAppointmentsData(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    console.log('Appointments state updated:', appointments);
  }, [appointments]);

  return (
    <div className="max-w-7xl mx-auto min-h-screen">
      <div className="text-lg font-medium text-secondary mb-4">
        Scheduled Calls
      </div>
      <div className="flex flex-col items-center">
        <DateCarousel />
      </div>

      <div className="flex justify-start items-center">
        <p className="text-xs mr-2 md:hidden">sort by:</p>
        {isMobile ? (
          <div className="">
            <SortDropdown
              options={tabs.map((tab) => ({
                label: tab.header,
                value: tab.header,
              }))}
              value={selectedTab}
              onChange={handleDropdownChange}
            />
          </div>
        ) : (
          <CustomTabView tabs={tabs} onTabChange={handleTabChange} />
        )}
      </div>

      <div className="py-4 md:hidden">
        {tabs.find((tab) => tab.header === selectedTab)?.content || (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default ScheduledCall;
