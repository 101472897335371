import React from 'react';
import CustomDropdown from 'components/common/Dropdown';

type TimeDropdownProps = {
  selectedItem: string;
  setSelectedItem: React.Dispatch<React.SetStateAction<any>>;
  items: { label: string; value: any }[];
  placeholder?: string;
  disable?: boolean;
  hasError?: boolean;
  wrapperclassName?: string;
};

const TimeDropdown: React.FC<TimeDropdownProps> = ({
  selectedItem,
  setSelectedItem,
  items,
  placeholder,
  wrapperclassName = '',
}) => {
  return (
    <div className={`${wrapperclassName}`}>
      <CustomDropdown
        name="time"
        value={selectedItem || items[0].value}
        onChange={(e: any) => setSelectedItem(new Date(e))}
        options={items}
        placeholder={placeholder as string}
        customMargin="!mb-0"
        customPlaceHolder="text-textFieldBlue border-textFieldBlue pl-2 md:pl-7"
      />
    </div>
  );
};

export default TimeDropdown;
