import { TermAndPolicyData } from 'model/TermsAndPolicyItems';

const termAndPolicyData: TermAndPolicyData[] = [
  {
    id: 'purpose',
    mainTitle: 'Purpose of this Policy',
    description: [
      {
        topic:
          'The purpose of this Privacy Policy is to let you know what kind of information Move2NewCity and our Affiliates may gather about you when you visit this Site, inline with the Privacy Act 1998 (Cth) including the Australian Privacy Principles.',
      },
      {
        topic:
          '“Personal Information” is, broadly speaking, any information relating to a person, where that person is identified or could be identified.',
      },
      {
        topic:
          'Protecting your privacy is very important to Move2NewCity. We are committed to maintaining the security of Personal Information provided to us.',
      },
    ],
  },
  {
    id: 'definitions',
    mainTitle: 'Definitions',
    description: [
      {
        topic: 'The following definitions apply in this Privacy Policy:',
        content: [
          '<ul class= "list-disc list-outside ml-6"><li>“Registered” means you have signed up with Move2NewCity;</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>“Members” refer to Users who are Registered with Move2NewCity, primarily “Newcomers” and “Friends;</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>“Policy” means this Privacy Policy as amended from time to time;</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>“Privacy Act” means the Privacy Act (Cth) 1988, as amended from time to time;</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>“User Agreement” means the agreement between Move2NewCity and each User of the Site which sets out the terms and conditions in respect to the Site, and is located at <a class="text-primary" target="_blank" href="/terms-and-conditions">Move2NewCity.com.au/terms-of-use</a>;</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>“Users” refer to Members and Visitors;</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>“Visitors” refer to individuals who visit and browse the Site but are not Registered.</li></ul>',
        ],
      },
      {
        topic:
          'Unless the context provides otherwise, capitalized terms not defined in paragraph 2.1 or elsewhere in this Policy have the meanings given in our User Agreement located at <a class="text-primary" href="/privacy-policy">Move2NewCity.com/privacy-policy</a>.',
      },
    ],
  },
  {
    id: 'personal-information-collection',
    mainTitle: 'Who does Move2NewCity collect Personal Information from',
    description: [
      {
        topic: 'We may collect Personal Information from:',
        content: [
          '<ul class= "list-disc list-outside ml-6"><li>Visitors when they visit our Site; or</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>Visitors who are engaged through social media channels or Affiliate websites; or</li></ul>',
          '<ul class= "list-disc list-outside ml-6"><li>Members, which includes Newcomers and Friends, who are a part of the Move2NewCity solution.</li></ul>',
        ],
      },
    ],
  },
  {
    id: 'user-consent',
    mainTitle: 'User consent',
    data: 'By submitting Personal Information through our Site or Affiliate Services (including when you register yourself), you agree to the terms of this Policy on your own behalf or on behalf of the person whose information you are submitting, and you expressly consent to the collection, use and disclosure of that Personal Information in accordance with this Policy.',
  },
  {
    id: 'types-of-data-collect',
    mainTitle: 'Types of data we collect',
    description: [
      {
        topic:
          'The information Move2NewCity collects about you depends on how and why you access our Site and use our Services.',
      },
      {
        topic:
          'We collect Personal Information and anonymous data, as described below.',
      },
    ],
    subTitles: [
      {
        id: 'information-you-provide',
        title: 'Information you provide to us',
        content: [
          '<ul class= "list-disc list-outside ml-6"><li><b/>Information you provide to us</b></li><ul class= "list-disc list-outside ml-6"><li>If you are a Newcomer, when you Register, we will collect Personal Information from you, such as your email address, first and last name, whether you are moving as a family or an individual, whether you are a student or a professional, areas of know-how you are after including not limited to accommodation, education, transportation etc; and credit card details through an Affiliate service for any paid services.</li><li>If you are a Friend and you are willing to share your lived experiences and earn rewards, when you Register, we will collect Personal Information from you, such as your email address, first and last name, areas of know-how you are after including not limited to accommodation, education, transportation etc.</li><li>If you are a Member other than those described in paragraphs 5.2.1.1 and 5.2.1.2 above, when you Register, we will collect Personal Information from you, similar to the information outlined in paragraphs 5.2.1.1 and 5.2.1.2 above.</li></ul></ul>',
        ],
      },
      {
        id: 'sensitive-information',
        title: 'Sensitive Information',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Sensitive Information</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>We may collect sensitive information (including lived experiences) from Members to provide better matching services between Newcomers and Friends.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },

      {
        id: 'other-ways-collect-information',
        title: 'Other ways we may collect Personal Information',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Other ways we may collect Personal Information</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>Upon Registration, we collect and retain information on your behalf.</li>' +
            '<li>When you post messages or upload content to Move2NewCity, the information contained in your posting will be stored on our servers, and other Members to whom you provide access will be able to see such messages and content.</li>' +
            '<li>We also collect other types of Personal Information that you provide to us voluntarily, such as your operating system and version, and other requested information.</li>' +
            '<li>We may also collect Personal Information at such other points in our Site that state that Personal Information is being collected. Please note that you can choose not to provide us with certain information, but this may limit the features of Move2NewCity or Services you are able to use.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },

      {
        id: 'information-via-technology',
        title: 'Information collected via technology',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Information collected via technology</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>To make Move2NewCity and Services more useful to you, our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system, Internet Protocol (IP) address, domain name, and/or a date/time stamp for your visit.</li>' +
            '<li>Like many other website operators, we use cookies on our Site. Cookies are very small files which a website uses to identify you when you come back to the site, and which store details about your use of the site. Cookies are not malicious programs that access or damage your computer. We use cookies to improve the experience of Users and enable Members to access secure areas of our Site. You may choose to disable cookies by adjusting the privacy settings in your browser, but you may not be able to access some parts of our Site or Services if you do so.</li>' +
            '<li>We may also use or enable: third party analytics services to track and analyse anonymous data from Users of our Site and Services; and location-enabled services for Members as a means to facilitate better services based on the co-relationships of the requested services.</li>' +
            '<li>If, in future, you use the location-enabled services, Move2NewCity may receive information about your actual location (such as GPS signals sent by a mobile device or via your web browser when on the Site).</li>' +
            '</ul>' +
            '</ul>',
        ],
      },
      {
        id: 'invitation-emails',
        title: 'Invitation emails',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Invitation emails</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>Members and Visitors of Move2NewCity can invite other people to become Move2NewCity Members by requesting us to send invitation emails to them.</li>' +
            '<li>We store the email addresses that are provided to us and, if they are provided by a Move2NewCity Member, we will associate those email addresses with such Member’s account.</li>' +
            '<li>We will only send one invitation and will not use the email address for any other communication unless the person becomes a member of Move2NewCity.</li>' +
            '<li>We do not currently sell these email addresses or use them for any other purpose.</li>' +
            '<li>Where you provide someone else’s email address to us for the purposes of sending them an invitation email, you must first obtain the person’s consent and ensure they agree to receive an invitation email from us.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },

      {
        id: 'information-from-others',
        title: 'Information collected from others about you',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Information collected from others about you</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>Other Members may provide us Personal Information about you through our Site or the Services. Examples include a Member providing your email address to send you an invitation to become a Member.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },
      {
        id: 'information-from-third-parties',
        title: 'Information collected from third parties',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Information collected from third parties</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>We may receive Personal Information and/or anonymous data about you from companies that assist Move2NewCity in providing the Services, such as service providers who assist with identifying you or verifying information provided by you (e.g., Reference check to verify a lived experience), or companies that offer their products and/or services on our Site.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },
      {
        id: 'unsolicited-information',
        title: 'Unsolicited Information',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Unsolicited Information</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>Where we collect unsolicited information (being information provided to us without us having sought the information), we will only hold, use and disclose that information if we could otherwise do so had we collected it by the means described above. If that unsolicited information could not have been collected by the means described above, then we will destroy, permanently delete or de-identify the information as appropriate.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },
    ],
  },
  {
    id: 'use-of-personal-information',
    mainTitle: 'Use of Your Personal Information',
    subTitles: [
      {
        id: 'general-use',
        title: 'General use',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>General use</b></li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>In general, we use the Personal Information we collect either to provide the Services to you, to respond to requests that you make, or to aid us in serving you better, including in the following ways:</li>' +
            '<li>to facilitate the creation of, and; to send you a welcome e-mail and to verify ownership of the e-mail address provided during Registration;</li>' +
            '<li>to identify you as a Newcomer or a Friend in our system;</li>' +
            '<li>to prompt you to set up your profile after you have Registered;</li>' +
            '<li>to verify your credentials;</li>' +
            '<li>to provide improved administration of our Site and Services;</li>' +
            '<li>to provide the Services you request;</li>' +
            '<li>to improve the quality of experience when you interact with our Site and Services;</li>' +
            '<li>to tailor the features, performance and support of the Site or Services to you;</li>' +
            '<li>to send you administrative e-mail notifications, such as security or support and maintenance advisories, or to inform you when our User Agreement or any of our policies (including this Policy) have been updated;</li>' +
            '<li>to respond to your inquiries or requests and/or troubleshoot problems;</li>' +
            '<li>to send newsletters, surveys, and other promotional materials related to our Services and for other Move2NewCity marketing purposes;</li>' +
            '<li>to customize the advertising you may see on the Site or through the Services;</li>' +
            '<li>to collect and/or distribute rewards; and</li>' +
            '<li>to verify your compliance with your obligations in our User Agreement or other Move2NewCity policies.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },
      {
        id: 'creation-of-Anonymous-Data',
        title: 'Creation of Anonymous Data',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li><b>Creation of Anonymous Data</b></li>' +
            '<p>We may create anonymous data records from Personal Information by excluding information (such as your name and/or email) that makes the data personally identifiable to you. We use this anonymous data to analyse request and usage patterns so that we may enhance the content of our Services and improve Site navigation. Move2NewCity may use anonymous data for any purpose and may disclose anonymous data to third parties as it sees fit.</p>' +
            '</ul>' +
            '</ul>',
        ],
      },
    ],
  },
  {
    id: 'disclosure-of-personal-information',
    mainTitle: 'Disclosure of Your Personal Information',
    description: [
      {
        topic:
          'We may disclose your Personal Information as described below and as described elsewhere in this Policy.',
      },
      {
        id: 'disclosure-to-others',
        topic: '<b>Disclosure to other Users</b>',
        isSubtitle: true,
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>We may share your Personal Information with our Affiliates to provide you the required services from Move2NewCity.</li>' +
            '<li>We do not disclose the Personal Information of any Member to any other Member. We do, however, provide other Members with the opportunity to communicate with you through your Move2NewCity display name (identity/handle). This display name will be visible to all the users of Move2NewCity. It is entirely up to you, whether you want the actual first name, last name, or both to be a part of this display name.</li>' +
            '<li>Members are to upload an avatar as a part of your profile. Again, it is entirely up to you whether you want to have your photograph or an alternative avatar. In either case, this will be visible to all the Users of Move2NewCity.</li>' +
            '</ul>',
        ],
      },

      {
        id: 'external-service-providers',
        topic: '<b>External service providers and other third parties</b>',
        isSubtitle: true,
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>We may share your Personal Information with our Affiliates to provide you with the Services that we offer you through our Site; conduct quality assurance testing; facilitate creation of accounts; and provide technical support.</li>' +
            '<li>Examples of such third party service providers include companies that provide communication services and providers that verify reference checks of your lived experiences, if you are a Friend, and companies that process payments from both Friends and Newcomers if you use paid services.</li>' +
            '<li>We may also disclose your Personal Information to our external service providers and advisers (Affiliates) who have been engaged to provide us with legal, administrative, financial, accounting, auditing, insurance, research, marketing, business consulting, technology, support or other services.</li>' +
            '<li>In addition, we may disclose your Personal Information to other external service providers, consultants, or business partners of ours (Affiliates) who may work with us to improve or develop our Site and Services, operate or develop our business, conduct surveys or seek feedback from you, conduct market and other research, or facilitate or collaborate with us in relation to promotions; and providers of consumer review websites or platforms, to enable those providers to send you an invitation to leave a review of our Services.</li>' +
            '<li>We take reasonable steps to ensure our Affiliates, these third party service providers, advisers, etc., do not use your Personal Information other than for the purpose for which it was provided to them by Move2NewCity.</li>' +
            '</ul>',
        ],
      },
      {
        id: 'direct-marketing',
        topic: '<b>Direct Marketing</b>',
        isSubtitle: true,
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>As indicated above, Move2NewCity may use information collected from or about you for the purpose of sending you marketing and promotional information and materials.</li>' +
            '<li>If you wish to opt out of receiving direct marketing communications from Move2NewCity, please use the “unsubscribe” option (in the case of e-mail communications) or contact us directly using the details below. If you wish to opt-out of receiving direct marketing communications from third party organizations to whom we have disclosed your Personal Information, you may make a separate request to that organization.</li>' +
            '</ul>',
        ],
      },
      {
        id: 'marketing-on-third-party-sites',
        topic: '<b>Marketing on third party sites</b>',
        isSubtitle: true,
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>We may disclose your Personal Information, such as your email address, to the operators of other websites, social media platforms and search engines, so that we may deliver tailored advertisements to you via those websites, platforms and search engines.</li>' +
            '</ul>',
        ],
      },
      {
        id: 'affiliates-and-acquisitions',
        topic: '<b>Affiliates and acquisitions</b>',
        isSubtitle: true,
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>We may share some or all of your Personal Information with our Affiliates, in which case we will require our Affiliates to honour this Policy unless they have their own privacy policy (in which case, they will act in accordance with their own policy).</li>' +
            '<li>If another company acquires the shares in Move2NewCity, the business, or our assets, that company will possess the Personal Information collected by us and will assume the rights and obligations regarding your Personal Information as described in this Policy.</li>' +
            '</ul>',
        ],
      },
      {
        id: 'personal-information-disclosures',
        topic: '<b>Disclosure of Personal Information to overseas entities</b>',
        isSubtitle: true,
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>We may transfer or otherwise disclose your Personal Information to third parties located outside Australia, including to third parties who provide information technology and other services to us. Those third parties may be located in the United States or other countries. By providing Personal Information to us, you consent to the transfer of that Personal Information to entities located outside Australia.</li>' +
            '</ul>',
        ],
      },
      {
        id: 'other-disclosures',
        topic: '<b>Other disclosures</b>',
        isSubtitle: true,
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>Regardless of any choices you make regarding your Personal Information (as described in section 9 below), Move2NewCity may disclose Personal Information to third parties if it believes in good faith that such disclosure is necessary:</li>' +
            '<ul class="list-disc list-outside ml-6">' +
            '<li>in connection with any legal investigation;</li>' +
            '<li>to comply with relevant laws, or to respond to subpoenas or warrants served on us;</li>' +
            '<li>to lessen or prevent a serious threat to the life, health or safety of an individual or to public safety;</li>' +
            '<li>to investigate or assist in preventing any violation or potential violation of the law;</li>' +
            '<li>where another “permitted general situation” or “permitted health situation” (as defined in the Privacy Act) applies; and/or where disclosure is reasonably necessary for a law enforcement related activity.</li>' +
            '</ul>' +
            '</ul>',
        ],
      },
    ],
  },
  {
    id: 'data-collection',
    mainTitle: 'Data collection',
    subTitles: [
      {
        id: 'data-collection-by-ad-networks',
        title:
          '<ul class="list-disc list-outside ml-6"><li><b>Information collected by ad networks</b></li>',
        text: 'We may use third-party advertising companies to serve ads when you visit our Site. These companies may collect and use information (not including your name or email address) about your visits to the Site and other websites in order to provide advertisements about goods and services to you that are tailored to your interests. This information is collected using cookies. As indicated above, you can disable cookies by changing your browser settings, but if you do, some parts of our Site may not function properly.',
      },
      {
        id: 'data-collection-by-third-parties',
        title:
          '<ul class="list-disc list-outside ml-6"><li><b>Information collected by other third parties</b></li>',
        text: 'This Policy addresses only our use and disclosure of Personal Information we collect from and/or about you. If you disclose information to others, including to other Members, those persons or organizations will not be bound to act in accordance with this Policy. We do not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable.',
      },
    ],
  },
  {
    id: 'choices-regarding-personal-data',
    mainTitle: 'Your choices regarding your personal data',
    description: [
      {
        topic:
          'Unless you become a Member, Move2NewCity does not collect information that identifies you personally, unless otherwise you have opted in to provide such information as a User, for instance during social media engagement activities.',
      },
    ],
    subTitles: [
      {
        id: 'choices-about-marketing',
        title:
          '<ul class="list-disc list-outside ml-6"><li><b>Choices about marketing</b></li>',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>We offer you choices regarding the collection, use, and sharing of your Personal Information.</li>' +
            '<li>We will periodically send you free newsletters and e-mails, or other marketing communications that directly promote the use of our Site or Services (unless you have previously requested not to receive such communications from us). When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive, or by contacting us directly. Our electronic marketing activities will comply with the requirements of the Spam Act 2003 (Cth).</li>' +
            '<li>Although you request not to receive direct marketing communications from us, we may send you Service related communications, including notices of any updates to our User Agreement or this Privacy Policy.</li>' +
            '</ul>',
        ],
      },
      {
        id: 'deletion-personal-information',
        title:
          '<ul class="list-disc list-outside ml-6"><li><b>Deletion of Personal Information</b></li>',
        content: [
          '<ul class="list-disc list-outside ml-6">' +
            '<li>You may request deletion of your Personal Information by us, but please note that we may be required to keep this information and not delete it (or to keep this information for a certain time, in which case we will comply with your deletion request only after we have fulfilled such requirements).</li>' +
            '<li>If we no longer need your Personal Information for any purpose for which it may be used or disclosed under the Privacy Act, and the information is not otherwise required to be kept under an Australian law or court order, we will take reasonable steps to destroy or permanently de-identify the information.</li>' +
            '<li>When we delete any information, it will be deleted from the active database, but may remain in our archives. We may retain your information for fraud protection or similar purposes if this is permitted pursuant to an Australian law or court order.</li>' +
            '<li>If we have already disclosed some of your Personal Information to third parties, we cannot force the deletion or modification of any such information by the parties to whom we have made those disclosures.</li>' +
            '</ul>',
        ],
      },
    ],
  },
  {
    id: 'personal-information-security',
    mainTitle: 'Security of your Personal Information',
    points: [
      '<ul class="list-disc list-outside ml-6">' +
        '<li>Move2NewCity is committed to protecting the security of your Personal Information. As a cloud native platform, we use a variety of industry-standard security technologies and procedures to help protect your Personal Information from unauthorized access, use, and disclosure.</li>' +
        '<li>Any information which we hold about you is stored on secure servers that are protected in controlled facilities and accessing these details by you will always be authenticated.</li>' +
        '<li>No method of transmission over the Internet, or method of electronic storage, is 100% secure, so while Move2NewCity uses reasonable efforts to protect your Personal Information, we cannot guarantee its absolute security.</li>' +
        '<li>In addition, our employees and contractors who provide services related to our information systems are obliged to respect the confidentiality and privacy of any Personal Information held by Move2NewCity.</li>' +
        '</ul>',
    ],
  },
  {
    id: 'personal-information-access',
    mainTitle: 'Access to your Personal Information',
    points: [
      '<ul class="list-disc list-outside ml-6">' +
        '<li>You can request access to the Personal Information we hold about you by contacting us using the details below.</li>' +
        '<li>We will generally allow you to access the Personal Information we hold about you if you request it, but we may refuse access if we are permitted or required by law to do so.</li>' +
        '<li>We will process requests for access to Personal Information within a reasonable time, and we may charge you reasonable costs associated with providing access (for example, to cover the costs of verifying the request and retrieving the information).</li>' +
        '<li>If we refuse to provide you with access to your Personal Information, we will generally provide reasons for the refusal.</li>' +
        '</ul>',
    ],
  },
  {
    id: 'personal-information-correction',
    mainTitle: 'Correction of your Personal Information',
    points: [
      '<ul class="list-disc list-outside ml-6">' +
        '<li>We take reasonable steps to ensure the Personal Information we hold about you is accurate, complete and up to date.</li>' +
        '<li>Please let us know if you think any of the information we hold about you is incorrect, incomplete or out of date. You may also change or update your Personal Information by logging into your Move2NewCity and editing your Profile.</li>' +
        '</ul>',
    ],
  },
  {
    id: 'complaints',
    mainTitle: 'Complaints',
    points: [
      '<ul class="list-disc list-outside ml-6">' +
        '<li>If you have any complaints about our dealings with your Personal Information, including any breaches by us of any Australian Privacy Principles, you are able to submit that complaint by contacting us using the details below. You will need to provide sufficient details regarding your complaint as well as any supporting evidence and information.</li>' +
        '<li>Complaints will be referred for investigation by our Privacy Officer and a response will be provided to you within a reasonable time (usually no longer than 30 days). We may seek further information from you in order to provide you with a full and complete response.</li>' +
        '<li>If you are not satisfied with the outcome of our investigation, you can contact us to raise your concerns, or you may wish to contact the Office of the Australian Information Commissioner.</li>' +
        '</ul>',
    ],
  },
  {
    id: 'privacy-policy-changes',
    mainTitle: 'Changes to this Privacy Policy',
    points: [
      '<ul class="list-disc list-outside ml-6">' +
        '<li>We may update this Policy from time to time. We will post the updated Policy on our Site, so please check regularly for any updates. The updated Policy will take effect from the date of posting.</li>' +
        '<li>our continued use of our Site or Services, or provision of further Personal Information to us after this Policy has been updated, will constitute your acceptance of the amended Policy.</li>' +
        '</ul>',
    ],
  },
  {
    id: 'contact-information',
    mainTitle: 'Contact Information',
    points: [
      '<ul class="list-disc list-outside ml-6">' +
        '<li>Move2NewCity welcomes your comments or questions regarding the Site, our Services, or this Policy.</li>' +
        '<li>Please contact us using the following details: <a class="text-primary" href="mailto: privacy@move2newcity.com">Email: privacy@move2newcity.com</a></li>' +
        '<li>Privacy Policy last updated on 26 January 2022.</li>' +
        '</ul>',
    ],
  },
];

export default termAndPolicyData;
