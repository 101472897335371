import { FC, useCallback, useEffect } from 'react';

import { AxiosError } from 'axios';
import Cookies from 'universal-cookie';
import { AUTH_ERROR } from 'constants/Common';
import { removeCookies } from 'utils/cookieUtil';
import apiClient from 'api/apiClient';

type LayoutWrapperProps = {
  children: any;
};

const cookies = new Cookies();

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
  const configureInterceptor = useCallback(() => {
    let startingTimer: NodeJS.Timeout;
    let loaderTimer: NodeJS.Timeout;
    apiClient.interceptors.response.use(
      (res) => {
        return res;
      },
      (error: AxiosError) => {
        const { response }: any = error;
        if (
          response?.status === 403 &&
          response?.data?.message === AUTH_ERROR
        ) {
          startingTimer = setTimeout(() => {
            loaderTimer = setTimeout(() => {
              removeCookies('accessToken');
            }, 3000);
          }, 1000);
        }
        return Promise.reject(error);
      }
    );

    apiClient.interceptors.request.use((req) => {
      const excludedEndpoints = [
        '/v1/auth/login',
        '/v1/auth/signUp',
        'v1/cities',
        'v1/categories',
      ];
      const token = cookies.get('accessToken');
      if (
        req.headers &&
        !excludedEndpoints.some((endpoint) => req.url?.includes(endpoint))
      ) {
        req.headers.authorization = `Bearer ${token}`;
      }
      return req;
    });

    return () => {
      clearTimeout(startingTimer);
      clearTimeout(loaderTimer);
    };
  }, []);

  useEffect(() => {
    configureInterceptor();
  }, [configureInterceptor]);

  return <>{children}</>;
};

export default LayoutWrapper;
