import { useQuery } from '@tanstack/react-query';
import { getAllCategories } from 'api/requests/categories';

export const useGetAllCategories = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const data = await getAllCategories();
      return data;
    },
  });
};
