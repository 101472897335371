import React from 'react';
import TextImgGrid from 'components/common/TextImageGrid';
import { AboutUsItem } from 'model/AboutUsItem';
import LaptopPlatform from 'assets/images/about-us/laptop-platform.png';
import RoadAhead from 'assets/images/about-us/road-ahead.png';
import Compass from 'assets/images/about-us/compass.png';

const aboutUsData: AboutUsItem[] = [
  {
    title: 'What is Move2NewCity',
    description: (
      <span>
        With Move2NewCity, you can find Friends who share lived experiences in
        the new city you are moving to. It is the starting point of the
        settlement process for you when moving to the new city. <b>Newcomers</b>{' '}
        can find <b>Friends</b> to gain knowledge and hear real stories to make
        their settlement experience seamless
      </span>
    ),
    image: LaptopPlatform,
  },
  {
    title: 'Our Mission',
    description: (
      <span>
        Our Mission is to provide people who move to new cities with knowledge,
        tips and information from individuals with lived experiences, in a
        convenient and efficient way.
      </span>
    ),
    image: RoadAhead,
  },
  {
    title: 'Our Vision',
    description: (
      <span>
        <b>Our Vision</b> is to be the single platform to connect people who
        move to a new city with the people with lived experiences in that city.
      </span>
    ),
    image: Compass,
  },
];

const AboutUs: React.FC = () => {
  return (
    <div className="container max-w-7xl flex flex-col justify-center mx-auto mt-14 md:mt-16">
      <div className="flex items-center justify-center mb-100">
        <div className="text-2xl md:text-3xl lg:text-4xl text-secondary font-semibold mb-16 md:mb-20">
          About Move2NewCity
        </div>
      </div>
      <TextImgGrid data={aboutUsData} />
    </div>
  );
};

export default AboutUs;
