import React, { useState, useEffect } from 'react';
import termAndPolicyData from 'components/terms-and-policy/TermsAndPolicydata';

const TermsAndPolicy: React.FC = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const createMarkup = (htmlContent: string) => {
    return { __html: htmlContent };
  };

  const extractTitle = (htmlString: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  const renderTableOfContents = () => {
    return (
      <div className="table-of-contents">
        <h3 className="font-semibold sm:text-lg lg:text-2xl">
          Table of Contents
        </h3>
        <div className="w-14 h-[3px] bg-primary mb-4" />
        <ul>
          {termAndPolicyData.map((section) => (
            <li key={section.id}>
              <div className="flex items-center mb-2 px-8">
                <div className="w-8 h-[3px] bg-primary mr-2" />
                <p>{section.mainTitle}</p>
              </div>
              {section.subTitles?.map((subTitle) => (
                <ul key={subTitle.id} className="ml-10">
                  <li className="flex items-center mb-1 px-8">
                    <div className="w-5 h-[3px] bg-primary mr-3" />
                    <p>{extractTitle(subTitle.title || '')}</p>
                  </li>
                </ul>
              ))}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="p-6 sm:py-10 md:py-20 container mx-auto max-w-7xl">
      <h1 className="text-xl md:text-2xl lg:text-5xl text-center font-medium mb-5">
        Privacy Policy
      </h1>
      <div className="text-center sm:text-base md:text-lg lg:text-xl mb-5">
        <span className="font-bold">Last Updated:</span> 26 January 2022
      </div>
      <h3 className="font-semibold sm:text-lg lg:text-2xl">INTRODUCTION</h3>
      <div className="w-14 h-[3px] bg-primary mb-4" />
      <div className="mb-8 text-sm md:text-base">
        Welcome to Move2NewCity! The privacy of the users who visit and use
        services provided by Move2NewCity are taken very seriously, and
        Move2NewCity is committed to protecting the personal information
        provided by the website users. Please read the following privacy policy
        to learn more about how Move2NewCity uses and protects your personal
        data and information.
      </div>
      <div className="mb-8 text-sm md:text-base">{renderTableOfContents()}</div>
      <ol className="pl-3 sm:pl-6">
        {termAndPolicyData.map((section, index) => (
          <li key={section.id} className="mb-6">
            <h2 className="text-lg lg:text-2xl font-semibold mb-4">
              {index + 1}. {section.mainTitle}
            </h2>
            <div className="w-14 h-[3px] bg-primary mb-4" />
            {section.points &&
              section.points.map((point, pointIndex) => (
                <div
                  key={pointIndex}
                  dangerouslySetInnerHTML={createMarkup(point)}
                  className="mb-4"
                ></div>
              ))}

            <ol className="pl-3 sm:pl-4">
              {section.description?.map((item, subIndex) => (
                <li key={subIndex} className="mb-2">
                  <p
                    className="text-sm md:text-base"
                    dangerouslySetInnerHTML={createMarkup(
                      `${index + 1}.${subIndex + 1} ${item.topic}`
                    )}
                  ></p>
                  <ul className="pl-3 sm:pl-4">
                    {item.content?.map((contentItem, contentIndex) => (
                      <li key={contentIndex} className="mb-2">
                        <p
                          className="text-sm md:text-base"
                          dangerouslySetInnerHTML={createMarkup(contentItem)}
                        ></p>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              {section.data && (
                <li className="mb-2">
                  <p
                    className="text-base"
                    dangerouslySetInnerHTML={createMarkup(section.data)}
                  ></p>
                </li>
              )}
              {section.subTitles?.map((subTitle, subTitleIndex) => (
                <li key={subTitleIndex} className="mb-2">
                  {subTitle.content?.map((contentItem, contentIndex) => {
                    if (typeof contentItem === 'string') {
                      return (
                        <p
                          key={contentIndex}
                          className="text-base"
                          dangerouslySetInnerHTML={createMarkup(contentItem)}
                        ></p>
                      );
                    } else {
                      return (
                        <div key={contentIndex} className="text-base">
                          {contentItem}
                        </div>
                      );
                    }
                  })}
                  {subTitle.text && (
                    <p
                      className="text-base"
                      dangerouslySetInnerHTML={createMarkup(subTitle.text)}
                    ></p>
                  )}
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TermsAndPolicy;
