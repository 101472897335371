import { useState } from 'react';
import CategoryButton from 'components/category/CategoryButton';
import { friendsList } from 'components/rating-profile/dummy';
import UserProfileCard from 'components/rating-profile/RatingProfileStatus';
import SearchBar from 'components/search-bar/SearchBar';
import SortDropdown from 'components/sort-dropdown/SortDropdown';
import { FriendFilterOptions } from 'constants/SortOptions';
import { generateOptionsFromEnum } from 'utils/generateOptions';
import FriendsLoadingBox from 'components/friends-loading/FriendsLoading';
import Pagination from 'components/common/pagination';

type CityData = {
  data: {
    name: string;
  };
};

const FriendList: React.FC = () => {
  const cityData: CityData = {
    data: { name: 'Melbourne' },
  };
  const [currentPage, setCurrentPage] = useState<number>(1);

  const categories = [
    { tag: 'education', title: 'Education' },
    { tag: 'employment', title: 'Employment' },
    { tag: 'healthcare', title: 'Healthcare' },
    { tag: 'accommodation', title: 'Accommodation' },
    { tag: 'community', title: 'Community' },
  ];
  const handleOnSearch = (query: string) => {
    // Implement your search functionality here
  };

  const [selectedSortOption, setSelectedSortOption] = useState<
    string | undefined
  >(undefined);

  const handleThreadSortChange = (value: string) => {
    setSelectedSortOption(value); // Update state when dropdown value changes
  };

  const totalPages = 10;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-7xl mx-auto mt-8 relative px-6 lg:px-8">
      <div className="flex justify-between items-center">
        <div className="md:text-[18px] font-semibold text-secondary">
          Friends from {cityData?.data.name}
        </div>
        <SearchBar
          onSearch={handleOnSearch}
          placeholder={'Search Friends'}
          customStyles="md:w-[250px] sm:w-[200px] !sm:mt-0"
        />
      </div>
      <div className="text-[14px] font-semibold text-secondary mt-4">
        Categories
      </div>
      <div className="mb-10 mt-2 flex lg:flex-wrap gap-6 text-xs overflow-x-auto">
        {categories.map((category, index) => (
          <CategoryButton
            key={index}
            label={category.title}
            isActive={false}
            onClick={() => {}}
            customStyle="flex-shrink-0 capitalize mb-4"
          />
        ))}
      </div>
      <div className="flex items-center">
        <div className="flex items-center">
          <div className="text-[12px] text-secondary mr-2">Sort By :</div>
          <SortDropdown
            options={generateOptionsFromEnum(FriendFilterOptions)}
            value={selectedSortOption}
            onChange={(value) => {
              handleThreadSortChange(value);
            }}
          />
        </div>
      </div>
      <div className="my-6">
        <div className="text-[12px] text-secondary mb-6">
          Showing 10 Friends from {cityData?.data.name}
        </div>
        <div className="flex flex-wrap mt-2 gap-4">
          {friendsList.length === 0 ? (
            <FriendsLoadingBox />
          ) : (
            friendsList.slice(0, 5).map((profile, index) => (
              <UserProfileCard
                key={profile._id} // Use unique ID for the key
                username={profile?.username}
                profilePictureUrl={profile.profilePictureUrl}
                userId={profile._id}
              />
            ))
          )}
        </div>
        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
export default FriendList;
