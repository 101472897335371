import React from 'react';
import search from 'assets/images/friends-loading/searchIcon.gif';

const FriendsLoadingBox: React.FC = () => {
  return (
    <div className="bg-white mb-4 p-0 sm:p-10 w-full lg:h-screen flex lg:flex-col items-center justify-center sm:rounded-lg">
      <div className="relative w-24 h-24 mb-4 sm:mb-0">
        <img src={search} alt="Search Icon" className="rounded-full" />
      </div>
      <p className="text-textFieldBlue text-center text-[14px] font-medium">
        We are yet to find Friends in this city
      </p>
    </div>
  );
};

export default FriendsLoadingBox;
