import React, { useState } from 'react';
import CustomButton from 'components/common/Button';
import Input from 'components/common/Input';
import EmailIcon from 'assets/images/auth/emailIcon.png';

type ForgotPasswordContentProps = {
  switchToSignIn: () => void;
};

const ForgotPasswordContent: React.FC<ForgotPasswordContentProps> = ({
  switchToSignIn,
}) => {
  const [email, setEmail] = useState('');

  const handleForgotPassword = () => {
    // Handle forgot password logic
    console.log('Forgot password for email:', email);
  };

  return (
    <div className="px-4 md:px-52">
      <div className="flex flex-col items-center">
        <div className="flex justify-center">
          <img src={EmailIcon} alt="email" className="mr-2 cursor-pointer" />
        </div>
        <p className="text-2xl text-secondary font-medium mb-6">
          Forgot your password?
        </p>
        <p className="text-xs text-center text-secondary">
          Enter the email address associated with your account to receive
          password reset instruction.
        </p>
      </div>

      <form className="my-10">
        <div className="mb-4">
          <Input
            label="Email*"
            name="email"
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <CustomButton
          label="Send"
          variant="primary"
          customStyle="w-full mt-5"
          onClick={handleForgotPassword}
        />
        <div className="mt-4 text-left flex items-center">
          <button
            className="text-primary text-sm ml-2"
            onClick={switchToSignIn}
          >
            Back to Sign In
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordContent;
