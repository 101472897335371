import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

type CalendarProps = {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  className?: string;
};

const Calendar: React.FC<CalendarProps> = ({
  selectedDate,
  onDateChange,
  className,
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const daysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const startDayOfMonth = (month: number, year: number) => {
    return new Date(year, month, 1).getDay();
  };

  const generateCalendar = () => {
    const days = [];
    const month = currentMonth.getMonth();
    const year = currentMonth.getFullYear();
    const daysInCurrentMonth = daysInMonth(month, year);
    const startDay = startDayOfMonth(month, year);

    for (let i = 0; i < startDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInCurrentMonth; i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() - 1))
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + 1))
    );
  };

  const handleDateClick = (date: Date | null) => {
    if (date) {
      onDateChange(date);
    }
  };

  return (
    <div className={`calendar ${className}`}>
      <div className="flex justify-between items-center mb-4 text-sm font-semibold text-secondary">
        <button onClick={handlePrevMonth} className="p-2">
          <ChevronLeftIcon className="h-4 w-4 text-secondary" />
        </button>
        <div>
          {currentMonth.toLocaleString('default', { month: 'long' })}{' '}
          {currentMonth.getFullYear()}
        </div>
        <button onClick={handleNextMonth} className="p-2">
          <ChevronRightIcon className="h-4 w-4 text-secondary" />
        </button>
      </div>
      <div className="my-4 border-b border-b-grayBorderColor"></div>
      <div className="grid grid-cols-7 justify-items-center  gap-2 gap-y-4 text-grayBorderColor">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-text font-medium text-xs">
            {day}
          </div>
        ))}
        {generateCalendar().map((date, index) => (
          <div
            key={index}
            className={`text-center p-2 rounded-full cursor-pointer font-medium text-sm w-6 h-6 flex justify-center items-center text-secondary ${
              date && date.toDateString() === selectedDate.toDateString()
                ? 'bg-blue-500 text-white'
                : 'hover:bg-blue-200'
            }`}
            onClick={() => handleDateClick(date)}
          >
            {date ? date.getDate() : ''}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
