import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import SocialMediaShare from 'components/blog/SocialShare';

const PreviewPage: React.FC = () => {
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState<any>(null);

  useEffect(() => {
    const data = sessionStorage.getItem('previewData');

    if (data) {
      setPreviewData(JSON.parse(data));
    } else {
      navigate('/add-new-article');
    }
  }, [navigate]);

  if (!previewData) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="font-semibold text-3xl text-primary">No Preview Data</p>
      </div>
    );
  }

  const { title, category, content, image, date } = previewData;

  const calculateReadingTime = (content: string): string => {
    const wordsPerMinute = 200;
    const text = content.replace(/<[^>]+>/g, '');
    const wordCount = text.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    return `${readingTime} min read`;
  };

  const readingTime = calculateReadingTime(content);

  return (
    <div>
      <div className="bg-blogBg h-full min-h-screen">
        <div className="relative w-full h-[600px] max-w-full overflow-hidden shadow-lg">
          <img
            className="w-full h-[600px] object-cover"
            src={image}
            alt={title}
          />
          <div className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center text-white">
            <div className="flex flex-col justify-center items-center text-center px-6 py-4 flex-wrap">
              <div className="flex justify-center mb-6 space-x-2">
                <div className="bg-overlay text-white text-[10px] rounded-full px-3 py-1 uppercase w-min">
                  {category}
                </div>
              </div>
              <div className="text-2xl font-bold mb-4">{title}</div>
              <div className="text-xs mb-4">{date}</div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl container mx-auto px-6 lg:px-4 xl:px-0 mt-12 md:mt-20">
          <div className="flex items-center mb-8">
            <button
              className="flex items-center text-sm text-primary hover:text-secondary transition-colors"
              onClick={() => navigate(-1)}
            >
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              Go Back
            </button>
          </div>
          <div className="flex items-center space-x-2 mb-6">
            <div className="text-xs font-semibold text-secondary mb-2">
              {date}
            </div>
            <hr className="border-b-[1px] border-b-primary w-10" />
            <div>
              <div className="text-xs font-semibold text-secondary mb-2">
                {readingTime}
              </div>
            </div>
          </div>
          <div className="mb-6">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className="flex justify-start mb-6 space-x-2">
            <div className="bg-overlay border-grayBorderColor border-[1px] text-borderline font-semibold text-[10px] rounded-md px-3 py-1 uppercase w-min">
              {category}
            </div>
          </div>
          <hr className="border-b-[1px] border-b-grayBorderColor w-full" />
          <div className="py-16 flex justify-end">
            <SocialMediaShare url={window.location.href} title={title} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPage;
