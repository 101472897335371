import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomButton from 'components/common/Button';
import CustomCheckbox from 'components/common/Checkbox';
import Input from 'components/common/Input';
import PasswordInput from 'components/common/PasswordInput';
import { LoginDetails } from 'model/Auth';
import { useSignIn } from 'api/mutations/auth';
import { setCookies } from 'utils/cookieUtil';
import { NotifySeverity } from 'components/common/Notification';
import notify from 'components/common/Notification';
import { useUser } from 'api/context/UserContext';

type SignInContentProps = {
  switchToSignUp: () => void;
  switchToForgotPassword: () => void;
  onLoginSuccess: () => void;
};

const SignInContent: React.FC<SignInContentProps> = ({
  switchToSignUp,
  switchToForgotPassword,
  onLoginSuccess,
}) => {
  const { mutateAsync: signIn } = useSignIn();
  const { refetch } = useUser();
  //const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleLogin = async (values: LoginDetails) => {
    try {
      const result: any = await signIn(values);
      if (result?.error) {
        notify({
          title: 'Error Logging In',
          content: result.error,
          severity: NotifySeverity.ERROR,
        });
      } else {
        formik.resetForm();
        setCookies('accessToken', result?.data?.token, {
          path: '/',
        });
        refetch();
        notify({
          title: 'User Logged Successfully',
          content: '',
          severity: NotifySeverity.SUCCESS,
        });
        onLoginSuccess();
      }
    } catch (error) {
      console.error('Error:', error);
      notify({
        title: 'Error Logging In',
        content: 'An unexpected error occurred',
        severity: NotifySeverity.ERROR,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email('Invalid email address')
        .required('Email is required.'),
      password: Yup.string()
        .trim()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required.'),
    }),
    onSubmit: (values: any) => {
      handleLogin({
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      });
    },
  });
  return (
    <div className=" px-4 md:px-52">
      <form onSubmit={formik.handleSubmit} className="my-10">
        <div className="mb-4">
          <Input
            label="Email*"
            name="email"
            type="email"
            placeholder="Enter your email address"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {formik.touched.email &&
            formik.errors.email &&
            typeof formik.errors.email === 'string' && (
              <p className="text-errorMessageColor -mt-4 text-xs">
                {formik.errors.email}
              </p>
            )}
        </div>
        <div className="mb-4">
          <PasswordInput
            label="Password*"
            name="password"
            placeholder="Enter your password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {formik.touched.password &&
            formik.errors.password &&
            typeof formik.errors.password === 'string' && (
              <p className="text-errorMessageColor text-xs -mt-4">
                {formik.errors.password}
              </p>
            )}
        </div>
        <div className="flex justify-between items-center">
          <CustomCheckbox
            //checked={isChecked}
            label="Remember me"
            name="remeberMe"
          />
          <div className="text-right">
            <button
              type="button"
              className="text-secondary text-sm font-medium"
              onClick={switchToForgotPassword}
            >
              Forgot password?
            </button>
          </div>
        </div>
        <CustomButton
          type="submit"
          label="Sign In"
          variant="primary"
          customStyle="w-full mt-5"
        />
        <div className="mt-4 text-left flex items-center">
          <span className="text-gray-700 text-sm"> New User? </span>
          <button
            type="button"
            className="text-primary text-sm ml-2"
            onClick={switchToSignUp}
          >
            Sign Up Here
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignInContent;
