import { useState, ChangeEvent, FC } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { City } from 'model/City';
import { useNavigate } from 'react-router-dom';

type DropdownSearchBarProps = {
  cities?: City[];
  height: string;
  buttonStyle: string;
  inputStyles: string;
  placeHolder: string;
  dropdownStyle?: string;
};

const DropdownSearchBar: FC<DropdownSearchBarProps> = ({
  cities,
  height,
  buttonStyle,
  inputStyles,
  placeHolder,
  dropdownStyle,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [results, setResults] = useState<City[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (value) {
      const filteredCities =
        cities?.filter((city: City) =>
          city.name.toLowerCase().includes(value)
        ) || [];
      setResults(filteredCities);
      setDropdownOpen(true);
    } else {
      setResults([]);
      setDropdownOpen(false);
    }
  };

  const navigate = useNavigate();

  const handleSelect = (cityId: string) => {
    navigate(`/city/${cityId}`);
    setDropdownOpen(false); // Close the dropdown
  };

  return (
    <div className="relative w-full">
      <div className="relative flex items-center justify-center">
        <input
          type="text"
          placeholder={placeHolder}
          className={`w-full px-4 py-2 pr-10 border rounded-full focus:outline-none focus:border-categoryActive ${inputStyles}`}
          style={{ height }}
          value={searchTerm}
          onChange={handleSearch}
        />
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
          <div className={`flex items-center justify-center ${buttonStyle}`}>
            <MagnifyingGlassIcon className="w-6 h-6" />
          </div>
        </div>
        {dropdownOpen && results?.length > 0 && (
          <div
            className={`absolute z-10 w-10/12 bg-white border border-t-0 rounded-lg rounded-t-none h-fit ${dropdownStyle}`}
            style={{
              top: `calc(${height} + 0.01rem)`,
            }}
          >
            <div className="flex flex-wrap p-3 gap-2">
              {results.map((city: City) => (
                <div
                  key={city.id}
                  className="px-4 bg-white rounded-full border border-grayBorderColor hover:border-categoryActive duration-300 text-cityTableHeaderText text-xs cursor-pointer h-[28px] flex justify-center items-center text-primaryTextColor"
                  onClick={() => handleSelect(city.id)}
                >
                  {city.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownSearchBar;
