import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  UserGroupIcon,
  UserIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import Logo from 'assets/images/logo.png';
import SignInModal from 'components/auth/Signin';
import { useLocation } from 'react-router-dom';
import DropdownSearchBar from 'components/home/SearchButtonDropdown';
import Loader from 'components/common/Loader';
import { useUser } from 'api/context/UserContext';
import UserMenu from './UserMenu';
import Cookies from 'universal-cookie';
import { useGetAllCities } from 'api/queries/cities';

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  { name: 'Become a Friend', href: '/become-a-friend', icon: UserGroupIcon },
  { name: 'Blog', href: '/blog', icon: DocumentTextIcon },
];

export default function RightAlignedNav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isSignInOpen, setSignInOpen] = useState<boolean>(false);
  const { data: cities } = useGetAllCities();
  const { user, isLoading } = useUser();
  const location = useLocation();

  if (isLoading) {
    return <Loader />;
  }

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('accessToken', { path: '/' });
    window.location.reload();
  };

  const isLoggedIn = !!user;
  const isHomePage = location.pathname === '/';

  return (
    <header className="bg-secondary">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between  py-4 px-6 lg:px-8 lg:py-6"
      >
        <a href="/" className="-m-1.5 p-1.5 hidden lg:block">
          <span className="sr-only">Move2NewCity</span>
          <img alt="" src={Logo} className="h-10 w-auto" />
        </a>
        {!isHomePage && (
          <div className="hidden lg:flex  justify-center mx-6">
            <DropdownSearchBar
              cities={cities}
              height="40px"
              buttonStyle="text-gray-500"
              inputStyles="bg-white border-gray-300 text-sm "
              placeHolder="Search for a city"
              dropdownStyle="bg-white border-gray-300"
            />
          </div>
        )}
        <div className="flex lg:hidden items-center justify-between w-full">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
          >
            <Bars3Icon aria-hidden="true" className="h-8 w-8" />
          </button>
          <a href="/" className="mx-auto p-1.5">
            <span className="sr-only">Move2NewCity</span>
            <img alt="" src={Logo} className="h-8 w-auto" />
          </a>
          {isLoggedIn && <UserMenu handleLogout={handleLogout} user={user} />}
        </div>
        <div className="hidden lg:flex lg:items-center lg:gap-x-6">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-base leading-6 text-white hover:text-primary transition duration-300 flex items-center"
            >
              {item.name}
            </a>
          ))}
          {isLoggedIn ? (
            <UserMenu handleLogout={handleLogout} user={user} />
          ) : (
            <>
              <button
                onClick={() => setSignInOpen(true)}
                className="text-base leading-6 text-white hover:text-primary transition duration-300 flex items-center"
              >
                Sign In
              </button>
              <a
                href="/demo"
                className="text-base leading-6 text-white flex items-center justify-center bg-primary hover:bg-primaryHover transition duration-300 h-10 w-28 rounded-sm"
              >
                Demo
              </a>
            </>
          )}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-secondary px-6 py-6 sm:max-w-sm sm:ring-1 rounded-r-xxl sm:ring-gray-900/10 shadow-sm">
          <div className="flex items-center justify-between mb-20">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-white"
            >
              <XMarkIcon aria-hidden="true" className="h-8 w-8 text-white" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 rounded-lg px-3 py-2 text-base leading-7 text-white hover:text-primary transition duration-300 flex items-center"
                  >
                    <item.icon className="h-5 w-5 mr-2" aria-hidden="true" />
                    {item.name}
                  </a>
                ))}
                {!isLoggedIn && (
                  <>
                    <button
                      onClick={() => {
                        setMobileMenuOpen(false);
                        setSignInOpen(true);
                      }}
                      className="-mx-3 rounded-lg px-3 py-2 text-base leading-7 text-white hover:text-primary transition duration-300 flex items-center"
                    >
                      <UserIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                      Sign In
                    </button>
                    <a
                      href="/demo"
                      className="text-base leading-6 text-white flex items-center justify-center bg-primary hover:bg-primaryHover transition duration-300 h-10 w-full rounded-sm"
                    >
                      Demo
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
      {!isLoggedIn && (
        <SignInModal
          isOpen={isSignInOpen}
          onClose={() => setSignInOpen(false)}
        />
      )}
    </header>
  );
}
