import React, { useState, useEffect } from 'react';
import CustomPopupModal from 'components/custom-modal/Modal';
import CustomButton from 'components/common/Button';
import CustomSwitch from 'components/common/Switch';
import Calendar from 'components/common/Calendar';
import CustomDropdown from 'components/common/Dropdown';
import {
  addMinutesToTime,
  convert12ToTime,
  disableOptions,
  dropdownOptions,
  formatDateTime,
} from 'utils/availabilityUtils';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useUser } from 'api/context/UserContext';
import notify, { NotifySeverity } from 'components/common/Notification';
import { useOverrideAvailability } from 'api/mutations/availability';

export type CustomTimeRange = {
  start: string;
  end: string;
};
export type DateCategory = {
  date: string;
  category: { timeSlot: string; segmentId: string }[];
  isAvailable: boolean;
  availabilityId: string;
};

enum DaysOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

type AddDateOverrideModalProps = {
  visible: boolean;
  onHide: () => void;
  dateCategoryLines: DateCategory[];
};

const AddDateOverrideModal: React.FC<AddDateOverrideModalProps> = ({
  visible,
  onHide,
  dateCategoryLines,
}) => {
  const { user } = useUser();
  const [date, setDate] = useState<Date | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [timeRanges, setTimeRanges] = useState<CustomTimeRange[]>([
    { start: '09:00 AM', end: '05:30 PM' },
  ]);
  const [overlapError, setOverlapError] = useState<boolean[]>([]);
  const [isOverlap, setIsOverlap] = useState(false);
  const createOverrides = useOverrideAvailability();

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setDate(new Date());
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visible]);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setDate(date);
  };

  const checkForOverlap = (timeRanges: CustomTimeRange[]) => {
    const slots = timeRanges.map((slot) => ({
      start: new Date(`${slot.start}:00`).getTime(),
      end: new Date(`${slot.end}:00`).getTime(),
    }));

    const overlapFlags = new Array(slots.length).fill(false);
    let hasOverlap = false;

    for (let i = 0; i < slots.length; i++) {
      for (let j = 0; j < slots.length; j++) {
        if (
          i !== j &&
          slots[i].start < slots[j].end &&
          slots[j].start < slots[i].end
        ) {
          overlapFlags[i] = true;
          overlapFlags[j] = true;
          hasOverlap = true;
        }
      }
    }

    setOverlapError(overlapFlags);
    setIsOverlap(hasOverlap);
  };

  const handleTimeRangeChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges[index] = {
      ...newTimeRanges[index],
      [field]: value,
    };
    setTimeRanges(newTimeRanges);
    checkForOverlap(newTimeRanges);
  };

  const handleAddRow = () => {
    if (timeRanges.length < 3) {
      const lastEndTime = timeRanges[timeRanges.length - 1].end;
      const newStartTime = addMinutesToTime(lastEndTime, 30);
      const newEndTime = addMinutesToTime(newStartTime, 30);

      const newTimeRanges = [
        ...timeRanges,
        { start: newStartTime, end: newEndTime },
      ];

      checkForOverlap(newTimeRanges);
      setTimeRanges(newTimeRanges);
    }
  };

  const handleRemoveRow = (index: number) => {
    setTimeRanges((prevTimeRanges) =>
      prevTimeRanges.filter((_, i) => i !== index)
    );
    checkForOverlap(timeRanges.filter((_, i) => i !== index));
  };

  const handleApplyClick = () => {
    onHide();

    try {
      const segments = timeRanges.map((range) => {
        return {
          startTime: formatDateTime(date!, range.start, user?.timeZone),
          endTime: formatDateTime(date!, range.end, user?.timeZone),
        };
      });

      const isOverlapping = dateCategoryLines.some((dateCategoryLine) => {
        const lineSegments = dateCategoryLine.category.map((segment) => {
          const [start, end] = segment.timeSlot.split(' - ');
          return {
            startTime: formatDateTime(
              new Date(dateCategoryLine.date),
              start,
              user?.timeZone
            ),
            endTime: formatDateTime(
              new Date(dateCategoryLine.date),
              end,
              user?.timeZone
            ),
          };
        });

        return lineSegments.some((lineSegment) => {
          return segments.some((segment) => {
            return (
              (segment.startTime >= lineSegment.startTime &&
                segment.startTime <= lineSegment.endTime) ||
              (segment.endTime >= lineSegment.startTime &&
                segment.endTime <= lineSegment.endTime) ||
              (segment.startTime <= lineSegment.startTime &&
                segment.endTime >= lineSegment.endTime)
            );
          });
        });
      });

      if (isOverlapping) {
        notify({
          title: 'Error',
          content: 'Date overrides for this date already exists.',
          severity: NotifySeverity.ERROR,
        });
        return;
      }

      const dayOfWeek = new Date(date!)?.getDay();
      const generatedSegments = timeRanges.map((range) => {
        const convertedTime = convert12ToTime(range.start, range.end);
        const formattedStartTime = convertedTime?.startDate.toLocaleString(
          user?.timeZone,
          {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }
        );
        const formattedEndTime = convertedTime?.endDate.toLocaleString(
          user?.timeZone,
          {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          }
        );
        return {
          startTime: formattedStartTime,
          endTime: formattedEndTime,
        };
      });

      // TODO need to develop override api
      const payload = {
        userId: user?.id as string,
        availabilityRequest: {
          date: date!,
          segments: generatedSegments,
          available: checked,
          type: 'DAY',
          dayOfWeek: DaysOfWeek[dayOfWeek].toUpperCase(),
        },
      };

      createOverrides.mutate(payload, {
        onSuccess: (data) => {
          notify({
            title: 'Success!',
            content: 'Override Dates Applied successfully',
            severity: NotifySeverity.SUCCESS,
          });
        },
        onError: (error) => {
          notify({
            title: 'Error Creating Date Overrides',
            content: error?.toString(),
            severity: NotifySeverity.ERROR,
          });
        },
      });
    } catch (error) {
      notify({
        title: 'Error Creating Date Overrides',
        content: error?.toString(),
        severity: NotifySeverity.ERROR,
      });
    }
  };

  return (
    <CustomPopupModal
      isOpen={visible}
      onClose={onHide}
      modalStyles="max-w-4xl md:rounded-b-xl md:rounded-t-xxl min-h-screen md:min-h-0 md:mt-[120px]"
      fullScreenStyles="md:p-4 h-auto"
    >
      <CustomPopupModal.Header
        title={'Add date overrides'}
        className=" text-secondary pl-6 md:rounded-t-xl"
      />
      <CustomPopupModal.Content>
        <div className="flex flex-col md:flex-row bg-white gap-x-4 p-6 border-t border-calendarBorderColor md:h-[420px]">
          <div className="border-[1px] border-textFieldBlue rounded-lg overflow-auto md:w-1/2 max-h-[380px] custom-scrollbar">
            <div className="flex justify-content-center">
              <Calendar
                className="w-full p-4"
                selectedDate={selectedDate}
                onDateChange={handleDateChange}
              />
            </div>
          </div>
          <div className="md:max-h-[370px] bg-mainBgColor rounded-lg md:w-1/2 p-4 md:py-9 md:px-6 overflow-y-auto custom-scrollbar mt-6 md:mt-0">
            {!date ? (
              <div className="flex justify-center text-center m-20 font-medium text-sm text-secondary">
                Select the date(s) you want to assign specific hours
              </div>
            ) : (
              <>
                <div className="text-[14px] font-semibold text-secondary">
                  What hours are you available?
                </div>
                <div className="flex  mt-4">
                  <CustomSwitch
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <span className="ml-5 text-[14px] font-medium flex items-center">
                    <span>{checked ? 'Available' : 'Unavailable'}</span>
                  </span>
                </div>
                {checked ? (
                  <>
                    {timeRanges.map((timeRange, index) => (
                      <div
                        className="flex items-center mt-4 text-sm md:justify-between"
                        key={index}
                      >
                        <CustomDropdown
                          options={dropdownOptions.map((option) => ({
                            ...option,
                            disabled: disableOptions(
                              timeRange.end,
                              dropdownOptions,
                              true
                            ).includes(option.value),
                          }))}
                          placeholder={timeRange.start}
                          value={timeRange.start}
                          onChange={(e: any) =>
                            handleTimeRangeChange(index, 'start', e as string)
                          }
                          name=""
                          className="w-[120px]"
                          customMargin="!mb-0"
                          customPlaceHolder="!pl-3"
                        />
                        <span className="text-sm font-medium mx-2">to</span>
                        <CustomDropdown
                          options={dropdownOptions.map((option) => ({
                            ...option,
                            disabled: disableOptions(
                              timeRange.start,
                              dropdownOptions,
                              false
                            ).includes(option.value),
                          }))}
                          placeholder={timeRange.end}
                          value={timeRange.end}
                          onChange={(e: any) =>
                            handleTimeRangeChange(index, 'end', e as string)
                          }
                          name=""
                          customMargin="!mb-0"
                          className="w-[120px]"
                          customPlaceHolder="!pl-3"
                        />
                        {index === 0 && (
                          <div
                            className=" mx-2 text-white bg-textFieldBlue rounded-full p-2 cursor-pointer"
                            onClick={handleAddRow}
                          >
                            <PlusIcon className={`w-4 h-4`} />
                          </div>
                        )}
                        {index !== 0 && (
                          <div
                            className="mx-2 text-textFieldBlue bg-white rounded-full p-2 cursor-pointer border-[1px] border-textFieldBlue"
                            onClick={() => handleRemoveRow(index)}
                          >
                            <TrashIcon
                              className={`w-4 h-4 text-textFieldBlue`}
                            />
                          </div>
                        )}
                        {overlapError[index] && (
                          <div className="text-[10px] text-invalidDropdownRed mt-14 absolute">
                            Times overlap with another set of times
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex justify-center items-center text-center mt-20 text-sm text-gray-500">
                    Please turn on the availability toggle to include your
                    available times.
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </CustomPopupModal.Content>
      <CustomPopupModal.Footer className="flex !justify-center !items-center ">
        <>
          <CustomButton
            label="Cancel"
            variant="primary-transparent"
            customStyle="!h-[40px] !px-2 !text-[16px] !w-[185px]"
            onClick={onHide}
          />
          <CustomButton
            label="Apply"
            variant="primary"
            customStyle="!h-[40px] !w-[185px] !px-2 !text-[16px] ml-4"
            onClick={handleApplyClick}
            disabled={date === null || isOverlap}
          />
        </>
      </CustomPopupModal.Footer>
    </CustomPopupModal>
  );
};

export default AddDateOverrideModal;
