import React from 'react';
import ScheduleButtons from 'components/scheduled-calls/ScheduleButtons';
import Avatar from 'components/common/Avatar';
import { AppointmentStatus } from 'constants/scheduleCallEnums';
import dayjs from 'dayjs';

type CallScheduleCardProps = {
  username: string;
  location: string;
  duration: number;
  date: string | Date;
  startTime: string;
  endTime: string;
  CreatedDate: string;
  rescheduledSuggestedDate?: string;
  newcomerTimezone: string;
  timeSpent?: number;
  profilePictureUrl: string;
  status: keyof typeof AppointmentStatus;
  meetingLink?: string;
  reminders: string;
  question?: string;
  onReschedule: (
    availabilityId: string,
    duration: number,
    newcomerTimezone: string,
    status: keyof typeof AppointmentStatus
  ) => void;
  onCancel?: () => void;
  onJoin?: () => void;
  onConfirm?: () => void;
  onDecline?: () => void;
  suggestion?: {
    username: string;
    friendNewcomerName: string;
    newDate: string;
    newTime: string;
    onConfirm?: () => void;
  };
  friendTimezone: string;
  statusText?: {
    username: string;
    friendNewcomerName: string;
  };
  isNewcomer: boolean;
  appointmentId: string;
  isConfirmedByFriend: boolean;
};

const CallScheduleCard: React.FC<CallScheduleCardProps> = (props) => {
  const {
    username,
    location,
    duration,
    date,
    startTime,
    endTime,
    CreatedDate,
    rescheduledSuggestedDate,
    newcomerTimezone,
    status,
    timeSpent,
    profilePictureUrl,
    question,
    onReschedule,
    onCancel,
    onJoin,
    onConfirm,
    onDecline,
    suggestion,
    statusText,
    isNewcomer,
    appointmentId,
    friendTimezone,
    isConfirmedByFriend,
  } = props;

  const formattedDate = dayjs(date).format('dddd D MMMM, YYYY');
  const formattedStartDateTime = dayjs(date).format('D MMMM, YYYY');
  const getStatus = (status: keyof typeof AppointmentStatus) => {
    if (statusText) {
      switch (status) {
        case 'CONFIRMED':
          return !isNewcomer
            ? isConfirmedByFriend
              ? `Confirmed by ${statusText.friendNewcomerName}`
              : `Confirmed by ${statusText.username}`
            : isConfirmedByFriend
              ? `Confirmed by ${statusText.username}`
              : `Confirmed by ${statusText.friendNewcomerName}`;
        case 'DECLINED':
          return !isNewcomer
            ? `Declined by ${statusText.friendNewcomerName}`
            : `Declined by ${statusText.username}`;
        case 'CANCELLED':
          return !isNewcomer
            ? `Cancelled by ${statusText.username}`
            : `Cancelled by ${statusText.friendNewcomerName}`;
        case 'RESCHEDULED':
          return !isNewcomer
            ? `RConfirmation pending`
            : `Confirmation pending by ${statusText.username}`;
        case 'SUGGESTED':
          return !isNewcomer
            ? `Confirmation pending by ${statusText.username}`
            : `Confirmation pending`;
        default:
          return `Waiting for Confirmation`;
      }
    }
    return status;
  };

  const getStatusColor = (status: keyof typeof AppointmentStatus) => {
    switch (status) {
      case 'CONFIRMED':
      case 'APPROVED':
        return 'text-green-500';
      case 'SUGGESTED':
      case 'RESCHEDULED':
      case 'PENDING':
        return 'text-yellow-500';
      case 'CANCELLED':
      case 'DECLINED':
      case 'SCHEDULED':
        return 'text-primary';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div className="first:mt-0 mt-10 ">
      <div className="p-6 px-0">
        <p className="text-base font-semibold text-darkBlue">{formattedDate}</p>
        <p className="text-sm font-medium text-darkBlue">
          {`${startTime} - ${endTime}`}
        </p>
      </div>
      <div className="flex flex-col md:flex-row w-full bg-white rounded-lg shadow-md">
        <div className="md:w-3/12 bg-categoryInactive px-4 py-10 flex md:flex-col items-center md:justify-start rounded-t-lg md:rounded-l-lg md:rounded-tr-none ">
          <Avatar
            name={username}
            initialImageUrl={profilePictureUrl}
            size="w-16 h-16 rounded-full border-[1px] border-primary"
          />
          <div className="md:text-center md:mt-4 ml-8 md:ml-0">
            <div className="font-medium text-normal mb-1">{username}</div>
            <p className="text-secondary text-xs font-medium">{location}</p>
            {isNewcomer && (
              <p className="text-secondary text-xs font-medium">
                Time Spent - {timeSpent} Years
              </p>
            )}
          </div>
        </div>
        <div className="md:w-9/12 py-4 px-6 flex flex-col justify-between">
          <p className="ml-auto text-xs text-secondary">
            {dayjs(CreatedDate).format('D MMMM, YYYY')}
          </p>
          {question && <div className="text-sm text-secondary">{question}</div>}
          <div className="mt-5 flex flex-col gap-y-2">
            <p className="flex">
              <span className="text-sm font-medium text-secondaryTextColor w-[100px]">
                Duration:
              </span>
              <span className="text-sm text-categoryActive font-medium">
                {duration} minutes
              </span>
            </p>
            <p className="flex">
              <span className="text-sm font-medium text-secondaryTextColor w-[100px]">
                Date:
              </span>
              <span className="text-sm text-categoryActive font-medium">
                {formattedStartDateTime}
              </span>
            </p>
            <div className="flex">
              <span className="text-sm font-medium text-secondaryTextColor w-[100px] ">
                Time:
              </span>
              <div className="flex flex-col">
                <span className="text-sm text-categoryActive font-medium ">
                  {`${startTime} - ${endTime}`}
                </span>
                <span className="text-xs text-blueBorderColor font-medium mt-1">
                  {isNewcomer ? newcomerTimezone : friendTimezone}
                </span>
              </div>
            </div>
            {status !== 'SUGGESTED' && status !== 'RESCHEDULED' && (
              <p className="flex">
                <span className="text-sm font-medium text-secondaryTextColor min-w-[100px]">
                  Status:
                </span>
                <span
                  className={`text-xs font-medium ${getStatusColor(status)}`}
                >
                  {getStatus(status)}
                </span>
              </p>
            )}
          </div>
          {(status === 'SUGGESTED' || status === 'RESCHEDULED') &&
            suggestion && (
              <div>
                <hr className="my-4 bg-categoryActive border-0" />
                <div className="flex flex-col justify-between">
                  <span className="border-b-[1px] my-2"></span>
                  <p className="ml-auto text-xs text-secondary">
                    {dayjs(rescheduledSuggestedDate).format('D MMMM, YYYY')}
                  </p>
                  <div className="bg-yellow-100 p-3 my-2 rounded-sm">
                    <p className="text-xs font-bold text-yellow-600">
                      {status === 'SUGGESTED'
                        ? !isNewcomer
                          ? `${suggestion.friendNewcomerName} suggested a new date & time`
                          : `${suggestion.username} suggested a new date & time.`
                        : !isNewcomer
                          ? `${suggestion.username} requested to reschedule the call.`
                          : `${suggestion.friendNewcomerName} has rescheduled the call.`}
                    </p>
                  </div>
                  <div className="mt-5 flex flex-col gap-y-2">
                    <div className="flex">
                      <span className="text-sm font-semibold text-secondary w-[100px]">
                        New Date:
                      </span>
                      <span className="text-sm font-semibold text-primary">
                        {dayjs(suggestion.newDate).format('D MMMM, YYYY')}
                      </span>
                    </div>
                    <div className="flex">
                      <span className="text-sm font-semibold text-secondary w-[100px]">
                        New Time:
                      </span>
                      <div className="flex flex-col">
                        <span className="text-sm font-semibold text-primary">
                          {suggestion.newTime}
                        </span>
                        <span className="text-xs text-blueBorderColor font-medium mt-1">
                          {isNewcomer ? newcomerTimezone : friendTimezone}
                        </span>
                      </div>
                    </div>
                    {(status === 'SUGGESTED' || status === 'RESCHEDULED') && (
                      <span className="flex">
                        <span className="text-sm font-medium text-secondaryTextColor min-w-[100px]">
                          Status:
                        </span>
                        <span
                          className={`text-xs font-medium ${getStatusColor(status)}`}
                        >
                          {getStatus(status)}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          <div className="flex mt-4 md:justify-end w-full ">
            <ScheduleButtons
              onReschedule={() =>
                onReschedule(
                  appointmentId,
                  duration,
                  isNewcomer ? newcomerTimezone : friendTimezone,
                  status
                )
              }
              onCancel={onCancel}
              onJoin={onJoin}
              onConfirm={onConfirm}
              onDecline={onDecline}
              status={status}
              isNewcomer={isNewcomer}
              appointmentId={appointmentId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallScheduleCard;
