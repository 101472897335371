import React from 'react';
import CustomInputText from 'components/common/Input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

type SearchBarProps = {
  onSearch: (query: string) => void;
  placeholder: string;
  customStyles?: string;
};

const SearchBar: React.FC<
  SearchBarProps & { searchIconPosition?: 'left' | 'right' }
> = ({
  onSearch,
  placeholder,
  customStyles = '',
  searchIconPosition = 'right',
}) => {
  const handleSearch = () => {};

  return (
    <div className={`${customStyles} flex items-center mt-0 sm:mt-4`}>
      <div className={`flex-1 relative `}>
        <CustomInputText
          placeholder={placeholder}
          inputStyles={`p-2 focus:outline-none text-sm border-none xs:rounded-full md:rounded-none`}
          className="mb-0"
        />
        <button
          className={`p-button-warning border-0 absolute ${
            searchIconPosition === 'left' ? 'left-0 pl-4' : 'right-0 pr-2'
          } top-0 bottom-4`}
          onClick={handleSearch}
        >
          <MagnifyingGlassIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
