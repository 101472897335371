export enum CategoryTypes {
  Education = 'Education',
  Employment = 'Employment',
  Healthcare = 'Healthcare',
  Accomodation = 'Accomodation',
  Transport = 'Transport',
  Food = 'Food',
  Recreational = 'Recreational',
  Community = 'Community',
  Other = 'Other',
}
