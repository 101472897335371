import React, { useState } from 'react';

interface TableProps {
  headers: string[];
  headerStyles?: string;
  columnWidths?: string[];
  columnAlignments?: ('left' | 'center' | 'right')[];
  rows: (string | React.ReactNode)[][];
  selectable?: boolean;
  onRowSelect?: (selectedRows: number[]) => void;
}

const Table: React.FC<TableProps> = ({
  headers,
  headerStyles = '',
  columnWidths = [],
  columnAlignments = [],
  rows,
  selectable = false,
  onRowSelect,
}) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleSelectRow = (index: number) => {
    const newSelectedRows = selectedRows.includes(index)
      ? selectedRows.filter((i) => i !== index)
      : [...selectedRows, index];
    setSelectedRows(newSelectedRows);
    if (onRowSelect) {
      onRowSelect(newSelectedRows);
    }
  };

  const handleSelectAllRows = () => {
    const allSelected = selectedRows.length === rows.length;
    const newSelectedRows = allSelected ? [] : rows.map((_, index) => index);
    setSelectedRows(newSelectedRows);
    if (onRowSelect) {
      onRowSelect(newSelectedRows);
    }
  };

  return (
    <div className="overflow-x-auto">
      <div className="min-w-full">
        <div className="flex mb-6 w-full">
          {selectable && (
            <div
              className={`flex items-center justify-center w-full px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-600 tracking-wider ${headerStyles}`}
              style={{ width: '5%' }}
            >
              <input
                type="checkbox"
                checked={selectedRows.length === rows.length}
                onChange={handleSelectAllRows}
              />
            </div>
          )}
          {headers.map((header, index) => (
            <div
              key={index}
              className={`flex items-center justify-start w-full px-6 py-3 border-b border-gray-200 text-left text-sm font-medium text-gray-600 tracking-wider ${headerStyles}`}
              style={{
                width: columnWidths[index] || 'auto',
                textAlign: columnAlignments[index] || 'left',
              }}
            >
              {header}
            </div>
          ))}
        </div>
        {rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={`flex ${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'} ${rowIndex < rows.length - 1 ? 'mb-4' : ''}`}
          >
            {selectable && (
              <div
                className="flex items-center justify-center px-6 py-4 whitespace-nowrap text-sm text-gray-700"
                style={{ width: '5%' }}
              >
                <input
                  type="checkbox"
                  checked={selectedRows.includes(rowIndex)}
                  onChange={() => handleSelectRow(rowIndex)}
                />
              </div>
            )}
            {row.map((cell, cellIndex) => (
              <div
                key={cellIndex}
                className="flex items-center justify-start px-6 py-4 whitespace-nowrap text-sm text-gray-700 overflow-hidden text-ellipsis"
                style={{
                  width: columnWidths[cellIndex] || 'auto',
                  textAlign: columnAlignments[cellIndex] || 'left',
                }}
              >
                <div className="truncate w-full">{cell}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
