import React, { useState, useEffect } from 'react';
import { blogArticles } from 'components/blog/dummy';

const HeroCarousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Get the latest 3 articles
  const latestArticles = blogArticles.slice(-5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % latestArticles.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [latestArticles.length]);

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative mb-8">
      <div className="relative h-[600px] overflow-hidden">
        {latestArticles.map((article, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              index === currentSlide ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div className="relative h-[600px] w-full">
              <img
                className="w-full h-full object-cover"
                src={article.image}
                alt={article.title}
              />
              <div className="absolute inset-0 bg-black opacity-80"></div>
              <div className="absolute inset-0 flex items-end p-6 text-white">
                <div className="max-w-7xl w-full mx-auto pb-14">
                  <div className="flex space-x-2 mb-2">
                    {article.categories.map((category, i) => (
                      <div
                        key={i}
                        className="bg-overlay text-[10px] rounded-full px-3 py-1 inline-block uppercase"
                      >
                        {category}
                      </div>
                    ))}
                  </div>

                  <div className="text-2xl font-bold mb-2">{article.title}</div>
                  <div className="text-base">{article.description}</div>
                  <div className="text-xs my-2">{article.date}</div>
                  <div className="space-x-2 mb-4 mt-10">
                    {latestArticles.map((_, index) => (
                      <button
                        key={index}
                        className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-white' : 'bg-gray-400'}`}
                        onClick={() => goToSlide(index)}
                      ></button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroCarousel;
