import React, { useState } from 'react';
import { ReactComponent as FacebookIcon } from 'assets/icons/blog_facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/blog_twitter.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/blog_linkedIn.svg';
import { LinkIcon } from '@heroicons/react/24/solid';

interface SocialMediaShareProps {
  url: string;
  title: string;
}

const SocialMediaShare: React.FC<SocialMediaShareProps> = ({ url, title }) => {
  const [alertVisible, setAlertVisible] = useState(false);

  const shareToFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookShareUrl, '_blank');
  };

  const shareToTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
    window.open(twitterShareUrl, '_blank');
  };

  const shareToLinkedIn = () => {
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
    window.open(linkedInShareUrl, '_blank');
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      setAlertVisible(true);
      setTimeout(() => setAlertVisible(false), 1000);
    });
  };

  return (
    <div className="relative flex space-x-4">
      <button
        onClick={shareToFacebook}
        className="text-grayBorderColor hover:text-primary transition-colors duration-300"
      >
        <FacebookIcon className="w-6 h-6 fill-current" />
      </button>
      <button
        onClick={shareToTwitter}
        className="text-grayBorderColor hover:text-primary transition-colors duration-300"
      >
        <TwitterIcon className="w-6 h-6 fill-current" />
      </button>
      <button
        onClick={shareToLinkedIn}
        className="text-grayBorderColor hover:text-primary  transition-colors duration-300"
      >
        <LinkedInIcon className="w-6 h-6 fill-current" />
      </button>
      <button
        onClick={copyLinkToClipboard}
        className="text-grayBorderColor hover:text-primary  transition-colors duration-300"
      >
        <LinkIcon className="w-6 h-6" />
      </button>
      {alertVisible && (
        <div className="absolute -top-20 right-0 left-0 mt-2 p-2 bg-white text-primary font-semibold text-[10px] rounded-md shadow-md w-full">
          Link copied to clipboard!
        </div>
      )}
    </div>
  );
};

export default SocialMediaShare;
