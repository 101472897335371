import React, { FC, useState } from 'react';
import TabButton from 'components/scheduled-calls/TabButton';
import { TabHeader } from 'constants/TabHeader';

export type Tab = {
  header: TabHeader;
  content?: React.ReactNode;
};
type CustomTabViewProps = {
  tabs: Tab[];
  onTabChange: (index: number) => void;
};

const CustomTabView: FC<CustomTabViewProps> = ({ tabs, onTabChange }) => {
  const defaultTabIndex = tabs.findIndex(
    (tab) => tab.header === TabHeader.AllCalls
  );

  const [activeIndex, setActiveIndex] = useState(defaultTabIndex);

  const handleTabClick = (index: number) => {
    setActiveIndex(index);
    onTabChange(index);
  };

  return (
    <div className="w-full">
      <div className="flex border-b">
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            label={tab.header}
            isActive={index === activeIndex}
            onClick={() => handleTabClick(index)}
          />
        ))}
      </div>
      <div className="py-4">{tabs[activeIndex]?.content}</div>
    </div>
  );
};

export default CustomTabView;
