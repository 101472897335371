import React from 'react';
import HomePageBanner from 'components/home/HomePageBanner';
import HomeFeatures from 'components/home/HomeFeatures';
import WhoIsFriend from 'components/home/WhoIsFriend';
import Cityarousel from 'components/home/CityCarousel';
import CookiePolicyPopup from 'components/cookie-policy/CookiePolicyPopup';
import { useGetAllCities } from 'api/queries/cities';

const Home: React.FC = () => {
  const { data: cities } = useGetAllCities();

  return (
    <div>
      <HomePageBanner />
      <CookiePolicyPopup />
      <div className="container md:mx-auto mx-6 flex lg:justify-center">
        <div className="lg:mt-[-250px] md:px-10">
          <h3 className="font-bold lg:text-white ml-4 z-10 text-primaryTextColor text-sm lg:text-lg mb-2 relative sm:mt-[20px]">
            Top Cities
          </h3>
          {cities && <Cityarousel data={cities} />}
        </div>
      </div>
      <div className="lg:container mx-auto px-4 xl:px-10 3xl:px-0 flex-col flex lg:flex-row mt-12 min-h-[90vh] gap-12 justify-between mb-6">
        <HomeFeatures />
        <WhoIsFriend />
      </div>
    </div>
  );
};

export default Home;
