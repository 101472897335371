import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';

export type RatingItem = {
  name: string;
  rating: number;
  count: number;
};

type RatingStarsProps = {
  ratings: RatingItem[];
};

const RatingStars: React.FC<RatingStarsProps> = ({ ratings }) => {
  const combineClassNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  return (
    <div className="flex text-secondary">
      <div className="flex flex-col gap-y-1">
        {ratings.map((item, index) => (
          <div key={index} className="flex items-center">
            <div className="w-32">
              <span className="text-xs whitespace-nowrap">{item.name}</span>
            </div>
            <div className="flex">
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    aria-hidden="true"
                    className={combineClassNames(
                      item.rating > rating ? 'text-gray-900' : 'text-gray-200',
                      'h-3 w-3 flex-shrink-0'
                    )}
                  />
                ))}
              </div>
              {/* TODO: Get the count from the source */}
              <span className="text-lightGreyColor ml-1 text-xs">{`(${item.count})`}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingStars;
