import React from 'react';
import Modal from 'components/custom-modal/Modal';
import Error from 'assets/icons/error-cycle.svg';

const NewcomerNotifiaction: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalStyles="md:max-w-xl md:rounded-none md:rounded-none  md::min-h-screen h-full md:h-auto"
      fullScreenStyles="md:p-4 min-h-screen h-screen md:h-full"
    >
      <Modal.Header
        title=""
        className="bg-white text-secondary md:rounded-none"
        iconStyles="text-secondary hover:text-secondary"
      />
      <Modal.Content>
        <div className=" px-4 md:px-11 py-4 min-h-[30vh]">
          <div className="flex flex-col items-center ">
            <h2 className="text-secondary text-lg font-medium lg:text-2xl mb-4">
              You have Signed In as a Newcomer
            </h2>
            <div className="flex justify-center mb-6">
              <img
                src={Error}
                alt="email"
                className="mr-2 cursor-pointer h-12 text-errorMessageColor"
              />
            </div>
            <span className="text-lg text-center mb-6">
              Sorry ! You cannot reply to a question because you have signed in
              as
              <b className="font-medium text-secondary"> Newcomer</b>. Please
              Sign in as <b className="font-medium text-secondary"> Friend </b>
              to reply questions.
            </span>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default NewcomerNotifiaction;
