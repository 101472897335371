import { useMutation } from '@tanstack/react-query';
import {
  CreateAnswerArgs,
  CreateAnswerResponse,
  CreatePostRequest,
  CreatePostResponse,
  QuestionVoteRequestArgs,
  QuestionVoteResponse,
} from './types';
import { answerPost, createPosts, questionVote } from 'api/requests/posts';

export const useCreatePosts = () => {
  return useMutation<CreatePostResponse, Error, CreatePostRequest>({
    mutationFn: createPosts,
  });
};

export const useAnswerPosts = () => {
  return useMutation<CreateAnswerResponse, Error, CreateAnswerArgs>({
    mutationFn: ({ body, questionId }: CreateAnswerArgs) =>
      answerPost(body, questionId),
  });
};

export const useQuestionVote = () => {
  return useMutation<QuestionVoteResponse, Error, QuestionVoteRequestArgs>({
    mutationFn: ({ vote, questionId }: QuestionVoteRequestArgs) =>
      questionVote({ vote }, questionId),
  });
};
