import { useMutation } from '@tanstack/react-query';
import {
  AddNewAvailabilityArgs,
  AvailabilityRequest,
  OverrideAvailabilityArgs,
} from './types';
import {
  addNewAvailability,
  overrideAvailability,
} from 'api/requests/availability';

export const useAddNewAvailability = () => {
  return useMutation<AvailabilityRequest, Error, AddNewAvailabilityArgs>({
    mutationFn: ({
      availabilityRequest,
      availabilityId,
      userId,
    }: AddNewAvailabilityArgs) =>
      addNewAvailability(availabilityRequest, availabilityId, userId),
  });
};

export const useOverrideAvailability = () => {
  return useMutation<AvailabilityRequest, Error, OverrideAvailabilityArgs>({
    mutationFn: ({ availabilityRequest, userId }: OverrideAvailabilityArgs) =>
      overrideAvailability(availabilityRequest, userId),
  });
};
