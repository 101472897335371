import React from 'react';
import Logo from 'assets/images/logo.png';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';

const Footer: React.FC = () => {
  return (
    <footer className="bg-secondary text-white pt-8 pb-3">
      <div className="container mx-6 lg:px-10 lg:mx-auto flex flex-col lg:flex-row justify-between">
        <div className="flex mb-9 lg:mb-0">
          <a href="/" className="-m-1.5 p-1.5">
            <img
              src={Logo}
              alt="Logo"
              className="mr-2 h-10 w-[208px] lg:h-14 lg:w-full cursor-pointer"
            />
          </a>
        </div>
        <div className="text-left mb-9 lg:mb-0">
          <h2 className="text-sm uppercase mb-1">Explore</h2>
          <hr className="w-12 md:w-14 border-t-2 md:border-t-4 border-primary mb-4 sm:mt-4" />
          <div className="flex gap-x-20">
            <div className="flex flex-col">
              <a
                href="/about-us"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                About Us
              </a>
              <a
                href="/how-it-works"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                How It Works
              </a>
              <a
                href="/become-a-friend"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                Become a Friend
              </a>
              <a
                href="/contact-us"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                Contact Us
              </a>
            </div>
            <div className="flex flex-col">
              <a
                href="/terms-and-conditions"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                Terms & Conditions
              </a>
              <a
                href="/cookie-policy"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                Cookie Policy
              </a>
              <a
                href="/privacy-policy"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                Privacy Policy
              </a>
              <a
                href="/faqs"
                className="text-xs pb-4 text-white hover:text-primary"
              >
                FAQs
              </a>
            </div>
          </div>
        </div>
        <div className="text-left mb-6 lg:mb-0">
          <h2 className="text-sm uppercase mb-1">Find us on</h2>
          <hr className="w-12 md:w-14 border-t-2 md:border-t-4 border-primary mb-4 sm:mt-4" />
          <div className="flex items-center">
            <a href="https://www.facebook.com/move2newcity" className="mr-4">
              <FacebookIcon className="w-7 h-7 hover:text-primary transition duration-300" />
            </a>
            <a
              href="https://www.linkedin.com/company/move2newcity/"
              className="mr-4"
            >
              <LinkedInIcon className="w-7 h-7 hover:text-primary transition duration-300" />
            </a>
            <a href="https://www.instagram.com/move2newcity/">
              <InstagramIcon className="w-7 h-7 hover:text-primary transition duration-300" />
            </a>
          </div>
        </div>
      </div>
      <hr className="my-3 border-white" />
      <div className="container flex justify-center lg:justify-start lg:px-10 lg:mx-auto text-xs text-center md:text-left text-white">
        <p>Move2NewCity &copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default Footer;
