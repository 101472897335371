import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CategoryButton from 'components/category/CategoryButton';
import AskQuestionModal from 'components/city/AskAQuestionModal';
import FriendNotifiacation from 'components/city/FriendNotifiaction';
import Button from 'components/common/Button';
import FriendFilter from 'components/friends-filter/FriendFilter';
import FriendsLoadingBox from 'components/friends-loading/FriendsLoading';
import QuestionPanel from 'components/question-panel/QuestionPanel';
import UserProfileCard from 'components/rating-profile/RatingProfileStatus';
import SearchBar from 'components/search-bar/SearchBar';
import SortDropdown from 'components/sort-dropdown/SortDropdown';
import { FriendFilterOptions, ThreadsSortOptions } from 'constants/SortOptions';
import { generateOptionsFromEnum } from 'utils/generateOptions';
import Slider from 'components/friend-profile/FirendCarousel';
import { useGetAllCategories } from 'api/queries/categories';
import Pagination from 'components/common/pagination';
import ScrollToTop from 'components/common/ScrollToTop';
import { useAnswerPosts, useQuestionVote } from 'api/mutations/posts';
import SignIn from 'components/auth/Signin';
import Loader from 'components/common/Loader';
import {
  CreateAnswerArgs,
  QuestionVoteRequestArgs,
} from 'api/mutations/posts/types';
import { voteType } from 'model/City';
import { useGetUsersByRoleAndCityId } from 'api/queries/friends';
import { User } from 'model/User';
import { useUser } from 'api/context/UserContext';
import { UserRole } from 'constants/User';
import { useGetAllCities } from 'api/queries/cities';

// Define types for your data
type QuestionData = {
  _id: string;
  title: string;
  text: string;
  status: string;
  tags: string[];
};

const CityForum = () => {
  const reply = useAnswerPosts();
  const vote = useQuestionVote();
  const user = useUser();
  const { data: cities } = useGetAllCities();
  const isFriend = user?.user?.role === UserRole.FRIEND;
  const params = useParams() || '';

  // TODO: Update with API Integrations
  const totalRecords: number = 2;

  const questionData: QuestionData = {
    _id: '1',
    title: 'Are there job opportunities in Sydney for migrants?',
    text: 'To find work in Australia as a migrant, embracing flexibility and adaptability is crucial. Consider taking lower-level positions or retraining in high-demand fields to enhance employability.',
    status: '',
    tags: ['Education'],
  };

  const { data: users = [], isLoading } = useGetUsersByRoleAndCityId(
    UserRole.FRIEND,
    params.cityId || ''
  );
  console.log('user', users);
  const [city, setCity] = useState<string>();
  const [openQuestionModal, setOpenQuestionModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handleOnSearch = (query: string) => {
    // Implement your search functionality here
  };
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [isFriendNotificationOpen, setFriendNotificationOpen] =
    useState<boolean>(false);
  const [selectedSortOption, setSelectedSortOption] = useState<
    string | undefined
  >(undefined);
  const { data: categories } = useGetAllCategories();
  const [isModalLoading, setIsModalLoading] = useState<boolean>(true); // Add loading state

  useEffect(() => {
    setCity(cities?.find((city) => city.id === params.cityId)?.name as string);
  }, [params.cityId, cities]);

  const handleThreadSortChange = (value: string) => {
    setSelectedSortOption(value); // Update state when dropdown value changes
  };

  const totalPages = 1;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleReply = async (values: CreateAnswerArgs) => {
    try {
      reply.mutate(values, {
        onSuccess: (data) => {
          //Need to handle success
          console.log(' handleSave ~ data:', data);
        },
      });
    } catch (error) {
      // Handle error
    }
  };

  const handleQuestionSelect = (comment: string, questionId: string) => {
    handleReply({
      questionId,
      body: comment,
    });
  };

  const handleVoteQuestion = async (values: QuestionVoteRequestArgs) => {
    try {
      vote.mutate(values, {
        onSuccess: (data) => {
          //Needs to handle success
          console.log('handleSave ~ data:', data);
        },
      });
    } catch (error) {
      // Handle error
    }
  };

  const handleVote = async (type: voteType) => {
    handleVoteQuestion({
      vote: type,
      questionId: questionData._id,
    });
  };

  const handleInteraction = (callback: () => void) => {
    if (!user?.user) {
      const currentUrl = window.location.href;
      let urlWithAsk = currentUrl;
      if (!currentUrl.includes('ask=true')) {
        urlWithAsk += currentUrl.includes('?') ? '&ask=true' : '?ask=true';
      }
      localStorage.setItem('redirectAfterSignIn', urlWithAsk);
      setIsSignInModalOpen(true);
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (user?.user) {
      const redirectUrl = localStorage.getItem('redirectAfterSignIn');
      if (redirectUrl) {
        window.location.href = redirectUrl;
        localStorage.removeItem('redirectAfterSignIn');
      }
    }
  }, [user?.user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const askQuestion = queryParams.get('ask');
    if (askQuestion === 'true') {
      setTimeout(() => {
        if (isFriend) {
          setFriendNotificationOpen(true);
        } else {
          setOpenQuestionModal(true);
        }
        setIsModalLoading(false);
      }, 0);
    } else {
      setIsModalLoading(false);
    }
  }, [isFriend]);

  const handleNotificationClose = () => {
    setFriendNotificationOpen(false);
    const url = new URL(window.location.href);
    url.searchParams.delete('ask');
    window.history.replaceState({}, '', url.toString());
  };

  return (
    <>
      <SignIn
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <FriendNotifiacation
        isOpen={isFriendNotificationOpen}
        onClose={handleNotificationClose}
      />
      <div className="max-w-7xl mx-auto mt-8 relative px-6 lg:px-8">
        {isModalLoading ? (
          <Loader />
        ) : (
          <>
            <div className="flex pb-20">
              <div className="w-full lg:w-9/12 border-none lg:border-r-[1px] border-searchCityPageBorder lg:pr-5">
                <div className="lg:hidden">
                  <div className="flex flex-col gap-4 lg:ml-8 xs:ml-0">
                    <div className="flex lg:justify-between items-center lg:mb-6">
                      <div className="text-[14px] font-semibold text-secondary">
                        Friends from {city}
                      </div>
                      <FriendFilter
                        options={generateOptionsFromEnum(FriendFilterOptions)}
                      />
                    </div>
                    {isLoading ? (
                      <FriendsLoadingBox />
                    ) : users?.length === 0 ? (
                      <FriendsLoadingBox />
                    ) : (
                      <Slider
                        className="py-6 px-5 mb-1 lg:mb-12"
                        items={users.map((profile) => ({
                          name: profile?.username,
                          imageUrl: profile?.profilePictureUrl,
                          id: profile.id,
                        }))}
                      />
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-[18px] font-semibold text-secondary">
                    {city}
                  </div>
                  <SearchBar
                    onSearch={handleOnSearch}
                    placeholder={'Search Posts'}
                    customStyles="md:w-[250px] sm:w-[200px] sm:mt-0"
                  />
                </div>
                <div className="text-[14px] font-semibold text-secondary mt-4">
                  Categories
                </div>
                <div className="mb-10 mt-2 flex lg:flex-wrap gap-6 text-xs overflow-x-auto">
                  {categories?.map((category, index) => (
                    <CategoryButton
                      key={index}
                      label={category?.name || ''}
                      isActive={false}
                      onClick={() => {}}
                      customStyle="flex-shrink-0 capitalize mb-4"
                    />
                  ))}
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <div className="text-[12px] text-secondary mr-2">
                      Sort By :
                    </div>
                    <SortDropdown
                      options={generateOptionsFromEnum(ThreadsSortOptions)}
                      value={selectedSortOption}
                      onChange={(value) => {
                        handleThreadSortChange(value);
                      }}
                    />
                  </div>

                  {!isFriend && (
                    <>
                      <AskQuestionModal
                        isOpen={openQuestionModal}
                        onClose={() => setOpenQuestionModal(false)}
                      />
                      {!isFriend && (
                        <Button
                          label="Ask"
                          variant="primary"
                          customStyle="w-16 h-16 text-[12px] fixed bottom-5 right-5 rounded-xxl shadow-lg md:hidden"
                          onClick={() =>
                            handleInteraction(() => setOpenQuestionModal(true))
                          }
                        />
                      )}
                      <Button
                        label="Ask a Question"
                        variant="primary"
                        customStyle="w-[167px] h-[32px] hidden md:block md:text-sm"
                        onClick={() =>
                          handleInteraction(() => setOpenQuestionModal(true))
                        }
                      />
                    </>
                  )}
                </div>
                <div className="text-[12px] text-secondary mt-4 mb-2">
                  Showing {totalRecords} threads for {city}
                </div>
                {totalRecords === 0 && (
                  <div className="flex items-center justify-center mt-2 bg-white text-editButtonColor h-[227px] font-semibold">
                    <div>Sorry we couldn’t find anything for this search.</div>
                  </div>
                )}
                <QuestionPanel
                  key={questionData._id} // Use unique ID for the key
                  userId={user?.user?.id}
                  date="26 Sep"
                  content={questionData.title}
                  description={questionData.text}
                  categories={questionData.tags}
                  answers={[]}
                  questionId={questionData._id}
                  questionStatus={questionData.status}
                  handleSendClick={handleQuestionSelect}
                  handleVoteClick={handleVote}
                  onSelectQuestion={() => {}}
                  signInUserId={user?.user?.id}
                  onSelectAnswer={() => {}}
                  voting={[]}
                />
                <div className="mt-6">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                  <div className="flex justify-center mt-4">
                    <ScrollToTop />
                  </div>
                </div>
              </div>
              <div className="w-3/12 hidden lg:block items-end">
                <div className="flex flex-col gap-4 ml-8">
                  <div className="flex lg:justify-between items-center mb-6">
                    <div className="text-[14px] font-semibold text-secondary">
                      Friends from {city}
                    </div>
                    <FriendFilter
                      options={generateOptionsFromEnum(FriendFilterOptions)}
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-2 gap-4 ml-8">
                  {isLoading ? (
                    <FriendsLoadingBox />
                  ) : users?.length === 0 ? (
                    <FriendsLoadingBox />
                  ) : (
                    users
                      .slice(0, 5)
                      .map((profile: User) => (
                        <UserProfileCard
                          key={profile.id}
                          username={profile.username}
                          profilePictureUrl={profile.profilePictureUrl ?? ''}
                          userId={profile.id}
                        />
                      ))
                  )}
                </div>
                {users.length > 5 && (
                  <div className="flex justify-center mt-9 ml-8">
                    <Link to={`/friend-list/1`}>
                      <Button
                        label="View more Friends"
                        variant="primary-transparent"
                        customStyle="lg:w-full md:text-sm p-4"
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CityForum;
