export type City = {
  id: string;
  image: any;
  name: string;
  country: string;
  description?: string;
};

export enum voteType {
  UPVOTE = 'upvote',
  DOWNVOTE = 'downvote',
}
