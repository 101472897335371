import apiClient from 'api/apiClient';
import {
  LoginDetails,
  SignupRequest,
  SignupResponse,
  UserSession,
} from 'model/Auth';

import { User } from 'model/User';

export const signin = async (payload: LoginDetails): Promise<UserSession> => {
  try {
    return await apiClient.post('/v1/auth/login', payload);
  } catch (error) {
    throw new Error(`Error login: ${error}`);
  }
};

export const getLoggedUserDetails = async (): Promise<UserSession> => {
  try {
    const response = await apiClient.get('/v1/auth/me');
    return response.data;
  } catch (error: any) {
    throw new Error(`Error login: ${error}`);
  }
};

export const signup = async (
  payload: SignupRequest
): Promise<SignupResponse> => {
  const response = await apiClient.post('/v1/auth/signUp', payload);
  return response?.data;
};

export type UpdateUserRequest = Partial<
  Omit<User, 'id' | 'lastPasswordChange' | 'profilePictureUrl'>
>;

export const updateUser = async (
  userId: string,
  payload: UpdateUserRequest
): Promise<User> => {
  try {
    const response = await apiClient.put(`/v1/users/${userId}`, payload);
    return response.data; // Adjust based on your response structure
  } catch (error) {
    throw new Error(`Error updating user: ${error}`);
  }
};
