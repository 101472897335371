import React from 'react';

type QuestionFriendProps = {
  userId: string | undefined;
  date: string;
  content: string;
  description?: string;
  useBackgroundColor?: boolean;
  showSocialIcons?: boolean;
  questionId: string;
  signInUserId?: string;
  questionStatus?: string;
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

const QuestionFriend: React.FC<QuestionFriendProps> = ({
  userId,
  date,
  content,
  description = '',
  useBackgroundColor = false,
}) => {
  const truncatedDescription = truncateText(description, 100);

  return (
    <div className="w-full mx-auto">
      <div className="p-4 rounded-sm">
        <div
          className={`${
            useBackgroundColor ? 'bg-homeItemBgColor p-4 rounded-sm' : ''
          }`}
        >
          <div className="flex justify-between">
            <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
              <h3 className="font-medium m-0 text-xs">{'user'}</h3>
              <p className="m-0 text-xs text-secondary">{date}</p>
            </div>
          </div>
          <div className="text-sm font-semibold mt-3 leading-loose">
            {content}
          </div>
          {description && (
            <p className="mt-1 text-xs">{truncatedDescription}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionFriend;
