import React from 'react';
import { Checkbox, CheckboxProps } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';

type CustomCheckboxProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  label?: React.ReactNode;
  checkBoxTextStyles?: string;
} & CheckboxProps;

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  label,
  checkBoxTextStyles,
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      className={`group relative flex items-center space-x-2 text-sm ${checkBoxTextStyles}`}
    >
      {({ checked }) => (
        <>
          <div
            className={`h-4 w-4 flex items-center justify-center rounded-sm border-[1px] ${
              checked
                ? 'bg-categoryActive border-categoryActive'
                : 'bg-white border-grayBorderColor'
            } transition-colors duration-200 ease-in-out`}
          >
            <CheckIcon
              className={`h-5 w-5 text-white ${checked ? 'block' : 'hidden'}`}
            />
          </div>
          {label && <span className="text-gray-700">{label}</span>}
        </>
      )}
    </Checkbox>
  );
};

export default CustomCheckbox;
