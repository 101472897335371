import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from 'pages/Home';
import HowItWorks from 'pages/HowItWorks';
import AboutUs from 'pages/AboutUs';
import TermsAndPolicy from 'pages/TermsAndPolicy';
import CookiePolicy from 'pages/CookiePolicy';
import BecomeAFriend from 'pages/BecomeAFriend';
import TermsAndCondition from 'pages/TermsAndConditions';
import Layout from 'components/common/Layout';
import ContactUs from 'pages/ContactUs';
import FAQs from 'pages/Faq';
import CityForum from 'pages/CityForum';
import FriendProfile from 'pages/FriendProfile';
import Profile from 'pages/Profile';
import { City } from 'model/City';
import ScheduleCall from 'pages/ScheduleCall';
import FriendList from 'pages/FriendList';
import Demo from 'pages/InteractiveDemo';
import LayoutWrapper from 'components/layout/LayoutWrapper';
import { useEffect, useState } from 'react';
import { UserSession } from 'model/Auth';
import { getCookies } from 'utils/cookieUtil';
import ArticleList from 'pages/ArticleList';
import NewArticle from 'pages/NewArticle';
import BlogArchive from 'pages/BlogArchive';
import SingleBlogPostPage from 'pages/SingleBlogPost';
import AllPostsPage from 'pages/AllPostPage';
import BlogPreviewPage from 'pages/BlogPreviewPage';

const cities: City[] = [
  {
    id: '1',
    image: 'path/to/image1.jpg',
    name: 'New York',
    country: 'USA',
    description: 'The city that never sleeps.',
  },
  {
    id: '2',
    image: 'path/to/image2.jpg',
    name: 'Los Angeles',
    country: 'USA',
    description: 'The entertainment capital of the world.',
  },
];

const App = () => {
  const [session, setSession] = useState<UserSession>();
  useEffect(() => {
    const accessToken = getCookies('accessToken');
    setSession(accessToken);
  }, []);

  return (
    <BrowserRouter>
      <LayoutWrapper>
        <Layout cities={cities} session={session}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<TermsAndPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/become-a-friend" element={<BecomeAFriend />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndCondition />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/city/:cityId" element={<CityForum />} />
            <Route path="/friends/:friendId" element={<FriendProfile />} />
            <Route path="/user-profile" element={<Profile />} />
            <Route path="/friend-list" element={<FriendList />} />
            <Route path="/demo" element={<Demo />} />
            <Route
              path="/friends/:friendId/schedule"
              element={<ScheduleCall />}
            />
            <Route path="/article-list" element={<ArticleList />} />
            <Route path="/add-new-article" element={<NewArticle />} />
            <Route path="/blog" element={<BlogArchive />} />
            <Route path="/blog/:id" element={<SingleBlogPostPage />} />
            <Route path="/all-posts" element={<AllPostsPage />} />
            <Route path="/preview" element={<BlogPreviewPage />} />
          </Routes>
        </Layout>
      </LayoutWrapper>
    </BrowserRouter>
  );
};

export default App;
