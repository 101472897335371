import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import CustomSwitch from 'components/common/Switch';
import Button from 'components/common/Button';
import TimeDropdown from 'components/remainder-form/TimeDropdown';
import { timeDropdownItems } from 'utils/timeDropdownItems';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import { Availability } from 'model/Availability';
import { User } from 'model/User';
import { useAddNewAvailability } from 'api/mutations/availability';
import notify, { NotifySeverity } from 'components/common/Notification';
import { convert12ToTime } from 'utils/availabilityUtils';

enum DaysOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

enum TimeType {
  startTime = 'startTime',
  endTime = 'endTime',
}

const initializeDaysState = (initialValue: boolean) => {
  return new Map<string, boolean>(
    Object.keys(DaysOfWeek).map((day) => [day, initialValue])
  );
};

type AvailabilitySlotsProps = {
  selectedTime: Map<string, { startTime: Date; endTime: Date }[]>;
  setSelectedTime: Dispatch<
    SetStateAction<Map<string, { startTime: Date; endTime: Date }[]>>
  >;
  availabilitiesData: Availability[];
  user: User;
};

const AvailabilitySlots: React.FC<AvailabilitySlotsProps> = ({
  selectedTime,
  setSelectedTime,
  availabilitiesData,
  user,
}) => {
  const [isAddingNew, setIsAddingNew] = useState(false);
  const newSegment = useAddNewAvailability();
  useEffect(() => {
    if (availabilitiesData) {
      const hasAvailabilities = availabilitiesData?.some(
        (availability) =>
          availability.segments &&
          availability.segments.length > 0 &&
          availability.available
      );

      setCheckedAvailability(hasAvailabilities);
      const availabilitiesMap = new Map<
        string,
        { [key in TimeType]: Date }[]
      >();

      availabilitiesData?.forEach((availability) => {
        if (availability.segments) {
          const dayKey = DaysOfWeek[+availability.dayOfWeek];
          const timesForDay = availability.segments.map((segment: any) => {
            const startTimeString = segment.startTime;
            const endTimeString = segment.endTime;
            const convertedTime = convert12ToTime(
              startTimeString,
              endTimeString
            );

            return {
              [TimeType.startTime]: convertedTime?.startDate,
              [TimeType.endTime]: convertedTime?.endDate,
            };
          });

          const existingTimesForDay = availabilitiesMap.get(dayKey) || [];
          const combinedTimesForDay = [...existingTimesForDay, ...timesForDay];
          availabilitiesMap.set(dayKey, combinedTimesForDay);

          setAdditionalDropdowns((prevAdditionalDropdowns) => {
            const updatedDropdowns = new Map<string, number>(
              prevAdditionalDropdowns
            );
            const existingCount = updatedDropdowns.get(dayKey) || 0;
            const newCount = Math.max(
              existingCount,
              combinedTimesForDay.length - 1
            );
            updatedDropdowns.set(dayKey, newCount);
            return updatedDropdowns;
          });
        }
      });

      setSelectedTime(availabilitiesMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitiesData]);

  const [checkedDays, setCheckedDays] = useState<Map<string, boolean>>(
    initializeDaysState(true)
  );

  const [checkedAvailability, setCheckedAvailability] = useState<boolean>(true);

  const [additionalDropdowns, setAdditionalDropdowns] = useState<
    Map<string, number>
  >(new Map(Object.keys(DaysOfWeek).map((day) => [day, 0])));

  const initializeOverlapError = () => {
    return new Map<string, boolean[]>(
      Object.keys(DaysOfWeek).map((day) => [day, [false, false, false]])
    );
  };

  const [overlapError, setOverlapError] = useState<Map<string, boolean[]>>(
    initializeOverlapError()
  );

  const handleTimeChange = async (
    day: string,
    index: number,
    field: TimeType,
    value: Date
  ) => {
    setSelectedTime((prevSelectedTime) => {
      const timesForDay = prevSelectedTime.get(day);
      if (!timesForDay || index >= timesForDay.length) {
        return prevSelectedTime;
      }

      const updatedTime = [...timesForDay];
      updatedTime[index][field] = value;

      checkForOverlap(day, updatedTime);

      return new Map(prevSelectedTime.set(day, updatedTime));
    });

    const overlapsExist = overlapError.get(day)?.includes(true);
    const dayNumber = DaysOfWeek[day as keyof typeof DaysOfWeek];

    if (!overlapsExist) {
      let availabilityId = '';
      availabilitiesData?.forEach((availability) => {
        if (Number(availability.dayOfWeek) === dayNumber) {
          availabilityId = availability.id;
        }
      });

      if (availabilityId) {
        try {
          const payload = {
            availabilityId,
            userId: user?.id,
            availabilityRequest: {
              friendId: user?.id ?? '',
              dayOfWeek: dayNumber,
              segments:
                selectedTime.get(day)?.map((segment) => {
                  const formattedStartTime = segment.startTime.toLocaleString(
                    user?.timeZone,
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }
                  );
                  const formattedEndTime = segment.endTime.toLocaleString(
                    user?.timeZone,
                    {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    }
                  );
                  return {
                    startTime: formattedStartTime,
                    endTime: formattedEndTime,
                  };
                }) || [],
              available: true,
              type: 'WEEK',
            },
          };
          newSegment.mutate(payload, {
            onSuccess: (data) => {
              notify({
                title: 'Success!',
                content: 'Availability updated successfully.',
                severity: NotifySeverity.SUCCESS,
              });
            },
          });
        } catch (error) {
          notify({
            title: 'Error!',
            content: 'An error occurred while updating availability.',
            severity: NotifySeverity.ERROR,
          });
        }
      }
    }
  };

  const checkForOverlap = (
    day: string,
    timeslots: { [key in TimeType]: Date }[]
  ) => {
    const slots = timeslots.map((slot) => ({
      start: slot.startTime.getTime(),
      end: slot.endTime.getTime(),
    }));

    const overlapFlags = new Array(slots.length).fill(false);
    for (let i = 0; i < slots.length; i++) {
      for (let j = 0; j < slots.length; j++) {
        if (
          i !== j &&
          slots[i].start < slots[j].end &&
          slots[j].start < slots[i].end
        ) {
          overlapFlags[i] = true;
          overlapFlags[j] = true;
        }
      }
    }

    setOverlapError(
      (prevOverlapError) => new Map(prevOverlapError.set(day, overlapFlags))
    );
  };

  const filterStartTimeItems = (endTime: Date | null, items: any[]) => {
    if (!endTime) return items;
    return items;
  };

  const filterEndTimeItems = (startTime: Date | null, items: any[]) => {
    if (!startTime) return items;
    return items;
  };

  const handleAddDropdown = async (enumDay: DaysOfWeek) => {
    const dayKey = DaysOfWeek[enumDay];

    if ((additionalDropdowns.get(dayKey) ?? 0) < 2 && !isAddingNew) {
      setIsAddingNew(true);
      const daySegments = selectedTime.get(dayKey) || [];
      const lastSegment = daySegments[daySegments?.length - 1];
      const latestEndTime = lastSegment?.endTime;

      const newStartTime = new Date(latestEndTime?.getTime() + 30 * 60000);
      const newEndTime = new Date(newStartTime?.getTime() + 30 * 60000);
      const timeZone = user.timeZone;
      const formattedNewStartTime = newStartTime.toLocaleString(timeZone, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });

      const formattedNewEndTime = newEndTime.toLocaleString(timeZone, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
      try {
        let availabilityId = '';
        availabilitiesData?.forEach((availability) => {
          if (Number(availability.dayOfWeek) === enumDay) {
            availabilityId = availability.id;
          }
        });

        if (availabilityId) {
          const formattedSegments = [
            ...daySegments.map((segment) => {
              const timeZone = user?.timeZone;
              const formattedStartTime = segment.startTime.toLocaleString(
                timeZone,
                { hour: 'numeric', minute: 'numeric', hour12: false }
              );
              const formattedEndTime = segment.endTime.toLocaleString(
                timeZone,
                { hour: 'numeric', minute: 'numeric', hour12: false }
              );

              return {
                startTime: formattedStartTime,
                endTime: formattedEndTime,
              };
            }),
            {
              startTime: formattedNewStartTime,
              endTime: formattedNewEndTime,
            },
          ];
          const payload = {
            availabilityId,
            userId: user?.id ?? '',
            availabilityRequest: {
              friendId: user?.id ?? '',
              dayOfWeek: DaysOfWeek[enumDay].toUpperCase(),
              segments: formattedSegments,
              available: true,
              type: 'WEEK',
            },
          };
          newSegment.mutate(payload, {
            onSuccess: (data) => {
              notify({
                title: 'Success!',
                content: 'New availability added successfully.',
                severity: NotifySeverity.SUCCESS,
              });
            },
          });
        }
      } catch (error) {
        notify({
          title: 'Error!',
          content: 'An error occurred while adding new availability.',
          severity: NotifySeverity.ERROR,
        });
      }

      setSelectedTime((prevSelectedTime) => {
        const updatedSegments = [
          ...prevSelectedTime?.get(dayKey)!,
          {
            startTime: newStartTime,
            endTime: newEndTime,
          },
        ];
        return new Map(prevSelectedTime?.set(dayKey, updatedSegments));
      });

      setAdditionalDropdowns((prevCounts) => {
        const updatedCounts = new Map(prevCounts);
        const currentCount = updatedCounts?.get(dayKey) || 0;
        updatedCounts.set(dayKey, currentCount + 1);
        return updatedCounts;
      });

      checkForOverlap(dayKey, [
        ...daySegments,
        { startTime: newStartTime, endTime: newEndTime },
      ]);
    }
    setIsAddingNew(false);
  };

  const renderDropdowns = (
    enumDay: DaysOfWeek,
    isRowDisabled: boolean,
    isAvailabilityDisabled: boolean,
    index: number
  ) => {
    const dayKey = String(DaysOfWeek[enumDay]);
    const timesForDay = selectedTime.get(dayKey);
    const startTime =
      timesForDay && timesForDay[index]
        ? timesForDay[index][TimeType.startTime]
        : null;
    const endTime =
      timesForDay && timesForDay[index]
        ? timesForDay[index][TimeType.endTime]
        : null;

    const filteredStartTimeItems = filterStartTimeItems(
      endTime,
      timeDropdownItems
    );

    const filteredEndTimeItems = filterEndTimeItems(
      new Date(),
      timeDropdownItems
    );

    return (
      <div className="flex w-full xl:w-8/12 items-center justify-between">
        <div className="flex ">
          <div className="flex items-center justify-between">
            <div
              className={`mr-2 ${
                isRowDisabled ? 'opacity-50 pointer-events-none' : ''
              }`}
            >
              <TimeDropdown
                items={filteredStartTimeItems}
                selectedItem={startTime?.toLocaleString() as string}
                setSelectedItem={(newTime) =>
                  handleTimeChange(dayKey, index, TimeType.startTime, newTime)
                }
                disable={isRowDisabled || isAvailabilityDisabled}
                wrapperclassName="min-w-[100px] sm:w-[200px] md:w-[260px] lg:w-[140px]"
              />
            </div>
            <div
              className={` px-2 sm:px-4 ${
                isRowDisabled ? 'opacity-50 pointer-events-none' : ''
              }`}
            >
              to
            </div>
            <div
              className={`ml-2 ${
                isRowDisabled ? 'opacity-50 pointer-events-none' : ''
              }`}
            >
              <TimeDropdown
                items={filteredEndTimeItems}
                selectedItem={endTime?.toLocaleString() as string}
                setSelectedItem={(newTime) =>
                  handleTimeChange(dayKey, index, TimeType.endTime, newTime)
                }
                disable={isRowDisabled || isAvailabilityDisabled}
                wrapperclassName="min-w-[100px] sm:w-[200px] md:w-[260px] lg:w-[140px]"
              />
            </div>
          </div>
          {overlapError.get(dayKey)?.[index] && (
            <div className="text-[10px] text-invalidDropdownRed mt-10 absolute">
              Times overlap with another set of times
            </div>
          )}
        </div>
        <div className="flex">
          {!isRowDisabled && index === 0 && (
            <Button
              label={<div className="hidden sm:block">Add New</div>}
              onClick={() => handleAddDropdown(enumDay)}
              disabled={false}
              icon={<PlusIcon className={`  w-4 h-4 !mr-0 `} />}
              iconStyle="!mr-0"
              customStyle={`md:ml-6 ml-2 !rounded-full sm:!rounded-[4px] flex justify-center items-center !bg-textFieldBlue w-4 sm:w-[112px] text-white text-[12px] font-semibold !px-5 sm:!px-0 border-none ${
                isAvailabilityDisabled ? 'disabled-button' : ''
              }`}
            />
          )}
          {index > 0 && (
            <div
              className={`flex justify-center items-center md:ml-6 ml-2 border-[1px] border-textFieldBlue rounded-full bg-white w-10 h-10 cursor-pointer  ${
                isRowDisabled ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={() => {}}
            >
              <TrashIcon className={`w-5 h-5  text-textFieldBlue`} />
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (availabilitiesData) {
      const updatedCheckedDays = initializeDaysState(true);
      availabilitiesData.forEach((availability) => {
        const dayOfWeek =
          DaysOfWeek[availability.dayOfWeek as keyof typeof DaysOfWeek];
        updatedCheckedDays.set(String(dayOfWeek), availability.available);
      });
      setCheckedDays(updatedCheckedDays);
    }
  }, [availabilitiesData]);

  const handleToggleDayOfWeekAvailability = async (
    isEnabled: boolean,
    enumDay: DaysOfWeek
  ) => {
    if (!user || !user.id) {
      notify({
        title: 'Error!',
        content: 'User session is missing, cannot update availabilities.',
        severity: NotifySeverity.ERROR,
      });
      return;
    }

    const dayKey = DaysOfWeek[enumDay];
    const availability = availabilitiesData?.find(
      (avail) => Number(avail.dayOfWeek) === enumDay
    );

    if (!availability) {
      notify({
        title: 'Error!',
        content: 'Availability data not found for the selected day.',
        severity: NotifySeverity.ERROR,
      });
      return;
    }

    const availabilityId = availability.id;

    try {
      const payload = {
        availabilityId: availabilityId,
        userId: user?.id,
        availabilityRequest: {
          friendId: user!.id,
          available: isEnabled,
        },
      };

      newSegment.mutate(payload, {
        onSuccess: (data) => {
          notify({
            title: 'Success!',
            content: `Availability for ${dayKey} has been set to ${
              isEnabled ? 'available' : 'unavailable'
            }.`,
            severity: NotifySeverity.SUCCESS,
          });
        },
      });
    } catch (error) {
      notify({
        title: 'Error!',
        content: 'An error occurred while updating availability.',
        severity: NotifySeverity.ERROR,
      });
    }
  };

  const handleToggleDayAvailability = async (
    isEnabled: boolean,
    enumDay: DaysOfWeek
  ) => {
    setCheckedDays((prevCheckedDays) => {
      const updatedCheckedDays = new Map(prevCheckedDays);
      updatedCheckedDays.set(String(DaysOfWeek[enumDay]), isEnabled);
      return updatedCheckedDays;
    });

    await handleToggleDayOfWeekAvailability(isEnabled, enumDay);
  };

  const renderDropdownsWrapper = (
    enumDay: DaysOfWeek,
    isRowDisabled: boolean,
    isAvailabilityDisabled: boolean
  ) => {
    return (
      <div
        key={enumDay}
        className={`font-medium mt-6 xl:mt-10 ${
          isAvailabilityDisabled ? 'opacity-50 pointer-events-none' : ''
        }`}
      >
        <div className="flex flex-col xl:flex-row xl:items-center w-12/12">
          <div className="flex items-center lg:flex-grow ">
            <div className="lg:w-1/12 xl:flex-grow">
              <CustomSwitch
                checked={checkedDays.get(String(DaysOfWeek[enumDay])) ?? true}
                onChange={() =>
                  handleToggleDayAvailability(
                    !checkedDays.get(String(DaysOfWeek[enumDay])) ?? true,
                    enumDay
                  )
                }
              />
            </div>
            <div
              className={`lg:w-2/12 p-4 xl:flex-grow ${
                isRowDisabled ? 'opacity-50 pointer-events-none' : ''
              }`}
            >
              {DaysOfWeek[enumDay]}
            </div>

            <div className="lg:w-1/12 pr-2 xl:flex-grow  hidden xl:block">
              <div className="border-b border-textFieldBlue"></div>
            </div>
          </div>

          {renderDropdowns(enumDay, isRowDisabled, isAvailabilityDisabled, 0)}
        </div>

        {Array.from(
          { length: additionalDropdowns.get(String(DaysOfWeek[enumDay])) ?? 0 },
          (_, index) => (
            <div key={index} className=" w-12/12 mt-4 flex xl:justify-end">
              {renderDropdowns(
                enumDay,
                isRowDisabled,
                isAvailabilityDisabled,
                index + 1
              )}
            </div>
          )
        )}
      </div>
    );
  };

  const isString = (value: any): value is string => {
    return typeof value === 'string';
  };

  const renderDayDropdowns = (enumDay: DaysOfWeek) => {
    if (isString(enumDay)) {
      return null;
    }
    const isAvailabilityDisabled = !checkedAvailability;
    const isRowDisabled = !(
      checkedDays.get(String(DaysOfWeek[enumDay])) ?? false
    );

    return renderDropdownsWrapper(
      enumDay,
      isRowDisabled,
      isAvailabilityDisabled
    );
  };

  return (
    <div className="bg-white text-[14px] text-secondary">
      <div className="">
        <div className="font-semibold pt-4 pb-4">Set Your Weekly Hours</div>
        <div className="lg:border-[1px] lg:border-textFieldBlue rounded-[10px] lg:p-8">
          <div className="flex items-center">
            <CustomSwitch checked={checkedAvailability} onChange={() => {}} />
            <div className="font-semibold ml-4">Availability</div>
          </div>

          <div className=" w-full font-medium mt-6 hidden xl:flex">
            <div className="w-4/12 ">Select Available Day(s) *</div>
            <div className=" w-8/12">Select Available Times *</div>
          </div>
          {Array.from(Object.values(DaysOfWeek)).map((day) => {
            const enumDay = day as DaysOfWeek;
            return renderDayDropdowns(enumDay);
          })}
        </div>
      </div>
    </div>
  );
};

export default AvailabilitySlots;
