import React, { FC, useMemo, useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomButton from 'components/common/Button';
import CustomInputText from 'components/common/Input';
import CustomDropdown from 'components/common/Dropdown';
import CategoryButton from 'components/category/CategoryButton';
import { CategoryTypes } from 'constants/Category';
import { timeZones } from 'constants/timezones';
import CustomTextarea from 'components/common/TextArea';
import Avatar from 'components/common/Avatar';
import { useUser } from 'api/context/UserContext';
import { updateUser } from 'api/requests/auth/index'; // Import the API function
import { User } from 'model/User';
import { useGetAllCities } from 'api/queries/cities';
import notify, { NotifySeverity } from 'components/common/Notification';

type AccountsProps = {
  isNewcomer?: boolean;
  session?: any;
};

const categories = Object.keys(CategoryTypes);

const EditUser: FC<AccountsProps> = ({ isNewcomer, session }) => {
  const { user, refetch: refetchUser } = useUser();
  const getSignUpUserId = user?.id?.toString() ?? '';
  const [timeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const { data: allCities } = useGetAllCities();

  const citiesOptions =
    allCities?.map((city) => ({
      label: city.name,
      value: city.id,
    })) || [];

  const userRole = user?.role || 'FRIEND';

  const initialValues = useMemo(
    () => ({
      userid: user?.id || '',
      fullname: user?.name || '',
      username: user?.username || '',
      email: user?.email || '',
      mobile: user?.mobile || '',
      aboutMe: user?.aboutMe || '',
      timeZone: user?.timeZone || '',
      userRole: user?.role || '',
      expertCategories: user?.expertCategories
        ? user?.expertCategories.split(',')
        : [],
      interestedCategories: user?.interestedCategories
        ? user?.interestedCategories.split(',')
        : [],
      yearsInCity: user?.yearsInCity?.toString() || '',
      cityId: user?.cityId || '',
    }),
    [user]
  );
  const previousValuesRef = useRef(initialValues);

  const cleanCategoryArray = (categories: string[]) => {
    return categories.filter((category) => category && category.trim() !== '');
  };

  const getCityNameById = (cityId: string): string => {
    if (!citiesOptions || citiesOptions.length === 0) {
      return 'Select City';
    }

    const city = citiesOptions.find((option) => option.value === cityId);
    return city?.label || 'Select City';
  };

  const placeholderCityName = getCityNameById(user?.cityId || '');

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string()
        .trim()
        .nullable()
        .matches(/\S+/, 'Invalid Full Name'),
      username: Yup.string()
        .trim()
        .required('*Username is required.')
        .matches(
          /^[a-zA-Z0-9_-]{5,16}$/,
          '* Your username must be at least 5 characters and can include a combination of numbers, letters, and special characters (- and _).'
        )
        .matches(/[a-zA-Z]/, 'Username must contain at least one letter.')
        .matches(
          /^[^\s@]+$/,
          'Username cannot contain spaces or the "@" sign.'
        ),
      email: Yup.string()
        .trim()
        .email('Invalid email')
        .required('Email is required'),
      mobile: Yup.string()
        .trim()
        .nullable()
        .matches(/^\d{10}$/, 'Invalid Mobile Number'),
      yearsInCity: Yup.number().when('userRole', {
        is: (role: string) => role !== 'GUEST',
        then: (schema) =>
          schema
            .min(1, 'Years in city should be greater than 0')
            .max(100, 'Years in city should be less than 100')
            .required('Years in city should be greater than 0'),
        otherwise: (schema) => schema.nullable(),
      }),
      aboutMe: Yup.string()
        .trim()
        .nullable()
        .matches(/\S+/, 'Invalid About')
        .when('userRole', {
          is: (role: string) => role !== 'GUEST',
          then: (schema) => schema.required('About is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      expertCategories: Yup.array().when('userRole', {
        is: 'GUEST',
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.min(1, 'Expert Categories are required').required(),
      }),
      interestedCategories: Yup.array().when('userRole', {
        is: 'FRIEND',
        then: (schema) => schema.nullable(),
        otherwise: (schema) =>
          schema.min(1, 'Interested Categories are required').required(),
      }),
      cityId: Yup.string().trim().required('Current city is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const userId = getSignUpUserId;

      const cleanedExpertCategories = cleanCategoryArray(
        values.expertCategories
      ).join(',');
      const cleanedInterestedCategories = cleanCategoryArray(
        values.interestedCategories
      ).join(',');

      const payload: User = {
        id: user?.id ?? '',
        username: values.username,
        name: values.fullname,
        email: values.email,
        aboutMe: values.aboutMe,
        mobile: values.mobile,
        timeZone: values.timeZone,
        yearsInCity:
          userRole === 'FRIEND' ? Number(values.yearsInCity) : undefined,
        expertCategories: userRole === 'FRIEND' ? cleanedExpertCategories : '',
        interestedCategories:
          userRole !== 'FRIEND' && userRole === 'GUEST'
            ? cleanedInterestedCategories
            : '',
        role: user?.role,
        availability: user?.availability,
        cityId: values.cityId,
        profilePictureUrl: user?.profilePictureUrl ?? '',
      };
      try {
        await updateUser(userId, payload);
        notify({
          severity: NotifySeverity.SUCCESS,
          title: 'Update Successful',
          content: 'Your profile has been updated successfully.',
        });
        resetForm({ values });
        await refetchUser();
      } catch (error) {
        notify({
          severity: NotifySeverity.ERROR,
          title: 'Update Failed',
          content:
            'There was an error updating your profile. Please try again.',
        });
      }
    },
  });

  useEffect(() => {}, [formik.values.expertCategories]);

  const handleTimeZoneChange = (value: string) => {
    setTimeZone(value);
    formik.setFieldValue('timeZone', value);
  };

  useEffect(() => {
    formik.setValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {}, [formik.errors]);

  const validateUsername = (username: string) => {
    const lengthRegex = /^[a-zA-Z0-9_-]{5,16}$/;
    const letterRegex = /[a-zA-Z]/;
    const invalidCharRegex = /^[-_ ]|[@ ]/;

    return (
      lengthRegex.test(username) &&
      letterRegex.test(username) &&
      !invalidCharRegex.test(username)
    );
  };

  useEffect(() => {
    formik.setValues(initialValues);
    previousValuesRef.current = initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const username = e.target.value;
    const isValidLength = username.length >= 5 && username.length <= 16;
    const isValidUsername = validateUsername(username);

    formik.setFieldTouched('username', true);
    formik.setFieldValue('username', username);
    formik.setFieldError(
      'username',
      isValidLength && isValidUsername ? undefined : 'Invalid username'
    );
  };

  const getUsernameErrorMessage = (username: string) => {
    if (!username) {
      return 'Username is required.';
    } else if (username.startsWith('_') || username.startsWith('-')) {
      return 'No starting hyphen or underscore allowed.';
    } else if (username.includes('@')) {
      return 'Username cannot contain the "@" sign.';
    } else if (/^\d+$/.test(username)) {
      return 'Username must contain at least one letter.';
    } else if (username.includes(' ')) {
      return 'Username cannot contain spaces.';
    } else if (username.length < 5) {
      return 'Your username must be at least 5 characters and can include a combination of numbers, letters, and special characters (- and _).';
    } else {
      return '';
    }
  };

  const isSaveDisabled = !formik.dirty || !formik.isValid;

  return (
    <div className="flex flex-col items-center md:items-start px-10">
      <h1 className="font-medium text-secondary text-lg mt-4 lg:mt-0">
        {userRole === 'FRIEND' ? 'Edit Friend Account' : 'Edit User Account'}
      </h1>

      <form onSubmit={formik.handleSubmit} className="mt-5">
        <div className="flex justify-center my-5">
          <Avatar
            name={formik.values.username}
            onImageUpload={(file: File) => {
              formik.setFieldValue('image', file);
            }}
            initialImageUrl={''}
            hidePencil={false}
            charBorderStyles="text-4xl"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-2">
          <div>
            <CustomInputText
              label="Full name"
              placeholder="e.g: Maria Hill"
              value={formik.values.fullname}
              errorText={
                formik.touched.fullname && formik.errors.fullname
                  ? Array.isArray(formik.errors.fullname)
                    ? formik.errors.fullname.join(', ')
                    : typeof formik.errors.fullname === 'object'
                      ? JSON.stringify(formik.errors.fullname)
                      : formik.errors.fullname
                  : undefined
              }
              className="!mb-0 mt-5"
              onChange={formik.handleChange}
              name="fullname"
              id="fullname"
            />
            {formik.touched.fullname && formik.errors.fullname && (
              <p className="text-errorMessageColor text-xs mt-1">
                {formik.errors.fullname instanceof Array
                  ? formik.errors.fullname.join(', ')
                  : typeof formik.errors.fullname === 'object'
                    ? JSON.stringify(formik.errors.fullname)
                    : formik.errors.fullname}{' '}
              </p>
            )}
          </div>
          <div>
            <CustomInputText
              isToolTip={true}
              label="Username *"
              placeholder="e.g: johnDoe123"
              value={formik.values.username}
              errorText={
                formik.touched.username && formik.errors.username
                  ? typeof formik.errors.username === 'string'
                    ? formik.errors.username
                    : undefined
                  : undefined
              }
              className="!mb-0 mt-5"
              onChange={handleUsernameChange}
              onBlur={formik.handleBlur}
              name="username"
              id="username"
            />
            {formik.touched.username && (
              <p className="text-errorMessageColor text-xs mt-1">
                {getUsernameErrorMessage(formik.values.username)}
              </p>
            )}
          </div>
          <div>
            <CustomInputText
              label="Mobile"
              placeholder="Enter your mobile number"
              value={formik.values.mobile}
              errorText={
                formik.touched.mobile && formik.errors.mobile
                  ? Array.isArray(formik.errors.mobile)
                    ? formik.errors.mobile.join(', ')
                    : typeof formik.errors.mobile === 'object'
                      ? JSON.stringify(formik.errors.mobile)
                      : formik.errors.mobile
                  : undefined
              }
              className="!mb-0 mt-5"
              onChange={formik.handleChange}
              name="mobile"
              id="mobile"
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <p className="text-errorMessageColor text-xs mt-1">
                {formik.errors.mobile as React.ReactNode}
              </p>
            )}
          </div>
          <div className="relative">
            <CustomInputText
              label="Email *"
              placeholder={isNewcomer ? 'Mariah@gmail.com' : 'stever@gmail.com'}
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              disabled
              inputStyles="!bg-mainBgColor !text-grayBorderColor !text-opacity-40 border-grayBorderColor"
              className="!mb-0 mt-5"
            />
          </div>
          {userRole !== 'FRIEND' && (
            <div>
              <CustomDropdown
                label="City moving to *"
                name="cityId"
                options={citiesOptions || []}
                placeholder={placeholderCityName}
                value={formik.values.cityId || ''}
                onChange={(value: string) => {
                  formik.setFieldValue('cityId', value || '');
                }}
                className="!mb-0 mt-5"
              />
              {formik.touched.cityId && formik.errors.cityId && (
                <p className="text-errorMessageColor text-xs mt-1">
                  {formik.errors.cityId as React.ReactNode}
                </p>
              )}
            </div>
          )}
          {userRole === 'FRIEND' && (
            <>
              <div>
                <CustomDropdown
                  label="Current city *"
                  name="cityId"
                  options={citiesOptions || []}
                  placeholder="Select City"
                  value={formik.values.cityId || ''}
                  onChange={(value: string) => {
                    formik.setFieldValue('cityId', value || '');
                  }}
                  className="!mb-0 mt-5"
                />
                {formik.touched.cityId && formik.errors.cityId && (
                  <p className="text-errorMessageColor text-xs mt-1">
                    {formik.errors.cityId as React.ReactNode}
                  </p>
                )}
              </div>
              <div className="w-full">
                <CustomInputText
                  label="No. of years in the city *"
                  type="number"
                  value={formik.values.yearsInCity}
                  onChange={(e) =>
                    formik.setFieldValue('yearsInCity', e.target.value)
                  }
                  className="!mb-0 mt-5"
                />
                {formik.touched.yearsInCity && formik.errors.yearsInCity && (
                  <p className="text-errorMessageColor text-xs mt-1">
                    {formik.errors.yearsInCity as React.ReactNode}
                  </p>
                )}
              </div>
            </>
          )}

          {userRole !== 'FRIEND' && (
            <div>
              <CustomDropdown
                label="Time zone *"
                name="timeZone"
                options={timeZones}
                placeholder="Select your time zone"
                value={formik.values.timeZone || timeZone}
                onChange={(e: any) => handleTimeZoneChange(e)}
                className="!mb-0 mt-5"
              />
              {formik.touched.timeZone && formik.errors.timeZone && (
                <p className="text-errorMessageColor text-xs mt-1">
                  {formik.errors.timeZone as React.ReactNode}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="grid pt-5 gap-y-1">
          <label className="text-sm text-secondary">
            {userRole !== 'FRIEND'
              ? 'Interested categories *'
              : 'Expert categories *'}
          </label>
          <div className="flex gap-2 flex-wrap">
            {categories.map((category, index) => {
              const selectedCategories = (
                userRole !== 'FRIEND'
                  ? formik.values.interestedCategories
                  : formik.values.expertCategories
              ) as string[];

              const handleCategoryChange = () => {
                const updatedCategories = selectedCategories.includes(category)
                  ? selectedCategories.filter(
                      (selectedCategory) => selectedCategory !== category
                    )
                  : [...selectedCategories, category];

                formik.setFieldValue(
                  userRole !== 'FRIEND'
                    ? 'interestedCategories'
                    : 'expertCategories',
                  updatedCategories
                );
              };

              return (
                <div key={category}>
                  <CategoryButton
                    label={category}
                    isActive={selectedCategories.includes(category)}
                    onClick={handleCategoryChange}
                  />
                </div>
              );
            })}
          </div>
          {userRole !== 'FRIEND'
            ? formik.touched.interestedCategories &&
              formik.errors.interestedCategories && (
                <p className="text-errorMessageColor text-xs mt-1">
                  {formik.errors.interestedCategories as React.ReactNode}
                </p>
              )
            : formik.touched.expertCategories &&
              formik.errors.expertCategories && (
                <p className="text-errorMessageColor text-xs mt-1">
                  {formik.errors.expertCategories as React.ReactNode}
                </p>
              )}
        </div>
        {userRole === 'FRIEND' && (
          <div className="grid pt-5">
            <CustomTextarea
              label="About you *"
              placeholder="Give a small description about yourself"
              value={formik.values.aboutMe}
              onChange={(e) => formik.setFieldValue('aboutMe', e.target.value)}
              onBlur={formik.handleBlur}
              name="aboutMe"
              id="aboutMe"
              maxLength={500}
              className="!mb-0"
            />
            {formik.touched.aboutMe && formik.errors.aboutMe && (
              <p className="text-errorMessageColor text-xs mt-0">
                {formik.errors.aboutMe instanceof Array
                  ? formik.errors.aboutMe.join(', ')
                  : typeof formik.errors.aboutMe === 'object'
                    ? JSON.stringify(formik.errors.aboutMe)
                    : formik.errors.aboutMe}{' '}
              </p>
            )}
          </div>
        )}
        <CustomButton
          label={'Save'}
          variant="primary"
          customStyle={`!h-[32px] !w-[138px] md:h-[30px] md:w-[100px] mb-10 mt-5 ${
            isSaveDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          type="submit"
          disabled={isSaveDisabled}
        />
      </form>
    </div>
  );
};

export default EditUser;
