import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FriendProfileInfo from 'components/friend-profile-info/FriendProfileInfo';
import QuestionPanelFriend from 'components/question-panel/QuestionPanelFirend';
import { generateOptionsFromEnum } from 'utils/generateOptions';
import Slider from 'components/friend-profile/FirendCarousel';
import { FriendSortOptions } from 'constants/SortOptions';
import SortDropdown from 'components/sort-dropdown/SortDropdown';
import Profile from 'assets/images/profile.png';

import { useGetUsersByRoleAndCityId } from 'api/queries/friends';
import { UserRole } from 'constants/User';
import { useGetUserByUserId } from 'api/queries/users';
import { useGetAllCities } from 'api/queries/cities';
import { useUser } from 'api/context/UserContext';

//TODO:Remove after API Integration
export const sampleProfileData = {
  username: 'JohnDoe',
  profilePictureUrl: Profile,
  city: 'New York',
  timeSpent: '2 hours',
  online: true,
  payment: '10',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  ratings: [
    { name: 'Communication', rating: 5, count: 3 },
    { name: 'Accommodation', rating: 0, count: 3 },
    { name: 'Transport', rating: 5, count: 3 },
    { name: 'Other', rating: 0, count: 3 },
  ],
};

//TODO:Remove after API Integration
export const questionData = [
  {
    _id: '1',
    userName: 'John Doe',
    date: 'December 7, 2023',
    content: 'Which universities are open for application in 2024?',
    description:
      'I am looking to apply for universities next year and would like to know which ones are currently accepting applications.',
    categories: ['Education', 'University Application'],
    answers: [
      {
        userName: 'Alice Johnson',
        date: 'December 8, 2023',
        content:
          'Several universities are currently accepting applications for the 2024 academic year. These include Harvard, Stanford, and MIT. Be sure to check their official websites for detailed application processes.',
        avatarUrl: '/path/to/alice-avatar.jpg',
        isTopAnswer: true,
      },
      {
        userName: 'Bob Smith',
        date: 'December 9, 2023',
        content:
          'In addition to the ones already mentioned, consider looking at universities in Europe, such as Oxford and the University of Edinburgh. Their diverse programs might be what you’re looking for!',
        avatarUrl: '/path/to/bob-avatar.jpg',
        isTopAnswer: false,
      },
      {
        userName: 'Catherine Zeta',
        date: 'December 10, 2023',
        content:
          'Don’t forget about the universities in Canada, like the University of Toronto and McGill University. They offer a wide range of programs and have a great international reputation.',
        avatarUrl: '/path/to/catherine-avatar.jpg',
        isTopAnswer: false,
      },
    ],
  },

  {
    _id: '2',
    userName: 'Maria Hill',
    date: '21 Jul,2023',
    content: 'What are the best universities in Sydney to study medicine?',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry standard dummy text ever since the 1500s .....',
    categories: ['Education'],
    answers: [
      {
        userName: 'Alice Johnson',
        date: 'December 8, 2023',
        content:
          'Several universities are currently accepting applications for the 2024 academic year. These include Harvard, Stanford, and MIT. Be sure to check their official websites for detailed application processes.',
        avatarUrl: '/path/to/alice-avatar.jpg',
        isTopAnswer: true,
      },
      {
        userName: 'Bob Smith',
        date: 'December 9, 2023',
        content:
          'In addition to the ones already mentioned, consider looking at universities in Europe, such as Oxford and the University of Edinburgh. Their diverse programs might be what you’re looking for!',
        avatarUrl: '/path/to/bob-avatar.jpg',
        isTopAnswer: false,
      },
      {
        userName: 'Catherine Zeta',
        date: 'December 10, 2023',
        content:
          'Don’t forget about the universities in Canada, like the University of Toronto and McGill University. They offer a wide range of programs and have a great international reputation.',
        avatarUrl: '/path/to/catherine-avatar.jpg',
        isTopAnswer: false,
      },
    ],
  },
];

//TODO:Remove after API Integration
export const answers = [
  {
    parentId: '1',
    username: 'JohnDoe',
    date: '2024-01-05',
    content: 'Practice regularly and build projects to apply your knowledge!',
    avataURL: '/path-to-avatar.jpg',
    isTopAnswer: true,
  },
];

const FriendProfile: React.FC = () => {
  const [selectedSortOption, setSelectedSortOption] = useState<
    string | undefined
  >(undefined);
  const User = useUser();
  const isLoggedIn = !!User?.user?.id;
  const [selectedUser, setSelectedUser] = useState<any>();
  const [city, setCity] = useState<string>();
  const params = useParams() || '';
  const { data: cities } = useGetAllCities();
  const { data: user = [] } = useGetUserByUserId(params.friendId || '');
  const { data: users = [] } = useGetUsersByRoleAndCityId(
    UserRole.FRIEND,
    selectedUser?.cityId || ''
  );

  console.log('friend', users);
  const isFriend = User?.user?.role === UserRole.FRIEND;

  useEffect(() => {
    if (isLoggedIn) {
      setSelectedUser(user);
    }
  }, [isLoggedIn, user]);

  useEffect(() => {
    if (user) {
      setSelectedUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (selectedUser && cities) {
      const cityName = cities.find(
        (city) => city.id === selectedUser.cityId
      )?.name;
      setCity(cityName as string);
    }
  }, [selectedUser, cities]);

  const handleThreadSortChange = (value: string) => {
    setSelectedSortOption(value);
  };

  return (
    <>
      <div className="container max-w-7xl mx-auto mt-8 px-6 lg:px-8">
        <div className="text-secondary text-lg font-medium">
          Friends From {city}
        </div>
        <Slider
          className="py-6 px-5 mb-1 lg:mb-12"
          items={users.map((profile) => ({
            name: profile.username,
            imageUrl: profile.profilePictureUrl,
            id: profile.id,
          }))}
          userId={params.friendId || ''}
        />
        <div className="lg:grid lg:grid-cols-12 gap-16 flex flex-col ">
          <div className="justify-center lg:col-span-3 col-span-12">
            <h2 className="text-secondary text-sm font-semibold mb-8 mt-3 text-center sm:text-left">
              About
            </h2>
            <div className="flex justify-center w-full ">
              <FriendProfileInfo
                userId={selectedUser?.id}
                city={city as string}
                username={selectedUser?.username}
                profilePictureUrl={Profile}
                yearsInCity={selectedUser?.yearsInCity}
                online={sampleProfileData?.online}
                payment={sampleProfileData?.payment}
                aboutMe={selectedUser?.aboutMe}
                ratings={sampleProfileData.ratings}
                showScheduleButton={!isFriend}
              />
            </div>
          </div>
          <div className="lg:col-span-9 col-span-12">
            <div className="cols-12 gap-8 flex justify-center items-center mb-6 sm:justify-normal">
              <h2 className="col-span-3 flex text-secondary text-sm font-medium">
                {selectedUser?.username}&apos;s Answers
              </h2>
              <div className="border rounded-md bg-white">
                <SortDropdown
                  options={generateOptionsFromEnum(FriendSortOptions)}
                  value={selectedSortOption}
                  onChange={(value) => {
                    handleThreadSortChange(value);
                  }}
                />
              </div>
            </div>
            {questionData.length > 0 ? (
              questionData.map((question, index) => {
                const relevantAnswers = answers.filter(
                  (answer) => answer.parentId === question._id
                );
                return (
                  <QuestionPanelFriend
                    key={index}
                    userId={question.userName}
                    date={question.date}
                    content={question.content}
                    description={question.description}
                    answers={relevantAnswers}
                    questionId={question._id}
                  />
                );
              })
            ) : (
              <div className="flex items-center justify-center mt-2 p-8 bg-white  text-sm text-secondary h-[227px] font-semibold">
                {"You haven't answered any questions yet"}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendProfile;
