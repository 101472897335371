import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      modules={modules}
      formats={formats}
      className="w-full h-80 border rounded"
    />
  );
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],

    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }], // text and background colors
    ['link', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'color',
  'background',
  'link',
  'image',
  'list',
  'bullet',
];

export default TextEditor;
