import React from 'react';
import Table from 'components/common/Table';
import CustomButton from 'components/common/Button';
import { blogArticles } from 'components/blog/dummy';
import { useNavigate } from 'react-router-dom';

const ArticleList: React.FC = () => {
  const handleRowSelect = (selectedRows: number[]) => {
    console.log('Selected rows:', selectedRows);
  };
  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    console.log(`Edit article with ID: ${id}`);
  };

  const handleNewArticle = () => {
    navigate('/add-new-article');
  };

  const handleDelete = (id: string) => {
    console.log(`Delete article with ID: ${id}`);
  };

  const headers = ['Title', 'Category', 'Actions'];
  const columnWidths = ['40%', '30%', '30%'];
  const columnAlignments: ('left' | 'center' | 'right')[] = [
    'left',
    'left',
    'left',
  ];

  const rows = blogArticles.map((article) => [
    article.title,
    article.categories.join(', '),
    <div key={article.id}>
      <button
        className="bg-secondaryDisabled text-white py-1 px-2 rounded mr-2 text-sm"
        onClick={() => handleEdit(article.id)}
      >
        Edit
      </button>
      <button
        className="bg-rejectButtonColor text-white py-1 px-2 rounded text-sm"
        onClick={() => handleDelete(article.id)}
      >
        Delete
      </button>
    </div>,
  ]);

  return (
    <div className="max-w-7xl mx-auto px-6 lg:px-8 min-h-screen mt-10">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Articles</h1>

        <CustomButton
          label="Add New Article"
          variant="primary"
          customStyle="w-[167px] h-[32px] md:text-sm"
          onClick={handleNewArticle}
        />
      </div>
      <Table
        headers={headers}
        headerStyles="!bg-transparent"
        columnWidths={columnWidths}
        columnAlignments={columnAlignments}
        rows={rows}
        selectable
        onRowSelect={handleRowSelect}
      />
    </div>
  );
};

export default ArticleList;
