import React, { useState, useEffect } from 'react';
import BlogArticleCard from 'components/blog/ArticleCard';
import SingleBlogCard from 'components/blog/SingleBlogCard';
import { blogArticles } from 'components/blog/dummy';
import HeroCarousel from 'components/blog/HeroCarousel';
import { useNavigate } from 'react-router-dom';
import EditorsPickCard from 'components/blog/EdtiorsPickCard';

const BlogArchive: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [editorsPicks, setEditorsPicks] = useState(blogArticles.slice(0, 2));
  const navigate = useNavigate();

  const handleSeeMore = (title: string) => {
    console.log(`See more about: ${title}`);
  };

  const handleSeeAll = () => {
    navigate('/all-posts');
  };

  const categories = Array.from(
    new Set(blogArticles.flatMap((article) => article.categories))
  );

  const filteredArticles =
    selectedCategory === 'All'
      ? blogArticles
      : blogArticles.filter((article) =>
          article.categories.includes(selectedCategory)
        );

  const displayedArticles = filteredArticles.slice(0, 8);

  const fifthArticle = blogArticles[4] || null;

  useEffect(() => {
    const shuffledArticles = [...blogArticles].sort(() => 0.5 - Math.random());
    setEditorsPicks(shuffledArticles.slice(0, 2));
  }, []);

  return (
    <div className="bg-blogBg h-full min-h-screen">
      <HeroCarousel />
      <div className="max-w-7xl container mx-auto px-6 lg:px-4 xl:px-0 mt-12 md:mt-20">
        <div>
          <h2 className="lg:text-3xl font-medium text-secondary mb-2">
            Popular topics
          </h2>
          <hr className="w-14 border-t-4 border-primary sm:mt-4 sm:mb-12 mt-2 mb-6" />
        </div>
        <div className="flex justify-between items-center mb-2">
          <div className="flex flex-wrap">
            <button
              className={`mr-4 text-xs ${
                selectedCategory === 'All'
                  ? 'text-primary font-semibold'
                  : 'text-secondary'
              }`}
              onClick={() => setSelectedCategory('All')}
            >
              All
            </button>
            {categories.map((category) => (
              <button
                key={category}
                className={`mr-4 text-xs ${
                  selectedCategory === category
                    ? 'text-primary font-semibold'
                    : 'text-secondary'
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
          <div>
            <button
              onClick={handleSeeAll}
              className="text-xs text-primary font-semibold min-w-14 hover:text-primaryHover"
            >
              View All
            </button>
          </div>
        </div>

        <div className="flex flex-wrap gap-x-7 gap-y-10 py-9 pt-6">
          {displayedArticles.map((article) => (
            <BlogArticleCard
              id={article.id}
              key={article.id}
              image={article.image}
              categories={article.categories}
              date={article.date}
              title={article.title}
              description={article.description}
            />
          ))}
        </div>
      </div>
      {fifthArticle && (
        <div className="mt-12">
          <SingleBlogCard
            id={fifthArticle.id}
            image={fifthArticle.image}
            categories={fifthArticle.categories}
            date={fifthArticle.date}
            title={fifthArticle.title}
            description={fifthArticle.description}
            onSeeMore={() => handleSeeMore(fifthArticle.title)}
          />
        </div>
      )}
      <div className="max-w-7xl container mx-auto px-6 lg:px-4 xl:px-0 mt-12 md:mt-20">
        <h2 className="lg:text-3xl font-medium text-secondary mb-2">
          Editor’s Pick
        </h2>
        <hr className="w-14 border-t-4 border-primary sm:mt-4 sm:mb-12 mt-2 mb-6" />
        <div className="flex flex-col lg:flex-row gap-7 felx-wrap pb-12 md:pb-20">
          {editorsPicks.map((article) => (
            <EditorsPickCard
              id={article.id}
              key={article.id}
              image={article.image}
              categories={article.categories}
              date={article.date}
              title={article.title}
              description={article.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogArchive;
