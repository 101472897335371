import React from 'react';
import { Vote, Answer } from 'model/Question';
import AnswerPanel from 'components/answer-panel/AnswerPanel';
import Question from './Question';
import { voteType } from 'model/City';

type QuestionPanelProps = {
  userId: string | undefined;
  date: string;
  content: string;
  description: string | undefined;
  categories: string[] | undefined;
  answers: Answer[];
  questionId: string;
  signInUserId?: string;
  voting?: Vote[];
  questionStatus?: string;
  onSelectQuestion?: (questionId: string) => void;
  onSelectAnswer?: (answerId: string) => void;
  handleVoteClick?: (voteType: voteType) => void;
  handleSendClick?: (comment: string, questionId: string) => void;
};

const QuestionPanel: React.FC<QuestionPanelProps> = ({
  userId,
  date,
  content,
  description,
  categories,
  answers,
  handleSendClick,
  handleVoteClick,
  questionId,
  signInUserId,
  voting,
  questionStatus,
  onSelectQuestion,
}) => {
  const handleQuestionSelect = () => {
    onSelectQuestion?.(questionId);
  };

  return (
    <div className="bg-white mb-4 rounded-sm pb-8">
      <Question
        userId={userId}
        date={date}
        content={content}
        description={description}
        categories={categories}
        questionId={questionId}
        voting={voting}
        onSelectQuestion={handleQuestionSelect}
        handleSendClick={handleSendClick}
        signInUserId={signInUserId}
        questionStatus={questionStatus}
        handleVoteClick={handleVoteClick}
        useBackgroundColor={true}
      />
      {answers.map((answer, index) => (
        <AnswerPanel
          key={index}
          userName={answer.createdBy}
          date={answer.createdAt?.toDateString() || ''}
          content={answer.title}
          handleSendClick={handleSendClick}
          questionId={questionId}
          isAnswerByUser={signInUserId === answer.createdBy}
          status={answer.status}
          useBackgroundColor={true}
        />
      ))}
    </div>
  );
};

export default QuestionPanel;
