import React, { useRef, useState, useEffect } from 'react';
import Avatar from 'components/common/Avatar';
import Tooltip from 'components/common/Tooltip'; // Adjust the import path as necessary
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

type FriendCarouselProps = {
  items: {
    name: string;
    imageUrl?: string;
    id: string;
  }[];
  size?: string;
  className?: string;
  userId?: string;
};

const FriendCarousel: React.FC<FriendCarouselProps> = ({
  items,
  size = 'w-20 h-20 ',
  className = '',
  userId,
}) => {
  const navigate = useNavigate();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isAtStart, setIsAtStart] = useState<boolean>(true);
  const [isAtEnd, setIsAtEnd] = useState<boolean>(false);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current && selectedIndex === 0) {
      scrollContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  }, [selectedIndex]);

  const onClickUser = (index: number | string) => {
    setSelectedIndex(index as number);
    navigate(`/friends/${index}`);
  };

  return (
    <div className={` relative w-full ${className}`}>
      {!isAtStart && (
        <div
          className="absolute left-0 top-[70px] transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-50"
          onClick={scrollLeft}
        >
          <ChevronLeftIcon className="h- w-5 text-primary" />
        </div>
      )}
      {!isAtEnd && (
        <div
          className="absolute right-0 top-[70px] transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer z-50"
          onClick={scrollRight}
        >
          <ChevronRightIcon className="h-5 w-5 text-primary" />
        </div>
      )}
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto space-x-5 md:space-x-7 lg:space-x-10 scrollbar-hide h-32 "
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={`flex-shrink-0 ${size} ml-2 mt-1 flex flex-col items-center cursor-pointer`}
            onClick={() => onClickUser(item.id)}
          >
            <Avatar
              name={item.name}
              initialImageUrl={item.imageUrl}
              size={size}
              isSelected={userId ? item.id === userId : index === selectedIndex}
              borderStyle="border-primary"
            />
            <Tooltip
              content={item.name}
              className="mt-2 text-center text-sm font-medium"
              tooltipClassName="w-auto p-4 border-[0px] !bg-black !text-white"
              position="top"
            >
              <div className="overflow-hidden text-ellipsis whitespace-nowrap w-[60px] lg:w-[80px]">
                {item.name}
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FriendCarousel;
